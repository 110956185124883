import ContactSection from "../landingPage/contactSection/contactSection";
import FooterSection from "../landingPage/footerSection/footerSection";
import HeaderSection from "../landingPage/headerSection/headerSection";
import BannerSection from "./bannerSection";
import MissionSection from "./missionSection/missionSection";
import SubscribeSection from "./subscribeSection/subscribeSection";
import VisionSection from "./visionSection/visionSection";

function AboutUsPage() {
  return (
    <>
      <HeaderSection />
      <BannerSection />
      <MissionSection />
      <VisionSection />
      {/* <SubscribeSection /> */}
      <ContactSection />
      <FooterSection />
    </>
  );
}

export default AboutUsPage;
