// antd
import { Typography } from "antd";

// components
import ContentHeader from "../../components/contentHeader";

const JobsScreen = () => {
  const { Title } = Typography;

  return (
    <>
      <ContentHeader title={"Jobs"} />
      <Title
        level={4}
        style={{
          textAlign: "center",
        }}
      >
        Under Development
      </Title>
    </>
  );
};

export default JobsScreen;
