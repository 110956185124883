// assets
import userProfile1 from "../../assets/icons/blog_icon1.png";
import userProfile2 from "../../assets/icons/blog_icon2.png";
import userProfile3 from "../../assets/icons/blog_icon3.png";
import blog1 from "../../assets/images/blog1.png";
import blog2 from "../../assets/images/blog2.png";
import blog3 from "../../assets/images/blog3.png";

export const BLOG_PAGE = [
  {
    id:1,
    title: "Strategies for Establishing a Micro Business in India",
    description:
      "In recent years, India has emerged as a hotbed for entrepreneurial ventures, with a surge in micro-businesses driving economic growth and innovation.",
    blogPicture: blog1,
    user: {
      name: "Annette Black",
      date: "August 24, 2023",
      profilePicture: userProfile1,
    },
  },
  {
    id:2,
    title: "The Essential Role of Integrated Professional Services for Startup Success",
    description:
      "Startups often face numerous challenges and complexities as they strive to establish themselves and grow.",
    blogPicture: blog2,
    user: {
      name: "Annette Black",
      date: "August 24, 2023",
      profilePicture: userProfile2,
    },
  },
  {
    id: 3,
    title: "Overcoming Fear and Failure in Entrepreneurship",
    description:
      "Fear and failure are intrinsic parts of the entrepreneurial journey. While they can be daunting, learning to manage and overcome them is essential for success.",
    blogPicture: blog3,
    user: {
      name: "Annette Black",
      date: "August 24, 2023",
      profilePicture: userProfile3,
    },
  },
  
];