import React from "react";

function MailMain() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 117 113"
    >
      <path
        fill="#67DAFF"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.576"
        d="M116.446 44.577l.181-.141h-.353L58.485 1 .705 44.436H.343l.188.133-.188.141H.72l58.283 41.923 57.255-41.923h.369l-.181-.133z"
      ></path>
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.576"
        d="M96.551 5.343H18.554v96.811H96.55V5.344z"
      ></path>
      <path
        fill="#263238"
        d="M74.187 31.72c0 11.218-5.096 17.678-10.363 17.678-2.72 0-4.304-1.365-4.476-3.857-1.137 2.492-3.058 3.92-5.84 3.857-4.241-.063-5.715-3.63-5.206-8.67l.62-5.668c.51-5.15 2.83-8.38 7.055-8.27a4.703 4.703 0 014.876 3.74l.392-3.513H65.1l-1.7 16.258c-.173 1.302.227 2.438 1.638 2.438 4.366 0 5.84-8.333 5.785-13.883 0-7.839-3.684-12.973-12.182-12.973-11.327 0-16.595 9.407-16.595 22.098 0 9.799 4.468 15.678 14.33 15.678 3.966 0 7.588-.784 11.154-3.575l-.337 3.92c-3.457 2.351-7.055 3.057-11.045 3.057-12.464 0-17.904-7.643-17.904-19.315 0-13.483 6.46-25.32 20.507-25.32 11.382-.016 15.435 6.914 15.435 16.32zm-21.471 3.74l-.51 5.095c-.337 2.948.572 5.04 3.23 5.103 2.657.063 4.021-1.983 4.366-5.048l.565-5.432c.282-2.666-.784-4.531-3.285-4.531-2.626-.086-4.021 1.732-4.366 4.79v.023z"
      ></path>
      <path
        fill="#67DAFF"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.576"
        d="M.344 44.435v67.408h116.283V44.435L58.485 78.143.344 44.435z"
      ></path>
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.576"
        d="M.344 111.843l58.141-33.7 58.142 33.7"
      ></path>
    </svg>
  );
}

export default MailMain;
