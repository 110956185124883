import { useNavigate } from 'react-router-dom'

// antd
import { Card, Col, Row } from 'antd'

// constants
import { BLOG_SECTION } from '../../../constants/landingPage/blogSection'
import { PUBLIC_ROUTES } from '../../../constants'

// css
import '../blogSection/blogSection.css'

function BlogSection() {
    const navigate = useNavigate()

    return (
        <>
            <div className="blogMain">
                <h1 className="heading2">{BLOG_SECTION.description}</h1>
                <Row
                    align={'middle'}
                    justify={'center'}
                    style={{
                        margin: '0px auto',
                    }}
                    gutter={[23, 23]}
                >
                    {BLOG_SECTION?.container?.length > 0 &&
                        BLOG_SECTION?.container.map((data) => (
                            <Col xs={20} sm={18} md={7} lg={7} xl={7} xxl={7}>
                                <Card
                                    style={{
                                        padding: '0px',
                                        borderRadius: '16px',
                                    }}
                                    hoverable
                                    bodyStyle={{
                                        padding: '14px',
                                    }}
                                    onClick={() =>
                                        navigate(
                                            PUBLIC_ROUTES.blogId(
                                                data?.title
                                                    .toLowerCase()
                                                    .replace(/ /g, '-')
                                            ),
                                            {
                                                state: {
                                                    data: data,
                                                },
                                            }
                                        )
                                    }
                                >
                                    <img
                                        src={data?.blogPicture}
                                        alt=""
                                        className="blog1Img"
                                        height={210}
                                        width={'100%'}
                                    />
                                    <p className="blog1Para1">{data?.title}</p>
                                    <p className="blog1Para2">
                                        {data?.description}
                                    </p>
                                </Card>
                            </Col>
                        ))}
                </Row>
                <div className="blogSectionBottom">
                    <h1
                        onClick={() => navigate(PUBLIC_ROUTES.blog)}
                        className="heading3"
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        {BLOG_SECTION.footer}
                    </h1>
                    <div class="hLine"></div>
                </div>
            </div>
        </>
    )
}
export default BlogSection
