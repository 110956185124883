import React from 'react'

function RegistrationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={'100%'}
            width={'100%'}
        >
            <path
                fill="#f5f5fc"
                d="M387.675 176.525c-1.662-1.838-3.7-2.112-4.767-2.112h-.001c-1.066 0-3.105.274-4.769 2.113-.275.304-.558.596-.843.885-12.823 26.298-33.281 36.301-53.084 45.972-3.92 1.914-7.972 3.894-11.996 6.003a9.995 9.995 0 01-11.839-1.912 9.999 9.999 0 01-1.49-11.899 110.47 110.47 0 005.367-10.724c-3.1-.087-6.226-.21-9.364-.369a10 10 0 01-5.004-18.332c4.889-3.229 9.465-5.793 13.89-8.272 12.338-6.913 20.847-11.688 26.346-25.377a6.372 6.372 0 00-4.696-1.705c-11.306.575-21.432-5.811-25.76-16.276-4.32-10.446-1.683-22.088 6.72-29.659 1.835-1.653 2.109-3.673 2.109-4.729 0-1.057-.274-3.077-2.108-4.73-7.243-6.525-10.189-16.074-8.103-25.275H80.815c10.613-.056 21.04 4.585 28.129 12.451 6.134 6.749 9.873 15.714 9.873 25.551v52.229h-.01V441.89c0 12.452 4.328 23.724 11.324 31.899 6.985 8.165 16.637 13.211 27.294 13.211 21.324 0 38.618-20.196 38.618-45.11v-39.992a5.13 5.13 0 015.128-5.128H389.13V178.668c-.221-.436-.44-.88-.657-1.326-.267-.271-.541-.532-.798-.817zm-230.402-53.07h64.47c5.523 0 10 4.477 10 10s-4.477 10-10 10h-64.47c-5.523 0-10-4.477-10-10s4.477-10 10-10zm0 31.437h96.7c5.523 0 10 4.477 10 10s-4.478 10-10 10h-96.7c-5.523 0-10-4.477-10-10s4.477-10 10-10zm0 92.512H260.89c5.522 0 10 4.477 10 10 0 5.522-4.478 10-10 10H157.273c-5.523 0-10-4.478-10-10 0-5.523 4.477-10 10-10zm64.47 117.052h-64.47c-5.523 0-10-4.478-10-10s4.477-10 10-10h64.47c5.523 0 10 4.478 10 10s-4.477 10-10 10zm128.93-32.357h-193.4c-5.523 0-10-4.478-10-10s4.477-10 10-10h193.399c5.522 0 10 4.478 10 10s-4.477 10-9.999 10zm0-32.348h-193.4c-5.523 0-10-4.478-10-10s4.477-10 10-10h193.399c5.522 0 10 4.478 10 10s-4.477 10-9.999 10z"
            ></path>
            <path
                fill="#9999af"
                d="M196.042 401.898v39.992c0 24.914-17.293 45.11-38.618 45.11h270.323c21.324 0 38.617-20.196 38.617-45.11v-39.992a5.123 5.123 0 00-5.128-5.128H201.17a5.129 5.129 0 00-5.128 5.128zm-77.225-293.77c0-9.837-3.739-18.802-9.873-25.551-7.364-8.201-18.337-12.811-29.36-12.451-20.996 0-38.002 17.016-38.002 38.002v47.1a5.13 5.13 0 005.128 5.128H118.816v-52.228z"
            ></path>
            <path
                fill="#de8bae"
                d="M157.273 143.455h64.47c5.523 0 10-4.477 10-10s-4.477-10-10-10h-64.47c-5.523 0-10 4.477-10 10s4.477 10 10 10zm0 31.437h96.7c5.523 0 10-4.477 10-10s-4.478-10-10-10h-96.7c-5.523 0-10 4.477-10 10s4.477 10 10 10zm193.4 104.859h-193.4c-5.523 0-10 4.478-10 10s4.477 10 10 10h193.399c5.522 0 10-4.478 10-10s-4.477-10-9.999-10zm-193.4-12.347H260.89c5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10H157.273c-5.523 0-10 4.477-10 10s4.477 10 10 10zm193.4 44.695h-193.4c-5.523 0-10 4.478-10 10s4.477 10 10 10h193.399c5.522 0 10-4.478 10-10s-4.477-10-9.999-10zm-128.93 32.357h-64.47c-5.523 0-10 4.478-10 10s4.477 10 10 10h64.47c5.523 0 10-4.478 10-10s-4.477-10-10-10z"
            ></path>
            <path
                fill="#ff5384"
                d="M475.929 186.15c-4.889-3.229-9.465-5.793-13.89-8.272-12.339-6.913-20.848-11.688-26.346-25.377-.061.057-.128.116-.18.169-.747.748-1.983 2.369-1.857 4.824.577 11.284-5.8 21.375-16.246 25.709-10.11 4.193-21.336 1.874-28.937-5.86.217.446.435.889.657 1.326 12.865 25.352 32.969 35.184 52.441 44.696 3.929 1.919 7.991 3.904 12.026 6.021a9.993 9.993 0 0011.84-1.91 9.998 9.998 0 001.491-11.9 110.47 110.47 0 01-5.367-10.724c3.1-.087 6.226-.21 9.364-.369a10 10 0 005.004-18.333zm-117.261-.88c-3.429 0-6.907-.676-10.263-2.068-10.446-4.334-16.823-14.426-16.246-25.709.126-2.455-1.109-4.076-1.857-4.824-.053-.053-.12-.112-.181-.168-5.499 13.689-14.008 18.464-26.346 25.377-4.425 2.479-9.001 5.043-13.89 8.272a10 10 0 005.004 18.332c3.139.159 6.265.282 9.364.369a111.041 111.041 0 01-5.367 10.724 9.998 9.998 0 0013.329 13.811c4.024-2.109 8.076-4.089 11.996-6.003 19.803-9.671 40.261-19.674 53.084-45.972-5.071 5.131-11.747 7.859-18.627 7.859z"
            ></path>
            <path
                fill="#fce677"
                d="M306.386 95.401c1.834 1.653 2.108 3.673 2.108 4.73 0 1.057-.274 3.077-2.109 4.729-8.402 7.571-11.04 19.213-6.72 29.659 4.328 10.465 14.454 16.851 25.76 16.276a6.368 6.368 0 014.696 1.705c.061.057.128.116.181.168.748.748 1.983 2.37 1.857 4.824-.577 11.284 5.8 21.375 16.246 25.709a26.744 26.744 0 0010.263 2.068c6.88 0 13.556-2.728 18.628-7.859.285-.289.568-.581.843-.885 1.663-1.838 3.702-2.113 4.769-2.113h.001c1.066 0 3.104.274 4.767 2.112.257.285.531.546.798.817 7.601 7.734 18.827 10.053 28.937 5.86 10.446-4.334 16.823-14.426 16.246-25.709-.126-2.455 1.11-4.076 1.857-4.824.053-.053.119-.112.18-.169a6.329 6.329 0 014.696-1.705c11.321.577 21.433-5.812 25.761-16.276 4.319-10.446 1.682-22.088-6.722-29.659-1.834-1.653-2.108-3.673-2.108-4.729 0-1.057.274-3.077 2.108-4.73 8.403-7.571 11.041-19.212 6.722-29.659-4.329-10.465-14.457-16.86-25.761-16.276a6.39 6.39 0 01-4.876-1.873c-.748-.748-1.984-2.37-1.858-4.825.577-11.283-5.8-21.375-16.246-25.709-10.463-4.341-22.136-1.722-29.734 6.677-1.662 1.838-3.7 2.112-4.767 2.112-1.067 0-3.105-.274-4.769-2.112-7.598-8.399-19.269-11.019-29.734-6.677-10.446 4.334-16.823 14.426-16.246 25.709.126 2.455-1.109 4.076-1.857 4.824a6.422 6.422 0 01-4.877 1.873c-11.31-.583-21.432 5.811-25.76 16.276a27.089 27.089 0 00-1.382 4.384c-2.087 9.203.859 18.751 8.102 25.277zm76.521-38.593c24.359 0 44.178 19.434 44.178 43.322s-19.818 43.323-44.178 43.323-44.178-19.435-44.178-43.323 19.819-43.322 44.178-43.322z"
            ></path>
            <ellipse
                cx="382.907"
                cy="100.13"
                fill="#fcf3c3"
                rx="44.178"
                ry="43.323"
            ></ellipse>
        </svg>
    )
}

export default RegistrationIcon
