// antd
import { Typography } from "antd";

// assets
import MissionBackground from "../../../assets/icons/aboutUsPage/missionBackground";

// constants
import {
  ABOUT_US_PAGE_CONTENT,
  MISSION_ABOUT_US_PAGE,
} from "../../../constants/aboutUsPage";

// css
import "./missionSection.css";

const MissionSection = () => {
  const { Text } = Typography;

  return (
    <>
      <div className="missionOuterContainer">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
           <p className="missionList">
            {ABOUT_US_PAGE_CONTENT}
          </p>
        </div>
      </div>
      <div className="missionMain">
        <div className="missionDesc">
          <h1 className="missionHeading">{MISSION_ABOUT_US_PAGE.title}</h1>
          <p className="missionList">{MISSION_ABOUT_US_PAGE.description}</p>
        </div>
        <div
          style={{
            position: "relative",
          }}
        >
          <img
            className="missionImg"
            src={MISSION_ABOUT_US_PAGE.missionImage}
            alt=""
          ></img>
          <div className="missionSvgInner">
            <MissionBackground />
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionSection;
