import React, { useState } from 'react'

// antd
import { Card, Col, Modal, Row, Typography } from 'antd'

// constants
import { SERVICE_SECTION_CONTENT } from '../../../constants/landingPage/servicesSection'

// assets
import CorrectIcon from '../../../assets/icons/landingPage/correctIcon'

// css
import './servicesSection.css'

const ServicesSection = () => {
    const { Text } = Typography
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedContent, setSelectedContent] = useState(null)

    const TabDataShow = ({ description }) => {
        const midIndex = Math.ceil(description.length / 2)
        const firstHalf = description.slice(0, midIndex)
        const secondHalf = description.slice(midIndex)

        return (
            <>
                <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        {firstHalf.map((data, index) => (
                            <Row
                                key={index}
                                style={{
                                    columnGap: '16px',
                                    marginTop: index > 0 ? '16px' : '0px',
                                    flexWrap: 'nowrap',
                                    alignItems: 'baseline',
                                }}
                            >
                                <Row
                                    style={{
                                        width: '15px',
                                        minWidth: '15px',
                                    }}
                                >
                                    <CorrectIcon />
                                </Row>
                                <Text
                                    style={{
                                        color: '#1A1A1A',
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {data}
                                </Text>
                            </Row>
                        ))}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        {secondHalf.map((data, index) => (
                            <Row
                                key={index}
                                style={{
                                    columnGap: '16px',
                                    marginTop: index > 0 ? '16px' : '0px',
                                    flexWrap: 'nowrap',
                                    alignItems: 'baseline',
                                }}
                            >
                                <Row
                                    style={{
                                        width: '15px',
                                        minWidth: '15px',
                                    }}
                                >
                                    <CorrectIcon />
                                </Row>
                                <Text
                                    style={{
                                        color: '#1A1A1A',
                                        fontSize: '18px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {data}
                                </Text>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </>
        )
    }

    const handleCardClick = (content) => {
        setSelectedContent(content)
        setIsModalVisible(true)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false)
        setSelectedContent(null)
    }

    return (
        <div className="serviceOuterContainer" id="ourServicesId">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <h1 style={{ fontWeight: 700 }} className="serviceTitle">
                        {SERVICE_SECTION_CONTENT.title}
                    </h1>
                    <Text style={{ fontSize: '20px' }} className="serviceDesc">
                        {SERVICE_SECTION_CONTENT.description}
                    </Text>
                </div>
                <div className="serviceTabContainer">
                    <Row gutter={[20, 20]}>
                        {SERVICE_SECTION_CONTENT?.tabContent.map((data) => (
                            <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={8}
                                xl={8}
                                xxl={8}
                                key={data.title}
                            >
                                <Card
                                    style={{
                                        borderRadius: '18px',
                                        height: '100%',
                                        border: 'none',
                                        boxShadow:
                                            '0px 12px 20px 0px #0000000a',
                                        cursor: 'pointer',
                                    }}
                                    bodyStyle={{ borderRadius: '18px' }}
                                    onClick={() => handleCardClick(data)}
                                >
                                    <Row
                                        align={'middle'}
                                        style={{
                                            columnGap: '20px',
                                            flexFlow: 'row nowrap',
                                        }}
                                    >
                                        {data?.icon && (
                                            <div
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    minWidth: '50px',
                                                }}
                                            >
                                                {data?.icon}
                                            </div>
                                        )}
                                        <h1 className="serviceHeadingMain">
                                            {data?.title}
                                        </h1>
                                    </Row>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <Modal
                open={isModalVisible}
                onCancel={handleCloseModal}
                footer={null}
                centered
                title={selectedContent?.title}
                closeIcon={<></>}
            >
                {selectedContent && (
                    <TabDataShow description={selectedContent.description} />
                )}
            </Modal>
        </div>
    )
}

export default ServicesSection
