import { Outlet } from 'react-router-dom'

// context
import { useWindowWidth } from '../windowContext'

// antd
import { theme } from 'antd'

// assets
import RootImage from '../assets/images/rootImage.png'

const LoginLayout = () => {
    const { useToken } = theme

    const { token } = useToken()
    const windowWidth = useWindowWidth()

    return (
        <>
            <div className={`main-div`}>
                {windowWidth > token.breakPoints.sm && (
                    <img
                        style={{
                            objectFit: 'cover',
                        }}
                        width={'44%'}
                        height={'100%'}
                        src={RootImage}
                        alt="logo"
                    />
                )}
                <div
                    style={{
                        width:
                            windowWidth > token.breakPoints.sm ? '56%' : '100%',
                    }}
                    className="loginSider"
                >
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default LoginLayout
