import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// antd
import {
    Card,
    Col,
    Form,
    Image,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Spin,
    Tag,
    Typography,
    Upload,
    message,
    theme,
} from 'antd'

// slices
import {
    projectAdd,
    projectEdit,
    projectList,
    projectState,
    uploadDocument,
} from './project.slice'
import { loginState } from '../login/login.slice'

// components
import ButtonComponent from '../../components/button/buttonComponent'
import ButtonDashboard from '../../components/button/buttonDashboard'

// assets
import {
    CrossIcon,
    FixedPriceIcon,
    PayByHourIcon,
} from '../../assets/icons/project'

// helpers
import COUNTRY_LIST from '../../helpers/country.json'

const AddProject = ({ props }) => {
    const { modalOpen, modalClose } = props

    const { TextArea } = Input
    const { Dragger } = Upload
    const { Option } = Select

    const { Text } = Typography
    const { useToken } = theme

    const { token } = useToken()

    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const { userProfile } = useSelector(loginState)
    const { selectedProject, statusGet } = useSelector(projectState)

    // INITIAL STATE
    const initialState = {
        loadingButton: false,
        skillsData: [],
        fileURL: [],
        errorFile: '',
        stream: false,
        countryList: [],
        currency: {},
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (selectedProject !== undefined) {
            form.setFieldsValue({
                ...selectedProject,
                estimation: {
                    currency:
                        selectedProject?.currency &&
                        JSON.parse(selectedProject?.currency)
                            ? [
                                  {
                                      label: JSON.parse(
                                          selectedProject?.currency
                                      )?.country,
                                      value: JSON.parse(
                                          selectedProject?.currency
                                      )?.country,
                                      currencySymbol: JSON.parse(
                                          selectedProject?.currency
                                      )?.symbol,
                                  },
                              ]
                            : [],
                    budget: selectedProject?.budget,
                },
            })

            setState((prevState) => ({
                ...prevState,
                skillsData:
                    JSON.parse(selectedProject?.skill)?.length > 0
                        ? JSON.parse(selectedProject?.skill)
                        : [],
                currency: JSON.parse(selectedProject?.currency)
                    ? {
                          country: JSON.parse(selectedProject?.currency)
                              ?.country,
                          symbol: JSON.parse(selectedProject?.currency)?.symbol,
                      }
                    : {},
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedProject])

    useEffect(() => {
        const setOptions =
            COUNTRY_LIST?.length > 0 &&
            COUNTRY_LIST.map((data) => {
                return {
                    label: data?.currency?.symbol,
                    value: data?.name,
                    currencySymbol: data?.currency?.symbol,
                }
            })
        setState((prevState) => ({
            ...prevState,
            countryList: setOptions,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen === true])

    // ON POST PROJECT
    const onPostProject = async (value) => {
        setState((prevState) => ({ ...prevState, loadingButton: true }))

        const formData = new FormData()

        formData.append('title', value?.title)
        formData.append('desc', value?.desc)
        formData.append('skill', JSON.stringify(state?.skillsData))
        formData.append('payment_type', value?.payment_type)
        formData.append('currency', JSON.stringify(state?.currency))
        formData.append('budget', value?.estimation?.budget)
        formData.append('user_id', userProfile?.id)

        if (selectedProject) {
            formData.append('id', selectedProject?.id)
        }

        const formDataList = new FormData()

        formDataList.append('user_id', userProfile?.id)

        const result = selectedProject
            ? await dispatch(projectEdit(formData))
            : await dispatch(projectAdd(formData))
        const data = result?.payload?.data
        if (data?.data) {
            const { success, message: checkMessage } = data?.data
            if (success) {
                message.success(checkMessage)
                setState((prevState) => ({
                    ...prevState,
                    loadingButton: false,
                }))
                dispatch(projectList(formDataList))
                modalClose()
            } else {
                setState((prevState) => ({
                    ...prevState,
                    loadingButton: false,
                }))
                message.error(checkMessage)
                modalClose()
            }
        }
    }

    // ON FINISH FAILED
    const onFinishFailed = () => {}

    // FILE UPLOAD PROPS
    const fileProps = {
        name: 'fileLink',
        multiple: true,
        onChange(info) {
            const formData = new FormData()
            if (state?.errorFile?.length === 0) {
                if (info?.file?.status === 'removed') {
                    const newData =
                        state?.fileURL?.length > 0 &&
                        state?.fileURL
                            .filter(
                                (data) =>
                                    info?.file?.name.includes(
                                        data?.displayName
                                    ) === false
                            )
                            .map((data) => {
                                return data
                            })
                    setState((prevState) => ({
                        ...prevState,
                        fileURL: newData,
                    }))
                } else {
                    setState((prevState) => ({ ...prevState, stream: true }))
                    info.fileList.forEach((file) => {
                        formData.append('files', file)
                    })
                    dispatch(uploadDocument(formData))
                }
            }
        },
        beforeUpload(file, fileListArray) {
            setState((prevState) => ({
                ...prevState,
                errorFile: '',
            }))
            if (
                state?.fileURL?.length > 6 ||
                (state?.fileURL?.length < 6 &&
                    fileListArray?.length + state?.fileURL?.length > 6)
            ) {
                setState((prevState) => ({
                    ...prevState,
                    errorFile: 'You can only upload 6 files at most!',
                }))
                return Upload.LIST_IGNORE
            }
            const isLt50M = file.size / 1024 / 1024 < 50
            if (!isLt50M) {
                message.error('Maximum File size would be 50 MB')
                return Upload.LIST_IGNORE
            } else {
                return false
            }
        },
        onDrop() {},
    }

    return (
        <>
            <Modal
                open={modalOpen}
                title={`${selectedProject ? 'Edit' : 'Post'} Inquiry`}
                footer={null}
                onCancel={() => {
                    form.resetFields()
                    modalClose()
                    setState(initialState)
                }}
            >
                <Spin spinning={statusGet === 'loading' ? true : false}>
                    <Form
                        layout="vertical"
                        name="postProjectForm"
                        onFinish={onPostProject}
                        onFinishFailed={onFinishFailed}
                        requiredMark={false}
                        form={form}
                        style={{
                            marginTop: '20px',
                        }}
                    >
                        <Col span={24}>
                            <Form.Item
                                label="Name of Service*"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Enter a Name of Service',
                                    },
                                ]}
                                colon={false}
                            >
                                <Input
                                    name="title"
                                    placeholder="Enter Name of Service"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Description*"
                                name="desc"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter a Description',
                                    },
                                ]}
                                colon={false}
                            >
                                <TextArea
                                    name="desc"
                                    placeholder="Enter a Description"
                                    rows={6}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Service Keywords* (You can select a maximum 5 services)"
                                name="skills"
                                //   rules={[
                                //     {
                                //       validator: (_, value) => {
                                //         if (value?.length === 0) {
                                //           Promise.reject("Please enter at-least one skill");
                                //         } else {
                                //           Promise.resolve();
                                //         }
                                //       },
                                //     },
                                //   ]}
                                colon={false}
                            >
                                <Input
                                    placeholder="Enter Service Keywords"
                                    name="skills"
                                    onKeyDown={(e) => {
                                        if (
                                            e.keyCode === 13 ||
                                            e.which === 13 ||
                                            e.key === 'Enter'
                                        ) {
                                            setState((prevState) => ({
                                                ...prevState,
                                                skillsData: [
                                                    ...prevState?.skillsData,
                                                    {
                                                        label: e.target.value,
                                                        value: e.target.value,
                                                    },
                                                ],
                                            }))

                                            form.setFieldValue('skills', '')
                                        }
                                    }}
                                />
                            </Form.Item>
                            <div
                                style={{
                                    marginTop: '30px',
                                }}
                            >
                                <Card
                                    style={{
                                        border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                        margin: '4px 0px 14px 0px',
                                        padding: '0px',
                                        minHeight: '110px',
                                    }}
                                    styles={{
                                        body: {
                                            padding: '15px',
                                        },
                                    }}
                                >
                                    <Row style={{ rowGap: '10px' }}>
                                        {state?.skillsData?.length > 0 &&
                                            state?.skillsData.map(
                                                (skill, index) => (
                                                    <Tag
                                                        style={{
                                                            padding:
                                                                '10px 20px',
                                                            whiteSpace:
                                                                'normal',
                                                            overflow: 'hidden',
                                                            borderRadius:
                                                                '40px',
                                                        }}
                                                        color={
                                                            token.colorPalette
                                                                .baseColor
                                                                .primary
                                                        }
                                                    >
                                                        <Row
                                                            align={'middle'}
                                                            style={{
                                                                columnGap:
                                                                    '30px',
                                                            }}
                                                        >
                                                            <Text
                                                                style={{
                                                                    color: token
                                                                        .colorPalette
                                                                        .baseColor
                                                                        .black,
                                                                }}
                                                            >
                                                                {skill?.label?.toUpperCase()}
                                                            </Text>
                                                            <div
                                                                style={{
                                                                    width: '13px',
                                                                }}
                                                                onClick={() => {
                                                                    const removeData =
                                                                        [
                                                                            ...state?.skillsData,
                                                                        ]
                                                                    removeData.splice(
                                                                        index,
                                                                        1
                                                                    )
                                                                    setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            ...prevState,
                                                                            skillsData:
                                                                                removeData,
                                                                        })
                                                                    )
                                                                }}
                                                            >
                                                                <CrossIcon />
                                                            </div>
                                                        </Row>
                                                    </Tag>
                                                )
                                            )}
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="payment_type"
                                label="Payment Preference?*"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please Select your payment preference',
                                    },
                                ]}
                            >
                                <Radio.Group
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Row gutter={[14, 14]}>
                                        <Col span={12}>
                                            <Card
                                                bodyStyle={{
                                                    padding: '20px',
                                                    borderRadius: '10px',
                                                    border: `1px solid ${
                                                        form.getFieldValue(
                                                            'payment_type'
                                                        ) === 'payByHour'
                                                            ? token.colorPalette
                                                                  .baseColor
                                                                  .primary
                                                            : 'auto'
                                                    }`,
                                                }}
                                            >
                                                <Row
                                                    align={'middle'}
                                                    justify={'space-between'}
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '18px',
                                                        }}
                                                    >
                                                        <PayByHourIcon />
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Pay by hour
                                                        </Text>
                                                    </Row>
                                                    <Radio value="payByHour"></Radio>
                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col span={12}>
                                            <Card
                                                bodyStyle={{
                                                    padding: '20px',
                                                }}
                                            >
                                                <Row
                                                    align={'middle'}
                                                    justify={'space-between'}
                                                >
                                                    <Row
                                                        align={'middle'}
                                                        style={{
                                                            columnGap: '18px',
                                                        }}
                                                    >
                                                        <FixedPriceIcon />
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    token.fontWeightStrong,
                                                            }}
                                                        >
                                                            Pay fixed price
                                                        </Text>
                                                    </Row>
                                                    <Radio value="payFixedPrice"></Radio>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="What is your estimated budget?*"
                                colon={false}
                            >
                                <Space.Compact>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['estimation', 'currency']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please Enter the Currency',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Select Currency"
                                                showSearch
                                                onChange={(value, option) => {
                                                    var lastIndex =
                                                        value.lastIndexOf(',')

                                                    var s1 = value.substring(
                                                        0,
                                                        lastIndex
                                                    )
                                                    var s2 = value.substring(
                                                        lastIndex + 1
                                                    )
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        currency: {
                                                            country: s1,
                                                            symbol: s2,
                                                        },
                                                    }))
                                                }}
                                            >
                                                {state?.countryList?.length >
                                                    0 &&
                                                    state?.countryList.map(
                                                        (country) => (
                                                            <Option
                                                                key={[
                                                                    country?.value,
                                                                    country?.currencySymbol,
                                                                ]}
                                                            >
                                                                <Row
                                                                    style={{
                                                                        columnGap:
                                                                            '5px',
                                                                    }}
                                                                    align={
                                                                        'middle'
                                                                    }
                                                                >
                                                                    <Text>
                                                                        {
                                                                            country?.value
                                                                        }
                                                                    </Text>
                                                                    {country?.currencySymbol !==
                                                                        false && (
                                                                        <Text>
                                                                            (
                                                                            {
                                                                                country?.currencySymbol
                                                                            }
                                                                            )
                                                                        </Text>
                                                                    )}
                                                                </Row>
                                                            </Option>
                                                        )
                                                    )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['estimation', 'budget']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please Enter the Budget',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder="Enter Budget"
                                                addonBefore={
                                                    state?.currency?.symbol
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Space.Compact>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row
                                style={{
                                    overflowX: 'hidden',
                                }}
                                justify={'space-between'}
                                gutter={16}
                            >
                                <Col span={24}>
                                    {state?.fileURL?.length > 0 &&
                                    !state?.stream ? (
                                        <>
                                            <Card
                                                style={{
                                                    border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                                    margin: '4px 0px 14px 0px',
                                                    padding: '0px',
                                                    minHeight: '110px',
                                                }}
                                                styles={{
                                                    body: {
                                                        padding: '15px',
                                                    },
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        columnGap: '13px',
                                                        rowGap: '13px',
                                                    }}
                                                >
                                                    {state?.fileURL.map(
                                                        (link) => (
                                                            <Col span={6}>
                                                                <div className="uploadImage">
                                                                    <Image
                                                                        src={
                                                                            link
                                                                        }
                                                                        height="162px"
                                                                        width={
                                                                            '100%'
                                                                        }
                                                                        style={{
                                                                            objectFit:
                                                                                'cover',
                                                                            borderRadius:
                                                                                '8px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        preview={
                                                                            false
                                                                        }
                                                                    />

                                                                    <div
                                                                        className={
                                                                            'hoverLayerImageProjects'
                                                                        }
                                                                    ></div>
                                                                    <div
                                                                        className={
                                                                            'hoverShowImageProjects'
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                cursor: 'pointer',
                                                                                marginRight:
                                                                                    '5px',
                                                                            }}
                                                                            onClick={
                                                                                () => {}
                                                                                // setViewMediaModal({
                                                                                //   open: true,
                                                                                //   fileName: link,
                                                                                // })
                                                                            }
                                                                        >
                                                                            {/* <PreviewIcon /> */}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                const removeData =
                                                                                    [
                                                                                        ...state?.fileURL,
                                                                                    ]
                                                                                removeData.splice(
                                                                                    removeData.findIndex(
                                                                                        (
                                                                                            item
                                                                                        ) =>
                                                                                            item ===
                                                                                            link
                                                                                    ),
                                                                                    1
                                                                                )
                                                                                setState(
                                                                                    (
                                                                                        prevState
                                                                                    ) => ({
                                                                                        ...prevState,
                                                                                        fileURL:
                                                                                            removeData,
                                                                                    })
                                                                                )
                                                                            }}
                                                                        >
                                                                            {/* <BinIcon
                                    color={token.colorPalette.baseColor.error}
                                  /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    )}
                                                </Row>
                                            </Card>
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <Upload
                                                    {...fileProps}
                                                    accept=".pdf"
                                                    style={{
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    <ButtonDashboard
                                                        props={{
                                                            text: 'Upload Files',
                                                            onClick: () => {},
                                                        }}
                                                    />
                                                </Upload>
                                            </div>
                                        </>
                                    ) : (
                                        <Form.Item
                                            name={'fileLink'}
                                            // rules={[
                                            //   {
                                            //     validator: async () => {
                                            //       if (state?.fileURL?.length === 0) {
                                            //         return Promise.reject(
                                            //           new Error("Please Upload at-lease one media")
                                            //         );
                                            //       }
                                            //     },
                                            //   },
                                            // ]}
                                            help={
                                                state?.fileURL?.length === 0
                                                    ? null
                                                    : ''
                                            }
                                            className="fileDragger"
                                        >
                                            <Dragger
                                                {...fileProps}
                                                fileList={[]}
                                                accept=".pdf, .doc"
                                                previewFile={false}
                                                style={{
                                                    border: `2px dashed ${token.colorPalette.textColor.quaternary}`,
                                                    padding: '20px',
                                                    minHeight: '185px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginTop: '20px',
                                                }}
                                            >
                                                {state?.stream ? (
                                                    <>
                                                        <Text
                                                            className="titleLight titleMiddle"
                                                            style={{}}
                                                        >
                                                            Uploading....
                                                        </Text>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Text
                                                            className="titleLight titleMiddle"
                                                            style={{}}
                                                        >
                                                            Click or Drag
                                                        </Text>
                                                        <br />
                                                        <Text
                                                            className="titleLight"
                                                            style={{
                                                                fontSize:
                                                                    token.fontSizeHeading5,
                                                            }}
                                                        >
                                                            File to Upload (Max
                                                            6 Files)
                                                        </Text>
                                                    </>
                                                )}
                                            </Dragger>
                                            {state?.errorFile?.length > 0 && (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        margin: 2,
                                                    }}
                                                >
                                                    {state?.errorFile}
                                                </span>
                                            )}
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Row
                            style={{
                                marginTop: '30px',
                            }}
                            align={'middle'}
                            justify={'end'}
                        >
                            <ButtonComponent
                                props={{
                                    buttonText: `${
                                        selectedProject ? 'Edit' : 'Submit'
                                    }  ${
                                        userProfile?.type === 'Entrepreneur'
                                            ? 'Proposals'
                                            : 'Inquiry'
                                    }`,
                                    htmlType: 'submit',
                                    disabled: state?.loadingButton,
                                    loadingButton: state?.loadingButton,
                                    style: {
                                        height: '48px',
                                    },
                                }}
                            />
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default AddProject
