import React from "react";

function ProjectIcon({ selected }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={selected ? "#67daff" : "#637381"}
        d="M4.33 6a.998.998 0 00-.981.817C1.983 14.272 1.75 18 1.75 18h20.5s-.233-3.728-1.599-11.183A.998.998 0 0019.67 6H4.33z"
        opacity="0.32"
      ></path>
      <path
        fill={selected ? "#67daff" : "#637381"}
        fillRule="evenodd"
        d="M22.25 18H1.75s0 .695.051 1.675c.072 1.37 1.155 2.411 2.527 2.463 1.527.058 3.95.112 7.672.112 3.722 0 6.145-.054 7.672-.112 1.372-.052 2.455-1.092 2.527-2.463.051-.98.051-1.675.051-1.675z"
        clipRule="evenodd"
      ></path>
      <path
        fill={selected ? "#67daff" : "#637381"}
        d="M12 1a5 5 0 00-5 5h2a3 3 0 016 0h2a5 5 0 00-5-5z"
      ></path>
    </svg>
  );
}

export default ProjectIcon;
