import React from "react";

function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#020055"
        fillRule="evenodd"
        d="M11.707 10.384l-4.376-4.38 4.376-4.382a.95.95 0 000-1.343.945.945 0 00-1.34 0L5.99 4.661 1.614.279A.944.944 0 10.277 1.616l4.378 4.381-4.378 4.381a.952.952 0 000 1.344c.372.37.971.37 1.341 0L5.995 7.34l4.376 4.382c.37.37.971.37 1.341 0a.944.944 0 00-.005-1.338z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CrossIcon;
