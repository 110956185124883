import React from "react";

function EcoSystemIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="39"
      fill="none"
      viewBox="0 0 40 39"
    >
      <path
        fill="url(#paint0_linear_305_17)"
        d="M14.394 3.833L8 .138a1.122 1.122 0 00-1.08 0L.526 3.833A1.051 1.051 0 000 4.756v7.375a1.051 1.051 0 00.526.923l6.394 3.567a1.093 1.093 0 001.08 0l6.394-3.695a1.05 1.05 0 00.525-.923V4.685a1.051 1.051 0 00-.525-.852zM7.46 2.227l4.262 2.458L7.46 7.143 3.197 4.685 7.46 2.227zM2.13 6.49l4.263 2.458v4.916l-4.263-2.458V6.49zm6.394 7.374V8.991l4.263-2.459v4.917l-4.263 2.415zM14.394 25.9L8 22.304a1.05 1.05 0 00-1.08 0L.526 26a1.051 1.051 0 00-.526.923v7.318a1.051 1.051 0 00.526.923l6.394 3.695a1.094 1.094 0 001.08 0l6.394-3.695a1.052 1.052 0 00.525-.923v-7.375a1.052 1.052 0 00-.525-.923v-.043zM7.46 24.393l4.262 2.472-4.262 2.458-4.263-2.458 4.263-2.472zM2.13 28.655l4.263 2.473v4.959L2.131 33.67v-5.015zm6.394 7.39V31.17l4.263-2.472v4.973l-4.263 2.373zM38.55 3.832L32.155.138a1.123 1.123 0 00-1.08 0L24.68 3.833a1.052 1.052 0 00-.526.923v7.375a1.051 1.051 0 00.526.923l6.394 3.694a1.094 1.094 0 001.08 0l6.394-3.694a1.05 1.05 0 00.525-.923V4.685a1.051 1.051 0 00-.525-.852zm-6.934-1.606l4.262 2.458-4.262 2.458-4.263-2.458 4.263-2.458zM26.286 6.49l4.263 2.458v4.916l-4.263-2.458V6.49zm6.394 7.374V8.991l4.263-2.459v4.917l-4.263 2.415zM38.55 25.9l-6.394-3.595a1.051 1.051 0 00-1.08 0L24.68 26a1.052 1.052 0 00-.526.923v7.318a1.051 1.051 0 00.526.923l6.394 3.695a1.094 1.094 0 001.08 0l6.394-3.695a1.052 1.052 0 00.525-.923v-7.375a1.052 1.052 0 00-.525-.923v-.043zm-6.934-1.506l4.262 2.472-4.262 2.458-4.263-2.458 4.263-2.472zm-5.329 4.262l4.263 2.473v4.959l-4.263-2.458v-4.973zm6.394 7.39V31.17l4.263-2.472v4.973l-4.263 2.373zM22.024 4.684a1.08 1.08 0 01-1.066 1.066h-2.842a1.066 1.066 0 110-2.131h2.842a1.066 1.066 0 011.066 1.065zm0 29.555a1.066 1.066 0 01-1.066 1.065h-2.842a1.066 1.066 0 010-2.131h2.842a1.08 1.08 0 011.066 1.066zm13.711-12.291a1.08 1.08 0 01-1.065-1.066v-2.841a1.065 1.065 0 112.131 0v2.841a1.066 1.066 0 01-1.066 1.066zm-32.396 0a1.066 1.066 0 01-1.066-1.066v-2.841a1.066 1.066 0 112.132 0v2.841a1.08 1.08 0 01-1.066 1.066z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_305_17"
          x1="-5.328"
          x2="39.756"
          y1="-5.403"
          y2="39.682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9CECFB"></stop>
          <stop offset="0.51" stopColor="#65C7F7"></stop>
          <stop offset="1" stopColor="#0052D4"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default EcoSystemIcon;
