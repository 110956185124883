// assets
import AIIcon from '../../assets/icons/landingPage/aiIcon'
import EcoSystemIcon from '../../assets/icons/landingPage/ecoSystemIcon'
import PartnerShipIcon from '../../assets/icons/landingPage/partnerShipIcon'

export const BUSINESS_SECTION = {
    title: 'Get your business ready to set and scale',
    container: [
        {
            title: 'Digital Ecosystem',
            description:
                "Access a wealth of resources, thought leadership, and cutting-edge solutions to stay ahead in today's dynamic business landscape.",
            color: '#fce7db',
            icon: <EcoSystemIcon />,
        },
        {
            title: 'Tailored Partnerships & Seamless Integration',
            description:
                "Explore a curated network of professionals ready to propel your business forward. From expert service providers to industry-specific mentors, Founders' Clinic ensures precision partnerships for optimal growth.",
            color: '#dedbfc',
            icon: <PartnerShipIcon />,
        },
        {
            title: 'AI-Driven Efficiency',
            description:
                'Our platform intelligently matches your business with professionals who align with your vision and goals.',
            color: '#dbf4fc',
            icon: <AIIcon />,
        },
    ],
}
