// antd
import { Col, Image, Row, Typography } from 'antd'

// assets
import PlayEcoIcon from '../../../assets/icons/landingPage/playEcoIcon'
import EcoIcon from '../../../assets/images/ecoIcon.png'

// constants
import { NEW_ECO_SECTION } from '../../../constants/landingPage/newEcoSection'

// css
import '../newEcoSection/newEcoSection.css'

function NewEcoSection() {
    const { Text, Title } = Typography

    return (
        <>
            <Row
                justify={'center'}
                style={{
                    margin: '100px 0px 152px 0px',
                }}
            >
                <Col
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                    style={{
                        borderRadius: '20px 0px 0px 20px',
                    }}
                >
                    <Image
                        src={EcoIcon}
                        preview={false}
                        className="ecoImage"
                        alt=""
                        width={'100%'}
                        style={{
                            objectFit: 'cover',
                            borderRadius: '20px 0px 0px 20px',
                        }}
                    />
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                    <div className="ecosystemInfo">
                        <div className="ecoSystemPlay">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                }}
                            >
                                <PlayEcoIcon />
                            </div>
                        </div>
                        <div className="textEcoContainer">
                            <Title
                                style={{
                                    color: '#030945',
                                    fontWeight: 700,
                                    margin: '0px',
                                }}
                                level={2}
                                className="heading1"
                            >
                                {NEW_ECO_SECTION.title}
                            </Title>
                            <Text className="para1EcoSystem">
                                {NEW_ECO_SECTION.subTitle}
                            </Text>
                            <Text className="para2EcoSystem">
                                {NEW_ECO_SECTION.description}
                            </Text>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default NewEcoSection
