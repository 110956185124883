import React from "react";

function MailBackground() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 283 152"
    >
      <g fill="#CDE8FF" clipPath="url(#clip0_173_561)">
        <path
          d="M283 141.5A141.502 141.502 0 0041.444 41.444 141.5 141.5 0 000 141.5h283z"
          opacity="0.3"
        ></path>
        <path d="M251 144a109 109 0 10-218 0h218z" opacity="0.6"></path>
        <path d="M215 144.5c0-19.228-7.691-37.669-21.381-51.265C179.929 79.638 161.361 72 142 72c-19.361 0-37.929 7.638-51.619 21.235C76.691 106.83 69 125.272 69 144.5h146z"></path>
      </g>
      <defs>
        <clipPath id="clip0_173_561">
          <path fill="#fff" d="M0 0H283V152H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default MailBackground;
