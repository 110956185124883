// antd
import { Row, Typography } from "antd";

// button dashboard
import ButtonDashboard from "../button/buttonDashboard";

const ContentHeader = ({ title, buttonText, onClick, buttonIcon }) => {
  const { Title } = Typography;

  return (
    <>
      <Row align={"middle"} justify={"space-between"}>
        <Title
          level={3}
          style={{
            fontWeight: 600,
          }}
        >
          {title}
        </Title>
        {buttonText && (
          <ButtonDashboard
            props={{
              buttonText: buttonText,
              onClick: () => {
                onClick();
              },
              icon: buttonIcon,
            }}
          />
        )}
      </Row>
    </>
  );
};

export default ContentHeader;
