import React from "react";

function PartnerShipIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_305_305)">
        <path
          fill="#FD6930"
          d="M20 31.25h1.725c.124 0 .216.115.19.236a1.877 1.877 0 001.622 2.252 1.876 1.876 0 002.048-2.25.196.196 0 01.19-.238h1.323a.403.403 0 00.402-.402v-7.946a.403.403 0 00-.402-.402h-1.323a.195.195 0 01-.19-.236 1.877 1.877 0 00-1.622-2.252 1.876 1.876 0 00-2.048 2.25.196.196 0 01-.19.238H20l-2.5 5 2.5 3.75z"
        ></path>
        <path
          fill="#01C0FA"
          d="M20.861 25.014a1.903 1.903 0 00-.625.026.195.195 0 01-.236-.19V22.5h-1.725a.195.195 0 01-.19-.236 1.877 1.877 0 00-1.622-2.252 1.876 1.876 0 00-2.048 2.25.196.196 0 01-.19.238h-1.323a.403.403 0 00-.402.402v7.946a.403.403 0 00.402.402h1.323c.125 0 .216.115.19.236a1.877 1.877 0 001.622 2.252 1.876 1.876 0 002.048-2.25.196.196 0 01.19-.238H20V28.9c0-.125.115-.216.238-.19a1.875 1.875 0 10.623-3.696z"
        ></path>
        <path
          fill="#80D261"
          d="M20 15c-.69 0-1.25-.56-1.25-1.25V12.5a1.25 1.25 0 112.5 0v1.25c0 .69-.56 1.25-1.25 1.25zm4.375 2.165a1.25 1.25 0 01-.457-1.707l.625-1.083a1.25 1.25 0 112.165 1.25l-.625 1.083a1.25 1.25 0 01-1.708.457zm-8.75 0a1.25 1.25 0 00.457-1.707l-.625-1.083a1.25 1.25 0 10-2.165 1.25l.625 1.083a1.25 1.25 0 001.708.457z"
        ></path>
        <path
          fill="#FFC344"
          d="M34.806 14.962l1.16-.232a4.627 4.627 0 003.72-4.537v-.35a3.593 3.593 0 00-7.186 0v3.229a1.928 1.928 0 002.306 1.89zM25.805 25h3.949a.403.403 0 00.382-.278l1.82-5.559A2.415 2.415 0 0134.25 17.5h3.335A2.414 2.414 0 0140 19.914v13.434a.403.403 0 01-.402.402h-6.696a.403.403 0 01-.402-.402v-4.836a.195.195 0 00-.303-.163 2.411 2.411 0 01-1.332.401h-3.853A2.012 2.012 0 0125 26.738v-.934c0-.444.36-.804.805-.804z"
        ></path>
        <path
          fill="#FEB237"
          d="M40 19.915a2.414 2.414 0 00-2.414-2.415h-.138c.091.259.138.53.138.805v10.5a3.335 3.335 0 01-3.335 3.335H32.5v1.208a.403.403 0 00.402.402h6.696a.403.403 0 00.402-.402V19.915z"
        ></path>
        <path
          fill="#0067C5"
          d="M5.194 14.962l-1.16-.232a4.627 4.627 0 01-3.72-4.537v-.35a3.593 3.593 0 017.186 0v3.229a1.928 1.928 0 01-2.306 1.89zM14.195 25h-3.949a.403.403 0 01-.382-.278l-1.82-5.559A2.415 2.415 0 005.75 17.5H2.415A2.414 2.414 0 000 19.915v13.433a.402.402 0 00.402.402h6.696a.403.403 0 00.402-.402v-4.836c0-.156.173-.249.303-.163.395.262.858.401 1.332.401h3.853A2.012 2.012 0 0015 26.738v-.934a.805.805 0 00-.805-.804z"
        ></path>
        <path
          fill="#0055A3"
          d="M6.73 27.767l-1.644-4.019v5.057A3.335 3.335 0 011.75 32.14H0v1.208a.402.402 0 00.402.402h6.696a.403.403 0 00.402-.402v-3.824s-.635-1.39-.77-1.757z"
        ></path>
        <path
          fill="#004281"
          d="M7.8 28.348a2.382 2.382 0 01-.93-1.174L4.677 21.25a.586.586 0 10-1.099.407l2.195 5.924A3.52 3.52 0 007.5 29.524v-1.012c0-.155.17-.247.3-.165z"
        ></path>
        <path
          fill="#F6AB31"
          d="M32.2 28.348c.415-.27.75-.683.93-1.174l2.194-5.924a.587.587 0 111.099.407l-2.195 5.924a3.52 3.52 0 01-1.728 1.943v-1.012a.195.195 0 00-.3-.165z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_305_305">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PartnerShipIcon;
