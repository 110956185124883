import React from "react";
import { createRoot } from "react-dom/client";

// routes
import RouteContext from "./routes";

// css
import "./index.css";

const root = document.getElementById("founders_clinic");
const react = createRoot(root);

react.render(<RouteContext />);
