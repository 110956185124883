import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

// antd
import { Card, Col, Row, Spin, Tabs, Tag, Typography, theme } from 'antd'

// slices
import { projectGet, projectState } from './project.slice'
import { loginState } from '../login/login.slice'

// assets
import { EarthIcon } from '../../assets/icons/project'

// tab section
import CommentSection from './commentSection'

const ProjectDescriptionScreen = () => {
    const { Title, Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const params = useParams()

    const dispatch = useDispatch()

    const { userProfile } = useSelector(loginState)
    const { statusGet, selectedProject } = useSelector(projectState)

    const onChange = () => {}
    const items = [
        {
            key: 'comments',
            label: 'Comments',
            children: <CommentSection />,
        },
    ]

    useEffect(() => {
        if (params?.id) {
            const formData = new FormData()

            formData.append('user_id', userProfile?.id)
            formData.append('project_id', params?.id)

            dispatch(projectGet(formData))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id, dispatch])

    return (
        <>
            <Spin spinning={statusGet === 'loading' ? true : false}>
                <Title level={3}>{selectedProject?.title}</Title>
                <Card>
                    <div
                        style={{
                            display: 'Flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                color: token.colorPalette.textColor.secondary,
                                fontSize: '18px',
                                marginBottom: '10px',
                            }}
                        >
                            Job description
                        </Text>
                        <Text
                            style={{
                                fontSize: '18px',
                                color: token.colorPalette.textColor.primary,
                            }}
                        >
                            {selectedProject?.desc}
                        </Text>
                    </div>
                    <Col
                        span={8}
                        style={{
                            marginTop: '30px',
                        }}
                    >
                        <Card
                            style={{
                                padding: '0px',
                            }}
                            bodyStyle={{
                                padding: '22px',
                            }}
                        >
                            <Title
                                style={{
                                    margin: '0px',
                                }}
                                level={4}
                            >
                                Budget
                            </Title>
                            <Row
                                align={'middle'}
                                style={{
                                    columnGap: '11px',
                                    marginTop: '10px',
                                }}
                            >
                                <EarthIcon />
                                <Text
                                    style={{
                                        fontSize: '18px',
                                        color: token.colorPalette.textColor
                                            .primary,
                                    }}
                                >
                                    {selectedProject &&
                                        JSON.parse(selectedProject?.currency)
                                            ?.symbol}
                                    {selectedProject?.budget}
                                </Text>
                            </Row>
                        </Card>
                    </Col>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '12px',
                        }}
                    >
                        <Text
                            style={{
                                color: token.colorPalette.textColor.secondary,
                                fontSize: '18px',
                                marginBottom: '5px',
                            }}
                        >
                            Must-have skills:
                        </Text>
                        <div
                            style={{
                                display: 'flex',
                                columnGap: '5px',
                                marginTop: '5px',
                            }}
                        >
                            {selectedProject &&
                                JSON.parse(selectedProject?.skill)?.length >
                                    0 &&
                                JSON.parse(selectedProject?.skill)?.map(
                                    (skill) => (
                                        <Tag
                                            style={{
                                                padding: '5px 10px',
                                                borderRadius: '40px',
                                            }}
                                            color={
                                                token.colorPalette.iconColor
                                                    .quinary
                                            }
                                        >
                                            <Text
                                                style={{
                                                    color: token.colorPalette
                                                        .baseColor.black,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {skill?.label?.toUpperCase()}
                                            </Text>
                                        </Tag>
                                    )
                                )}
                        </div>
                    </div>
                </Card>
            </Spin>
            <Tabs
                style={{
                    marginTop: '30px',
                }}
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
            />
            ;
        </>
    )
}

export default ProjectDescriptionScreen
