import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// antd
import {
  Card,
  Col,
  Empty,
  Popover,
  Row,
  Tag,
  Typography,
  message,
  theme,
} from "antd";

// slices
import {
  projectDelete,
  projectGet,
  projectList,
  projectState,
} from "./project.slice";
import { loginState } from "../login/login.slice";

// components
import DeletePopUp from "../../components/deletePopup";

// assets
import { DeleteIcon, EditIcon, EllipsisIcon } from "../../assets/icons/project";

// constants
import { PRIVATE_ROUTE } from "../../constants/index";

const ProjectListScreen = ({ props }) => {
  const { setOpen } = props;

  const { Title, Text } = Typography;

  const { useToken } = theme;

  const { token } = useToken();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { projects } = useSelector(projectState);
  const { userProfile } = useSelector(loginState);

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    loadingButton: false,
    id: "",
  });

  const deleteProject = async () => {
    setDeleteModal((prevState) => ({ ...prevState, loadingButton: true }));

    const formData = new FormData();

    formData.append("id", deleteModal?.id);

    const formDataList = new FormData();

    formDataList.append("user_id", userProfile?.id);

    const result = await dispatch(projectDelete(formData));
    const data = result?.payload?.data;
    if (data?.data) {
      const { success, message: checkMessage } = data?.data;
      if (success) {
        message.success(checkMessage);
        setDeleteModal(() => ({
          id: "",
          loadingButton: false,
          open: false,
        }));
        dispatch(projectList(formDataList));
      } else {
        setDeleteModal(() => ({
          id: "",
          loadingButton: false,
          open: false,
        }));
        message.error(checkMessage);
      }
    }
  };

  const CardWrapper = ({ project }) => {
    const [popOpen, setPopOpen] = useState(false);

    const handleOpenChange = (newOpen) => {
      setPopOpen(newOpen);
    };
    const dropDownIcon = [
      {
        title: <Text>Edit</Text>,
        icon: (
          <div
            style={{
              height: "15px",
              width: "15px",
            }}
          >
            <EditIcon />
          </div>
        ),
        onClick: (project) => {
          const formData = new FormData();

          formData.append("user_id", userProfile?.id);
          formData.append("project_id", project?.id);

          dispatch(projectGet(formData));
          setOpen();
          setPopOpen(false);
        },
      },
      {
        title: <Text>Delete</Text>,
        icon: <DeleteIcon />,
        onClick: (project) => {
          setPopOpen(false);
          setDeleteModal((prevState) => ({
            ...prevState,
            open: true,
            id: project?.id,
          }));
        },
      },
    ];

    const dropDownContent = (project) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "8px",
            }}
            onClick={(e) => e.preventDefault()}
          >
            {dropDownIcon?.length > 0 &&
              dropDownIcon.map((data) => {
                return (
                  data !== false && (
                    <Row
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        data?.onClick(project);
                      }}
                    >
                      <div
                        style={{
                          marginRight: "15px",
                        }}
                      >
                        {data?.icon}
                      </div>
                      <Text className="sub-title">{data?.title}</Text>
                    </Row>
                  )
                );
              })}
          </div>
        </>
      );
    };

    return (
      <>
        <Card
          style={{
            padding: "0px",
            border: "1px solid rgba(227, 227, 227, 1)",
            borderRadius: "21px",
            cursor: "pointer",
            height: "100%",
          }}
          bodyStyle={{
            padding: "26px",
          }}
          onClick={() => {
            navigate(PRIVATE_ROUTE.project.projectId(project?.id));
          }}
        >
          <Row align={"middle"} justify={"space-between"}>
            <Title
              level={3}
              style={{
                margin: "0px",
              }}
            >
              {project?.title}
            </Title>
            <Popover
              content={dropDownContent(project)}
              trigger="click"
              placement="left"
              open={popOpen}
              overlayInnerStyle={{
                padding: "16px",
              }}
              arrow={false}
              onOpenChange={handleOpenChange}
            >
              <div onClick={(e) => e.stopPropagation()}>
                <EllipsisIcon />
              </div>
            </Popover>
          </Row>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "12px",
            }}
          >
            <Text>Must-have skills:</Text>
            <div
              style={{
                display: "flex",
                columnGap: "5px",
                marginTop: "5px",
              }}
            >
              {project &&
                JSON.parse(project?.skill)?.length > 0 &&
                JSON.parse(project?.skill)?.map((skill) => (
                  <Tag
                    style={{
                      padding: "5px 10px",
                      borderRadius: "40px",
                    }}
                    color={token.colorPalette.iconColor.quinary}
                  >
                    <Text
                      style={{
                        color: token.colorPalette.baseColor.black,
                        fontWeight: 500,
                      }}
                    >
                      {skill?.label?.toUpperCase()}
                    </Text>
                  </Tag>
                ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              columnGap: "5px",
              marginTop: "8px",
              flexDirection: "column",
            }}
          >
            <Text>Monthly budget:</Text>
            <Title
              level={5}
              style={{
                margin: "0px",
              }}
            >
              {project && JSON.parse(project?.currency)?.symbol}
              {project?.budget}
            </Title>
          </div>
        </Card>
      </>
    );
  };

  return (
    <>
      <Row
        gutter={[33, 33]}
        style={{
          marginTop: "50px",
        }}
      >
        {projects?.length > 0 ? (
          projects.map((project) => (
            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
              <CardWrapper project={project} />
            </Col>
          ))
        ) : (
          <Row
            align={"middle"}
            justify={"center"}
            style={{
              width: "100%",
            }}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Project Found"
            />
          </Row>
        )}
      </Row>
      <DeletePopUp
        previewDelete={deleteModal?.open}
        onDelete={() => {
          deleteProject();
        }}
        loadingButton={deleteModal?.loadingButton}
        onCancel={() => {
          setDeleteModal({
            open: false,
            id: "",
            loadingButton: false,
          });
        }}
        pageKey={"Project"}
        modalTitle={`Delete Project`}
      />
    </>
  );
};

export default ProjectListScreen;
