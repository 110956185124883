import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

// antd
import { Layout } from 'antd'

// sections
import HeaderSection from '../landingPage/headerSection/headerSection'
import FooterSection from '../landingPage/footerSection/footerSection'
import SubscribeSection from '../aboutusPage/subscribeSection/subscribeSection'
import ContactSection from '../landingPage/contactSection/contactSection'
import BannerSection from '../aboutusPage/bannerSection'

// constants
import { BLOG_SECTION } from '../../constants/landingPage/blogSection'

const BlogPageDetail = () => {
    const { Content } = Layout

    const location = useLocation()

    const params = useParams()

    const { state } = location

    const [blogData, setBlogData] = useState()

    useEffect(() => {
        if (params?.id) {
            setBlogData(BLOG_SECTION?.container[state?.data?.id - 1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id])

    return (
        <>
            <HeaderSection />
            <BannerSection blogPage={true} blogTitle={blogData?.title} />
            <Layout>
                <Content className="blogDetailOuter">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: blogData?.blogDetail?.content,
                        }}
                    />
                </Content>
            </Layout>
            <SubscribeSection />
            <ContactSection />
            <FooterSection />
        </>
    )
}

export default BlogPageDetail
