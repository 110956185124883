import { useNavigate } from 'react-router-dom'

// antd
import { Typography } from 'antd'

// components
import ButtonComponent from '../../../components/button/buttonComponent'

// assets
import JoinImage from '../../../assets/images/joinImage.png'

// constants
import { JOIN_CLINIC_SECTION } from '../../../constants/landingPage/joinClinicSection'
import { PUBLIC_ROUTES } from '../../../constants'

// css
import './joinclinicSection.css'

function JoinclinicSection() {
    const { Text, Title } = Typography

    const navigate = useNavigate()

    return (
        <>
            <div className="clinicMain">
                <div className="joinfounderclinic">
                    <div className="joinFounderInner">
                        <Text className="ready">
                            {JOIN_CLINIC_SECTION.title}
                        </Text>
                        <Title
                            style={{
                                fontWeight: 700,
                                margin: '0px',
                            }}
                            level={3}
                            className="joinToday"
                        >
                            {JOIN_CLINIC_SECTION.header}
                        </Title>
                        <Text
                            style={{
                                fontWeight: 400,
                                lineHeight: '32px',
                            }}
                            className="signUpNow"
                        >
                            {JOIN_CLINIC_SECTION.description}
                        </Text>
                        <div
                            style={{
                                marginTop: '20px',
                            }}
                        >
                            <ButtonComponent
                                props={{
                                    onClick: () => {
                                        navigate(PUBLIC_ROUTES.signUp)
                                    },
                                    buttonText: 'Join Us',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <img src={JoinImage} alt="" className="joinImg1" />
            </div>
        </>
    )
}
export default JoinclinicSection
