import React from "react";

function EarthIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        fill="#000"
        d="M9 .167a8.333 8.333 0 110 16.666A8.333 8.333 0 019 .167zm3.337 9.064c-.288-.437-.495-.752-1.285-.627-1.492.237-1.657.498-1.729.927l-.02.13-.02.139c-.081.569-.078.784.183 1.059 1.054 1.106 1.686 1.903 1.877 2.37.094.228.334.917.169 1.598a6.82 6.82 0 002.626-1.864c.092-.311.158-.7.158-1.17v-.087c0-.769 0-1.12-.543-1.43-.23-.13-.4-.208-.537-.27-.306-.14-.51-.231-.782-.631a9.488 9.488 0 01-.097-.144zM9 1.694a6.786 6.786 0 00-4.913 2.097c.148.102.276.246.364.444.17.382.17.774.17 1.121 0 .274 0 .533.088.722.12.256.638.366 1.096.461.163.035.332.07.485.112.422.118.748.496 1.01.8.108.127.269.313.349.359a.85.85 0 00.243-.415c.05-.183.036-.345-.038-.433-.466-.55-.441-1.61-.297-2 .227-.615.935-.57 1.453-.536.193.012.376.024.512.007.518-.065.678-.854.79-1.008.244-.333.989-.836 1.452-1.146A6.782 6.782 0 009 1.694z"
      ></path>
    </svg>
  );
}

export default EarthIcon;
