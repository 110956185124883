import { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// antd
import {
    Checkbox,
    Col,
    Form,
    Input,
    Row,
    Select,
    Typography,
    message,
    theme,
} from 'antd'

// components
import ButtonComponent from '../../components/button/buttonComponent'

// assets
import LogoIcon from '../../assets/icons/landingPage/logo'

// constants
import { PHONE_COUNTRIES } from '../../constants/phone-countries'
import { API_URL } from '../../constants/global'
import { PURPOSE_OPTIONS } from '../../constants/inquiryPage'

const GetInquiryPage = () => {
    const [form] = Form.useForm()

    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    const [loadingButton, setLoadingButton] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState({
        default: null,
        phoneNo: null,
        code: null,
        flag: null,
    })
    const [phoneValid, setPhoneValid] = useState(false)

    function onFinishFailed() {}

    // ON LOGIN VIA EMAIL AND PASSWORD
    async function onLogin(value) {
        setLoadingButton(true)

        const url = `${API_URL}/inquiry`
        // const url = `${process.env.REACT_APP_URL}/inquiry`;
        const formData = new FormData()

        formData.append('name', value.name)
        formData.append('company_name', value.company_name)
        formData.append('mobile', phoneNumber?.phoneNo)
        formData.append('email', value.email)
        formData.append('purpose', value.purpose)

        fetch(url, {
            method: 'POST',
            body: formData,
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((data) => {
                if (data?.data?.success) {
                    message.success(data?.data?.message)
                    setLoadingButton(false)
                    // window.open(
                    //     'https://drive.google.com/file/d/1qIISoPwWpLOQxVDFyJps4r0DTp-O8rfM/view',
                    //     '_self'
                    // )
                    form.resetFields()
                } else {
                    setLoadingButton(false)
                    message.error('Please Fill the details correctly')
                }
            })
            .catch(() => {
                setLoadingButton(false)
            })
    }

    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')
        setPhoneNumber({
            default: number,
            phoneNo,
            code: `+${country.dialCode}`,
            flag: country.countryCode.toUpperCase(),
        })
    }
    return (
        <div
            style={{
                padding: '58px 70px 258px 60px',
            }}
        >
            <div className="logoMainHeader">
                <LogoIcon />
            </div>
            <Title
                level={2}
                style={{
                    fontWeight: 700,
                    margin: '52px 0px 0px 0px',
                }}
            >
                Get In Touch
            </Title>
            <Text
                style={{
                    color: token.colorPalette.textColor.senary,
                    fontSize: token.fontSizeHeading4,
                    marginTop: '20px',
                }}
            >
                Join us on this journey of growth and transformation
            </Text>
            <Form
                layout="vertical"
                name="signUpForm"
                onFinish={onLogin}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                labelCol={{
                    style: {
                        padding: '0 0 6px',
                    },
                }}
                style={{
                    marginTop: '50px',
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Name',
                                },
                            ]}
                            colon={false}
                        >
                            <Input placeholder="Enter your Name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Company Name (Optional)"
                            name="company_name"
                            colon={false}
                        >
                            <Input placeholder="Enter Company Name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Purpose of Inquiry"
                            name="purpose"
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Purpose',
                                },
                            ]}
                        >
                            <Select
                                options={PURPOSE_OPTIONS}
                                placeholder="Select your Purpose"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Email',
                                },
                                {
                                    type: 'email',
                                    message: 'Please Enter valid Email',
                                },
                                // {},
                            ]}
                            colon={false}
                        >
                            <Input placeholder="Enter Email Id" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Mobile Number"
                            name="mobile"
                            rules={[
                                {
                                    validator: async () => {
                                        if (
                                            !phoneValid &&
                                            phoneNumber?.phoneNo?.length > 0
                                        ) {
                                            return Promise.reject(
                                                new Error(
                                                    'Please Enter a valid Phone Number'
                                                )
                                            )
                                        }
                                        if (
                                            phoneNumber?.phoneNo?.length ===
                                                0 ||
                                            phoneNumber?.phoneNo === null
                                        ) {
                                            return Promise.reject(
                                                new Error(
                                                    'Please Enter Phone Number'
                                                )
                                            )
                                        }
                                    },
                                },
                            ]}
                            colon={false}
                        >
                            <PhoneInput
                                inputProps={{
                                    name: 'mobile',
                                    required: true,
                                }}
                                country={'in'}
                                specialLabel=""
                                inputStyle={{
                                    height: '50px',
                                    width: '100%',
                                }}
                                placeholder="Enter Phone Number"
                                value={phoneNumber?.default ?? null}
                                onChange={(number, country) => {
                                    handleChangePhoneNumber(number, country)
                                    const correspondingCountry =
                                        PHONE_COUNTRIES.find(
                                            (countryName) =>
                                                countryName.iso2 ===
                                                country.countryCode?.toUpperCase()
                                        )
                                    let numberNew = `+${number}`
                                    if (
                                        correspondingCountry &&
                                        number &&
                                        correspondingCountry?.validation.test(
                                            numberNew
                                        )
                                    ) {
                                        setPhoneValid(true)
                                    } else {
                                        setPhoneValid(false)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="termsAndConditionsAgreed"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                  new Error(
                                                      'Should accept agreement'
                                                  )
                                              ),
                                },
                            ]}
                        >
                            <Checkbox
                                style={{
                                    marginTop: '12px',
                                }}
                            >
                                Agree to Terms & Condition
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={20}
                    style={{
                        marginTop: '42px',
                    }}
                >
                    <Col span={24}>
                        <ButtonComponent
                            props={{
                                buttonText: 'Get Inquiry',
                                htmlType: 'submit',
                                loadingButton: loadingButton,
                                style: {
                                    width: '100%',
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default GetInquiryPage
