import React from "react";

function NotificationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
    >
      <path
        fill="#637381"
        fillRule="evenodd"
        d="M15.72 11.4l1.8 1.81A1.64 1.64 0 0116.36 16H13v.34A3.84 3.84 0 019 20a3.84 3.84 0 01-4-3.66V16H1.64a1.64 1.64 0 01-1.16-2.79l1.8-1.81V6.73A6.74 6.74 0 019.9.06a6.86 6.86 0 015.82 6.88v4.46zM9 18a1.88 1.88 0 002-1.66V16H7v.34A1.88 1.88 0 009 18z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default NotificationIcon;
