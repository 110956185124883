import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// antd
import {
  Avatar,
  Badge,
  Card,
  Layout,
  Menu,
  Popover,
  Row,
  Typography,
  message,
  theme,
} from "antd";

// slices
import { loginState, signOut } from "../../pages/login/login.slice";

// components
import DeletePopUp from "../deletePopup";

// assets
import {
  LogoutIcon,
  NotificationIcon,
  ProfileIcon,
} from "../../assets/icons/header";
import logo from "../../assets/icons/logo.png";
import {
  DashboardIcon,
  JobIcon,
  ProjectIcon,
} from "../../assets/icons/project";

// constants
import { PRIVATE_ROOT_ROUTE, PRIVATE_ROUTE, ROOT_ROUTE } from "../../constants";

import styles from "./layouts.module.css";

const SideLayout = () => {
  const { Text } = Typography;

  const { Sider, Content, Header } = Layout;

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const { useToken } = theme;
  const { token } = useToken();

  const navigate = useNavigate();

  const [selected, setSelected] = useState();

  const { userProfile } = useSelector(loginState);

  const initialState = {
    openProfile: false,
    deleteModal: false,
    deleteButtonLoading: false,
  };

  const [state, setState] = useState(initialState);

  const handleOpenProfile = (newOpen) => {
    setState({ ...state, openProfile: newOpen });
  };

  useEffect(() => {
    setSelected(pathname.split("/")[1]);
  }, [pathname]);

  const menuItems = [
    {
      key: "dashboard",
      label: "Dashboard",
      icon: <DashboardIcon selected={selected === "dashboard"} />,
      onClick: () => navigate(PRIVATE_ROOT_ROUTE),
    },
    {
      key: "project",
      label: userProfile?.type === "Entrepreneur" ? "Proposals" : "Inquiries",
      icon: <ProjectIcon selected={selected === "project"} />,
      onClick: () => navigate(PRIVATE_ROUTE.project.root),
    },
    {
      key: "jobs",
      label: "Jobs",
      icon: <JobIcon selected={selected === "jobs"} />,
      onClick: () => navigate(PRIVATE_ROUTE.jobs),
    },
  ];

  const logOut = () => {
    // const result = await dispatch(signOut());
    // const data = result?.payload?.data;
    // if (data?.data) {
    //   const { success, message: checkMessage } = data?.data;
    //   if (success) {
    // message.success(checkMessage);
    window.localStorage.clear();
    navigate(ROOT_ROUTE);
    //   } else {
    //     message.error(checkMessage);
    //   }
    // }
  };

  return (
    <>
      <Layout>
        <Sider
          width={280}
          style={{
            backgroundColor: token.colorPalette.baseColor.white,
            padding: "28px 20px",
          }}
          trigger={null}
        >
          <img
            onClick={() => navigate(PRIVATE_ROOT_ROUTE)}
            className="logoHeader"
            src={logo}
            alt=""
          />
          <Card
            style={{
              padding: "0px",
              marginTop: "28px",
              backgroundColor: token.colorPalette.baseColor.senary,
            }}
            bodyStyle={{
              padding: "19px",
            }}
            bordered={false}
            align={"middle"}
          >
            <Row
              align={"middle"}
              s
              style={{
                columnGap: "16px",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "#fde3cf",
                  color: "#f56a00",
                  cursor: "pointer",
                }}
                size={40}
                src="https://api.dicebear.com/7.x/miniavs/svg?seed=2"
              ></Avatar>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "2px",
                  alignItems: "flex-start",
                }}
              >
                <Text style={{}}>{userProfile?.name}</Text>
                <Text
                  style={{
                    color: token.colorPalette.textColor.senary,
                  }}
                >
                  {userProfile?.type}
                </Text>
              </div>
            </Row>
          </Card>
          <Menu
            items={menuItems}
            style={{
              marginTop: "30px",
              color: token.colorPalette.baseColor.black,
            }}
            selectedKeys={[selected]}
            onClick={(data) => {
              setSelected(data?.key);
            }}
          />
        </Sider>
        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          <Header
            style={{
              padding: "0px 24px",
              backgroundColor: token.colorPalette.baseColor.white,
            }}
          >
            <Row
              align={"middle"}
              justify={"end"}
              style={{
                columnGap: "30px",
                height: "inherit",
              }}
            >
              <Badge count={9}>
                <NotificationIcon />
              </Badge>

              <Popover
                overlayInnerStyle={{
                  padding: 0,
                  borderRadius: "12px",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                open={state?.openProfile}
                onOpenChange={handleOpenProfile}
                content={
                  <>
                    <div
                      style={{
                        padding: "5px 5px 0px 5px",
                      }}
                      onClick={() => {
                        navigate(PRIVATE_ROUTE.profile);
                      }}
                    >
                      <div className={styles.logoutCard}>
                        <Row
                          align={"middle"}
                          style={{
                            gap: 10,
                          }}
                        >
                          <ProfileIcon />
                          <Text
                            style={{
                              fontSize: "15px",
                            }}
                          >
                            Profile
                          </Text>
                        </Row>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "0px 5px 5px 5px",
                      }}
                      onClick={() => {
                        setState({
                          ...state,
                          openProfile: false,
                          deleteModal: true,
                        });
                      }}
                    >
                      <div className={styles.logoutCard}>
                        <Row
                          align={"middle"}
                          style={{
                            gap: 10,
                          }}
                        >
                          <LogoutIcon />
                          <Text
                            style={{
                              fontSize: "15px",
                            }}
                          >
                            Logout
                          </Text>
                        </Row>
                      </div>
                    </div>
                  </>
                }
                trigger="click"
                arrow={false}
                placement="bottomLeft"
              >
                <Avatar
                  style={{
                    backgroundColor: "#fde3cf",
                    color: "#f56a00",
                    cursor: "pointer",
                  }}
                  size={40}
                  src="https://api.dicebear.com/7.x/miniavs/svg?seed=2"
                ></Avatar>
              </Popover>
            </Row>
          </Header>
          <Content
            style={{
              backgroundColor: token.colorPalette.baseColor.white,
              padding: "28px",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <DeletePopUp
        previewDelete={state?.deleteModal}
        onDelete={() => {
          setState({ ...state, deleteButtonLoading: true });
          logOut();
        }}
        loadingButton={state?.deleteButtonLoading}
        onCancel={() => {
          setState({
            ...state,
            deleteButtonLoading: false,
            deleteModal: false,
          });
        }}
        modalTitle="Log Out"
        keyModal="logout"
      />
    </>
  );
};

export default React.memo(SideLayout);
