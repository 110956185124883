// assets
import BusinessRing from "../../../assets/icons/landingPage/businessRing";

// constants
import { BUSINESS_SECTION } from "../../../constants/landingPage/businessSection";

// css
import "../businessSection/businessSection.css";

function BusinessSection() {
  return (
    <>
      <div className="businessMain">
        <h1
          className="businessHeading"
          style={{
            marginBottom: "0px",
          }}
        >
          {BUSINESS_SECTION.title}
        </h1>
        <div className="digitalContainer">
          {BUSINESS_SECTION.container?.length > 0 &&
            BUSINESS_SECTION.container.map((data) => (
              <div
                className="digitalEcosystem"
                style={{
                  backgroundColor: data?.color,
                }}
              >
                <h1 className="digitalEcoHeading">{data?.title}</h1>
                <p className="digitalEcoDescription">{data?.description}</p>
                <div className="businessRing">
                  <BusinessRing />
                </div>
                <div className="circleWhite">
                  <div className="flexIcon">{data?.icon}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
export default BusinessSection;
