export const PURPOSE_OPTIONS = [
    {
        label: 'Subscription',
        value: 'Subscription',
    },
    {
        label: 'Getting featured',
        value: 'Getting featured',
    },
    {
        label: 'Other',
        value: 'Other',
    },
]
