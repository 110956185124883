import { Button } from "antd";
import "./button.css";

function ButtonComponent({ props }) {
  const {
    buttonText,
    disabled,
    htmlType,
    buttonType,
    loadingButton,
    onClick,
    danger,
  } = props;

  return (
    <>
      <Button
        className="button_main"
        disabled={disabled}
        onClick={() => !htmlType && onClick()}
        htmlType={htmlType}
        type={buttonType}
        loading={loadingButton}
        danger={danger}
        {...props}
      >
        {buttonText}
      </Button>
    </>
  );
}
export default ButtonComponent;
