import React from "react";

function ChooseCardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="58"
      fill="none"
      viewBox="0 0 77 58"
    >
      <g stroke="#DBF4FC" strokeWidth="10" clipPath="url(#clip0_215_567)">
        <circle cx="65" cy="51" r="39" opacity="0.5"></circle>
        <circle cx="70.5" cy="57.5" r="24.5" opacity="0.5"></circle>
      </g>
      <defs>
        <clipPath id="clip0_215_567">
          <path fill="#fff" d="M0 0H77V58H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChooseCardIcon;
