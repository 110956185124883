// antd
import { Typography } from 'antd'

// constants
import { FEATURED_SECTION } from '../../../constants/landingPage/informationSection'

// css
import './informationSection.css'
import ButtonComponent from '../../../components/button/buttonComponent'
import { PUBLIC_ROUTES } from '../../../constants'
import { useNavigate } from 'react-router-dom'

function InformationSection() {
    const { Text } = Typography

    const navigate = useNavigate()

    return (
        <>
            <div className="infoOuter">
                <div className="infoOuterInner">
                    <div className="infoOuterFirst">
                        <h1 className="infoHeaderMain">
                            {FEATURED_SECTION.title}
                        </h1>
                        <Text className="paragraph_1">
                            {FEATURED_SECTION.description}
                        </Text>
                        <div
                            style={{
                                marginTop: '20px',
                            }}
                        >
                            <ButtonComponent
                                props={{
                                    onClick: () => {
                                        navigate(PUBLIC_ROUTES.inquiry)
                                    },
                                    buttonText: 'Connect to get featured',
                                }}
                            />
                        </div>
                    </div>
                    <div className="infoImgOuter">
                        <img
                            className="infoImgSecondary"
                            src={FEATURED_SECTION.planImage}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default InformationSection
