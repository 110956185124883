// antd
import { Card, Col, Row, Typography } from 'antd'

// constants
import { EVENT_SECTION_CONTENT } from '../../../constants/landingPage/eventSection'

// assets
import EventIcon from '../../../assets/icons/landingPage/eventSection/eventIcon'

// css
import './eventSection.css'
import { useWindowWidth } from '../../../windowContext'

const EventSection = () => {
    const { Title, Text } = Typography

    const windowWidth = useWindowWidth()

    return (
        <div className="eventOuterContainer" id="eventSectionMain">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div>
                    <Title
                        level={2}
                        style={{
                            fontWeight: 700,
                            fontSize: '36px',
                            color: '#030945',
                        }}
                        className="eventTitle"
                    >
                        {EVENT_SECTION_CONTENT.title}
                    </Title>
                    <Text
                        style={{
                            fontSize: '20px',
                            color: '#585858',
                        }}
                    >
                        {EVENT_SECTION_CONTENT.description}
                    </Text>
                    {EVENT_SECTION_CONTENT.container?.length > 0 && (
                        <Row
                            align={'middle'}
                            gutter={[20, 20]}
                            style={{
                                marginTop: '60px',
                            }}
                        >
                            {EVENT_SECTION_CONTENT.container.map((data) => (
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                >
                                    <Card
                                        style={{
                                            padding: 0,
                                            border: '0.5px solid rgba(145, 158, 171, 0.6)',
                                        }}
                                        bodyStyle={{
                                            padding: 16,
                                            borderRadius: 16,
                                        }}
                                        className="eventCard"
                                    >
                                        <Row
                                            align={'middle'}
                                            style={{
                                                columnGap: 20,
                                                flexFlow: 'row nowrap',
                                            }}
                                            className="flexColumnEvent"
                                        >
                                            {windowWidth > 500 && (
                                                <div
                                                    style={{
                                                        width: 115,
                                                        height: 120,
                                                        minWidth: 115,
                                                        backgroundColor:
                                                            data?.color,
                                                        borderRadius: 14,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <EventIcon
                                                        color={data?.iconColor}
                                                    />
                                                </div>
                                            )}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <h1
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                    className="eventHeader"
                                                >
                                                    {data?.title}
                                                </h1>
                                                <Text
                                                    style={{
                                                        marginTop: 6,
                                                    }}
                                                >
                                                    {data?.description}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontWeight: 500,
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    {data?.date}
                                                </Text>
                                            </div>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EventSection
