export const membershipPlan = [
    {
        name: 'basic',
        label: 'Basic',
        subName: 'Entry Edge for Entrepreneurs & Professionals',
        description: [
            'Access to the web app',
            'Discover Businesses / Professionals',
            'Training videos / material',
            'Business management templates',
            'Participation in online/offline seminars/ Conclaves/ conferences',
        ],
        color: '#dfe0ec',
    },
    {
        label: 'Premium',
        name: 'premium',
        subName: 'Growth Grid for Professionals',
        description: [
            "Any number of inquiries can be responded to on the Founders' Clinic app.",
            'Lead Generation',
            'Lead conversion support',
            'Participation in online / offline seminars / Conclaves',
            'Networking & Collaboration opportunities',
            'Spotlight in newsletter and social media handles',
            'Opportunity to deliver online / in person expert sessions',
            'Mentoring sessions for business development',
            'Offerings covered under “Entry Edge” package.',
        ],
        color: '#F4DBFC',
    },
    {
        label: 'Standard',
        name: 'standard',
        subName: 'Supreme Solutions for Entrepreneurs',
        description: [
            'Guidance about suitable Govt. Schemes (grant / subsidy / loans)',
            'Group Mentoring sessions',
            'Business development planning & execution advisory',
            'Set up & Scale advisory',
            'Spotlight of business in newsletter and social media handles',
            'Streaming of an interview / success story in digital media (News Portal / You tube channel / Social media handles and newsletter)',
            'All Offerings covered under the “Entry Edge” package.',
        ],
        color: '#EBE7C3',
    },
]
