export const COMMENTS_LIST = [
  {
    id: 1,
    title: "Dhyey Patel",
    profilePicture: "https://api.dicebear.com/7.x/miniavs/svg?seed=2",
    comment:
      "@Chirag Vaghela That's a fantastic new app feature. You and your team did an excellent job of incorporating user testing feedback.",
  },
  {
    id: 2,
    title: "Dhyey Patel",
    profilePicture: "https://api.dicebear.com/7.x/miniavs/svg?seed=2",
    comment:
      "@Chirag Vaghela That's a fantastic new app feature. You and your team did an excellent job of incorporating user testing feedback.",
  },
  {
    id: 3,
    title: "Dhyey Patel",
    profilePicture: "https://api.dicebear.com/7.x/miniavs/svg?seed=2",
    comment:
      "@Chirag Vaghela That's a fantastic new app feature. You and your team did an excellent job of incorporating user testing feedback.",
  },
];
