import { useState } from 'react'
import { useSelector } from 'react-redux'

// antd
import { Avatar, Divider, Modal, Row, Typography, theme } from 'antd'

// slices
import { loginState } from '../login/login.slice'

// components
import ContentHeader from '../../components/contentHeader'
import ButtonComponent from '../../components/button/buttonComponent'

// modal
import ProfileModal from './profileModal'

const ProfileSection = () => {
    const { Title, Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const { userProfile } = useSelector(loginState)

    const [profileModal, setProfileModal] = useState(false)

    return (
        <>
            <ContentHeader title={'Profile'} onClick={() => {}} />
            <Row
                align={'middle'}
                style={{
                    columnGap: '40px',
                    marginTop: '40px',
                }}
            >
                <Avatar
                    style={{
                        backgroundColor: '#fde3cf',
                    }}
                    size={150}
                    src="https://api.dicebear.com/7.x/miniavs/svg?seed=2"
                ></Avatar>
                <div>
                    <Title
                        style={{
                            margin: '0px',
                        }}
                        level={2}
                    >
                        {userProfile?.name}
                    </Title>
                    <Text
                        style={{
                            color: token.colorPalette.textColor.senary,
                            fontSize: '26px',
                        }}
                    >
                        {userProfile?.type}
                    </Text>
                </div>
            </Row>
            <Divider />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '20px',
                }}
            >
                <Text
                    style={{
                        color: token.colorPalette.iconColor.senary,
                        fontSize: '24px',
                    }}
                >
                    Email Address
                </Text>
                <Text
                    style={{
                        color: token.colorPalette.iconColor.error,
                        fontSize: '26px',
                    }}
                >
                    {userProfile?.email}
                </Text>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '20px',
                    marginTop: '40px',
                }}
            >
                <Text
                    style={{
                        color: token.colorPalette.iconColor.senary,
                        fontSize: '24px',
                    }}
                >
                    Mobile Number
                </Text>
                <Text
                    style={{
                        color: token.colorPalette.iconColor.error,
                        fontSize: '26px',
                    }}
                >
                    {userProfile?.phone}
                </Text>
            </div>
            <div
                style={{
                    marginTop: '40px',
                }}
            >
                <ButtonComponent
                    props={{
                        buttonText: 'Edit Profile',
                        onClick: () => setProfileModal(true),
                    }}
                />
            </div>
            <Modal
                footer={null}
                title="Edit Profile"
                open={profileModal}
                onCancel={() => setProfileModal(false)}
            >
                <ProfileModal />
            </Modal>
        </>
    )
}

export default ProfileSection
