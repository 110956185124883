import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'

// antd
import { Popover, Typography, theme } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

// components
import ButtonComponent from '../../../components/button/buttonComponent'

// assets
import LogoIcon from '../../../assets/icons/landingPage/logo'

// constants
import { PUBLIC_ROUTES } from '../../../constants'

// css
import './headerSection.css'

// context
import { useWindowWidth } from '../../../windowContext'

function HeaderSection() {
    const { Text } = Typography

    const { useToken } = theme

    const { token } = useToken()

    const navigate = useNavigate()

    const params = useLocation()

    const windowWidth = useWindowWidth()

    const [scrollTrue, setScrollTrue] = useState(false)

    // SCROLL SECTION
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId)

        if (section) {
            section.scrollIntoView({ behavior: 'smooth' })
        }
    }

    // SCROLL WHEN IN ABOUT-US SECTION
    useEffect(() => {
        if (scrollTrue) {
            setTimeout(() => {
                scrollToSection('ourServicesId')
                setScrollTrue(false)
            }, 2000)
        }
    }, [scrollTrue])

    // HEADER TABS
    const HEADER_TABS = [
        {
            text: 'Home',
            onClick: () => navigate('/'),
            key: 'home',
        },

        {
            text: 'About Us',
            onClick: () => navigate(PUBLIC_ROUTES.aboutUs),
            key: 'about',
        },
        {
            text: 'Services',
            onClick: () => {
                if (
                    params.pathname.includes('about') ||
                    params.pathname.includes('blog')
                ) {
                    setScrollTrue(true)
                    setTimeout(() => {
                        navigate('/')
                    }, 1000)
                } else {
                    scrollToSection('ourServicesId')
                }
            },

            key: 'services',
        },
        {
            text: `Events`,
            onClick: () => {
                if (
                    params.pathname.includes('about') ||
                    params.pathname.includes('blog')
                ) {
                    setScrollTrue(true)
                    setTimeout(() => {
                        navigate('/')
                    }, 1000)
                } else {
                    scrollToSection('eventSectionMain')
                }
            },
            key: 'events',
        },
        {
            text: 'Resources',
            onClick: () => {
                navigate(PUBLIC_ROUTES.blog)
            },
            key: 'resources',
        },
        {
            text: 'Contact Us',
            onClick: () => {
                navigate(PUBLIC_ROUTES.inquiry)
            },
            key: 'contactUs',
        },
    ]

    // HEADER MENU CONTENT
    const HeaderMenuContent = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '20px',
            }}
        >
            <div>
                <Text className="navBarLink" onClick={() => navigate('/')}>
                    Home
                </Text>
            </div>

            <div>
                <Text
                    onClick={() => navigate(PUBLIC_ROUTES.aboutUs)}
                    className="navBarLink"
                >
                    About Us
                </Text>
            </div>
            <div>
                <Text
                    className="navBarLink"
                    onClick={() => {
                        if (
                            params.pathname.includes('about') ||
                            params.pathname.includes('blog')
                        ) {
                            setScrollTrue(true)
                            setTimeout(() => {
                                navigate('/')
                            }, 1000)
                        } else {
                            scrollToSection('ourServicesId')
                        }
                    }}
                >
                    Services
                </Text>
            </div>
            <div>
                <Text
                    onClick={() => {
                        if (
                            params.pathname.includes('about') ||
                            params.pathname.includes('blog')
                        ) {
                            setScrollTrue(true)
                            setTimeout(() => {
                                navigate('/')
                            }, 1000)
                        } else {
                            scrollToSection('eventSectionMain')
                        }
                    }}
                    className="navBarLink"
                >
                    Events
                </Text>
            </div>
            <div>
                <Text
                    onClick={() => {
                        navigate(PUBLIC_ROUTES.blog)
                    }}
                    className="navBarLink"
                >
                    Resources
                </Text>
            </div>
            <div>
                <Text
                    onClick={() => {
                        navigate(PUBLIC_ROUTES.inquiry)
                    }}
                    className="navBarLink"
                >
                    Contact Us
                </Text>
            </div>
            {windowWidth <= 550 && (
                <>
                    {/* <div>
                        <Text
                            onClick={() => {
                                navigate(PUBLIC_ROUTES.login)
                            }}
                            className="navBarLink"
                        >
                            Sign In / Sign Up
                        </Text>
                    </div> */}
                </>
            )}
        </div>
    )

    return (
        <>
            <div className="navBarOuter">
                <div className="logoMainHeader" onClick={() => navigate('/')}>
                    <LogoIcon />
                </div>
                <div className="navBarOuterLink">
                    {windowWidth > token.breakPoints.md ? (
                        HEADER_TABS?.length > 0 &&
                        HEADER_TABS.map((tabData) => (
                            <div>
                                <Text
                                    className={[
                                        'navBarLink',
                                        `selectedHeader-${tabData?.key}`,
                                    ]}
                                    onClick={tabData?.onClick}
                                >
                                    {tabData?.text}
                                </Text>
                            </div>
                        ))
                    ) : (
                        <Popover
                            arrow={false}
                            placement="bottom"
                            content={HeaderMenuContent}
                            trigger={'click'}
                            overlayInnerStyle={{
                                padding: '20px 30px',
                            }}
                        >
                            <MenuOutlined className="menuHeaderIcon" />
                        </Popover>
                    )}
                    {windowWidth > 550 && (
                        <>
                            {/* <div>
                                <ButtonComponent
                                    props={{
                                        onClick: () => {
                                            navigate(PUBLIC_ROUTES.login)
                                        },
                                        buttonText: 'Sign In / Sign Up',
                                    }}
                                />
                            </div> */}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default HeaderSection
