import React from "react";

function PayByHourIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#67DAFF"
        d="M11.457 20.364c0-4.913 3.998-8.91 8.911-8.91.987 0 1.974.17 2.935.506.069.024.14.036.21.036h.025a.635.635 0 00.637-.636.651.651 0 00-.039-.222C23.646 4.878 18.39 0 12.094 0 5.425 0 0 5.424 0 12.09c0 6.358 4.973 11.652 11.322 12.054a.636.636 0 00.64-.845 8.913 8.913 0 01-.505-2.935zm-2.734-4.005a.634.634 0 01-.9 0 .636.636 0 010-.9l3.632-3.632V4.455a.636.636 0 111.272 0v7.636a.636.636 0 01-.186.45l-3.818 3.818z"
      ></path>
      <path
        fill="#006D90"
        d="M20.363 12.727c-4.21 0-7.636 3.425-7.636 7.636S16.152 28 20.363 28 28 24.574 28 20.363c0-4.21-3.426-7.636-7.637-7.636zm-.318 7h.637c1.228 0 2.227 1 2.227 2.227 0 1.12-.833 2.04-1.91 2.195v.669a.636.636 0 11-1.272 0v-.636h-1.273a.636.636 0 110-1.273h2.228a.956.956 0 00.954-.955.956.956 0 00-.954-.954h-.637a2.23 2.23 0 01-2.227-2.227c0-1.12.832-2.04 1.91-2.196v-.668a.636.636 0 111.272 0v.636h1.273a.636.636 0 110 1.273h-2.228a.956.956 0 00-.954.955c0 .526.428.954.954.954z"
      ></path>
    </svg>
  );
}

export default PayByHourIcon;
