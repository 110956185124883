import AccountingIcon from '../../assets/icons/landingPage/servicesSection/accountingIcon'
import BusinessWellnessIcon from '../../assets/icons/landingPage/servicesSection/businessWellnessIcon'
import ConsultancyIcon from '../../assets/icons/landingPage/servicesSection/consultancyIcon'
import EnterpriseIcon from '../../assets/icons/landingPage/servicesSection/enterpriseIcon'
import FinanceIcon from '../../assets/icons/landingPage/servicesSection/financeIcon'
import MarketingIcon from '../../assets/icons/landingPage/servicesSection/marketingIcon'
import MentoringIcon from '../../assets/icons/landingPage/servicesSection/mentoringIcon'
import RegistrationIcon from '../../assets/icons/landingPage/servicesSection/registrationIcon'
import SetUpIcon from '../../assets/icons/landingPage/servicesSection/setUpIcon'

export const SERVICE_SECTION_CONTENT = {
    title: 'Featured Services',
    description:
        'Discover the right professional for business support services. Our digital platform provides integrated professional services designed to meet all your business needs in one place. Experience seamless integration and holistic support for your business through our user-friendly digital platform.',
    tabContent: [
        {
            key: '1',
            label: 'Registration',
            title: 'Registration / Compliance/ Certification',
            description: [
                'MSME (Udyam)',
                'GST',
                'FSSAI',
                'ISO/ GMP Quality assurance Certification',
                'Startup India / DPIIT registration',
                'Partnership / LLP / OPC / Pvt. Ltd. registration',
                'Intellectual properties (Trademark/ Copyright)',
            ],
            icon: <RegistrationIcon />,
        },
        {
            key: '2',
            label: 'Accounting',
            title: 'Accounting / Book Keeping / Taxation',
            description: [
                'Bookkeeping -Domestic',
                'Bookkeeping -Overseas',
                'Financial Planning & Reporting',
                'Income tax- Compliance, ITR Filing and Assessment',
                'GST Compliance, Return Filing and Assessment',
            ],
            icon: <AccountingIcon />,
        },
        {
            key: '3',
            label: 'Business',
            title: 'Business Setup / Scale',
            description: [
                'Business Plan',
                'Project Report',
                'Financial projections',
                'Pitch Deck',
                'Investor Connect',
                'System and Data security knowledge impart',
                'Business Automation consultancy',
            ],
            icon: <SetUpIcon />,
        },
        {
            key: '4',
            label: 'Finance',
            title: 'Finance & Government Assistance',
            description: [
                'Eligibility Assessment',
                'Guidance of Relevant Govt. Schemes (Grant, Subsidies and Debt)',
                'MSME Assistance',
                'Bank and NBFC business loans',
                'Private Equity / Venture capital',
            ],
            icon: <FinanceIcon />,
        },
        {
            key: '5',
            label: 'Marketing',
            title: 'Marketing',
            description: [
                'Market expansion',
                'Digital presence (Website / Application)',
                'Logo Designing',
                'Social Media Strategy / Management',
                'Digital Media Management',
                'Branding (Strategy / Consultancy)',
                'Collaboration / Brand Visibility',
                'Lead Generation',
                'Google my business listing and management',
                'Export potential assessment / Procedural guidance',
                'Product / Service video presentation/ portfolio',
                'Product photography',
                'Braucher / posters / presentation packages',
                'Influencer  Marketing',
                'E-commerce presence management',
            ],
            icon: <MarketingIcon />,
        },
        {
            key: '7',
            label: 'Wellness',
            title: 'Business Wellness',
            description: [
                'Goal Setting / Success motivation sessions',
                'Credit Health Improvement',
                'Corporate astrology / Numerology / Industrial Vastu consultancy',
                'Business wellness retreats / curated programs',
            ],
            icon: <BusinessWellnessIcon />,
        },
        {
            key: '8',
            label: 'Mentoring',
            title: 'Mentoring / Training / Coaching',
            description: [
                'Sector specific & product specific training programs & courses  (Herbal Products, cloud kitchen, specific food product, etc.) Handholding (Especially for startups)',
                'One to one mentoring',
            ],
            icon: <MentoringIcon />,
        },
        {
            key: '9',
            label: 'Recruitment',
            title: 'Recruitment Consultancy',
            description: ['Manpower Domestic and Overseas'],
            icon: <ConsultancyIcon />,
        },
        {
            key: '6',
            label: 'Services to Medium & Large Enterprises',
            title: 'Services to Medium & Large Enterprises ',
            description: [
                'Transaction Structuring',
                'M & A',
                'Insolvency related offerings',
                'Consultancy for Cross Border Transactions',
                'Virtual CFO',
                'Debt syndication in high ticket size',
                'Investor connect',
                'External directorship',
                'Cyber security',
                'System Development & audit',
                'Training & seminars on cyber fraud & cyber security',
            ],
            icon: <EnterpriseIcon />,
        },
    ],
}
