import React from "react";
import { useNavigate } from "react-router";

// antd
import { Typography } from "antd";

// components
import ButtonComponent from "../../../components/button/buttonComponent";

// assets
import input_pre from "../../../assets/images/Message.png";
import MailBackground from "../../../assets/icons/landingPage/mailBackground";
import MailMain from "../../../assets/icons/landingPage/mailMain";

// constants
import { PUBLIC_ROUTES } from "../../../constants";
import { SUBSCRIBE_SECTION_CONTENT } from "../../../constants/aboutUsPage";

// css
import "./subscribeSection.css";

const SubscribeSection = () => {
  const { Title } = Typography;

  const navigate = useNavigate();

  return (
    <>
      <div className="container subscribeFlex">
        <div className="subscribe subscribeFlex">
          <div
            style={{
              position: "relative",
            }}
          >
            <div className="mailBackgroundSvg">
              <MailBackground />
            </div>
            <div
              style={{
                position: "absolute",
              }}
              className="image2"
            >
              <MailMain />
            </div>
          </div>
          <div>
            <Title
              level={3}
              style={{
                margin: "0px",
                lineHeight: "36px",
              }}
              className="titleSubscribe"
            >
              {SUBSCRIBE_SECTION_CONTENT.title}
            </Title>
            <div
              className="flex"
              style={{
                marginTop: "32px",
              }}
            >
              <div className="input-container flex">
                <img
                  src={input_pre}
                  alt="icon"
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "6px",
                  }}
                />
                <input
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  placeholder="Enter your email"
                />
              </div>
              <ButtonComponent
                props={{
                  buttonText: "Submit",
                  onClick: () => {
                    navigate(PUBLIC_ROUTES.inquiry);
                  },
                  style: {
                    fontWeight: 600,
                    borderRadius: "200px",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribeSection;
