export const JOIN_CLINIC_SECTION = {
  title: "Ready to ignite your success?",
  header: "Join Founders' Clinic Today!",
  description:
    "Sign up now to unlock a world of opportunities, collaboration, and growth for your startup or MSME.",
};

export const JOIN_CLINIC_SECTION_TWO = {
  title: "Join us on this journey of growth and transformation.",
  header:
    "Explore a world of possibilities with Founders' Clinic as your trusted ally in achieving your business objectives.",
  description: "Together, let's redefine success in the digital age.",
};
