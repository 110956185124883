import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// slices
import { clearProject, projectList } from "./project.slice";
import { loginState } from "../login/login.slice";

// components
import ContentHeader from "../../components/contentHeader";

// screen
import ProjectListScreen from "./projectListScreen";
import AddProject from "./addProjectForm";
import { PlusIcon } from "../../assets/icons/project";

const ProjectModal = () => {
  const dispatch = useDispatch();

  const { userProfile } = useSelector(loginState);

  const [modal, setModal] = useState(false);

  useEffect(() => {
    const formData = new FormData();

    formData.append("user_id", userProfile?.id);

    dispatch(projectList(formData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentHeader
        title={userProfile?.type === "Entrepreneur" ? "Proposals" : "Inquiries"}
        buttonText={"Discover Professionals"}
        onClick={() => {
          dispatch(clearProject());
          setModal(true);
        }}
        buttonIcon={<PlusIcon />}
      />
      <ProjectListScreen
        props={{
          setOpen: () => setModal(true),
          setClose: () => setModal(false),
        }}
      />
      <AddProject
        props={{
          modalOpen: modal,
          modalClose: () => setModal(false),
        }}
      />
    </>
  );
};

export default ProjectModal;
