// antd
import { Avatar, Row, Typography } from "antd";

// constants
import { COMMENTS_LIST } from "../../constants/project";

const CommentSection = () => {
  const { Title, Text } = Typography;

  return (
    <>
      {COMMENTS_LIST?.length > 0 &&
        COMMENTS_LIST.map((comment) => (
          <div
            style={{
              marginBottom: "30px",
            }}
          >
            <Row
              align={"middle"}
              justify={"space-between"}
              style={{
                marginBottom: "7px",
              }}
            >
              <Row
                align={"middle"}
                style={{
                  columnGap: "13px",
                }}
              >
                <Avatar
                  src={comment?.profilePicture}
                  size={46}
                  style={{
                    backgroundColor: "#fde3cf",
                  }}
                />
                <Title
                  style={{
                    margin: "0px",
                  }}
                  level={5}
                >
                  {comment?.title}
                </Title>
                <Text>14 min</Text>
              </Row>
              <div
                style={{
                  padding: "12px 26px",
                  border: "1px solid #bbbbbb",
                  borderRadius: "50px",
                }}
              >
                <Title
                  level={5}
                  style={{
                    margin: "0px",
                  }}
                >
                  Chat
                </Title>
              </div>
            </Row>
            <Text>{comment?.comment}</Text>
          </div>
        ))}
    </>
  );
};

export default CommentSection;
