// antd
import { Typography } from 'antd'

// assets
import JoinImageSecondary from '../../../assets/images/joinImageSecondary.png'

// constants
import { JOIN_CLINIC_SECTION_TWO } from '../../../constants/landingPage/joinClinicSection'

// css
import './joinClinicSectionSecondary.css'

function JoinClinicSectionSecondary() {
    const { Text, Title } = Typography

    return (
        <>
            <div className="clinicMain2">
                <img
                    src={JoinImageSecondary}
                    alt=""
                    height="340px"
                    width="60%"
                    className="joinImg2"
                />
                <div className="joinfounderclinic2">
                    <div className="joinFounderInner2">
                        <Text className="ready">
                            {JOIN_CLINIC_SECTION_TWO.title}
                        </Text>
                        <Title
                            style={{
                                fontWeight: 700,
                                margin: '0px',
                            }}
                            level={3}
                            className="joinToday"
                        >
                            {JOIN_CLINIC_SECTION_TWO.header}
                        </Title>
                        <Text
                            style={{
                                fontWeight: 400,
                                lineHeight: '32px',
                            }}
                            className="signUpNow"
                        >
                            {JOIN_CLINIC_SECTION_TWO.description}
                        </Text>
                    </div>
                </div>
            </div>
        </>
    )
}
export default JoinClinicSectionSecondary
