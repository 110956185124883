import React from 'react'

function FinanceIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            height={'100%'}
            width={'100%'}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1="8.308"
                    x2="55.692"
                    y1="11.624"
                    y2="59.008"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#8e2de2"></stop>
                    <stop offset="1" stopColor="#4a00e0"></stop>
                </linearGradient>
            </defs>
            <path
                fill="url(#a)"
                d="M53.764 35.342a8.279 8.279 0 01-5.441-2.042 8.28 8.28 0 01-10.882 0 8.279 8.279 0 01-10.882 0 8.28 8.28 0 01-10.882 0A8.093 8.093 0 018.7 35.182v23.784A1.018 1.018 0 009.712 60H16V42.424a1.021 1.021 0 011.008-1.034h7.973a1.021 1.021 0 011.008 1.034V60h28.3a1.018 1.018 0 001.011-1.034V35.182a8.262 8.262 0 01-1.536.16zM46.8 47.723H33.008A1.021 1.021 0 0132 46.689v-4.265a1.021 1.021 0 011.008-1.034H46.8a1.021 1.021 0 011.008 1.034v4.265a1.021 1.021 0 01-1.008 1.034zm-27.022 3.1a1.431 1.431 0 11-1.431-1.468 1.45 1.45 0 011.43 1.472zm39.377-26.1L55.3 18.841v-7.175a1.018 1.018 0 00-1.008-1.034H40.8c0 .116.017.23.017.347 0 3.166-2.9 7.724-4.988 10.589l.471 4.055h-8.6l.474-4.055c-2.084-2.865-4.988-7.424-4.988-10.589 0-.117.013-.231.017-.347H9.712A1.018 1.018 0 008.7 11.666v7.175l-3.854 5.883a5 5 0 00-.332 4.983 6.36 6.36 0 0011.164.486 6.345 6.345 0 0010.882 0 6.345 6.345 0 0010.882 0 6.345 6.345 0 0010.882 0 6.36 6.36 0 0011.164-.486 5 5 0 00-.334-4.983zm-44.162.9H6.69l3.556-5.423h6.841zm10.67 0h-8.5l2.1-5.428h7.053zm12.675 0l-.645-5.423h7.053l2.1 5.428h-8.5zm10.67 0l-2.1-5.428h6.841l3.557 5.428h-8.3zM32 4a6.9 6.9 0 00-6.8 6.979c0 3.593 5.52 10.614 6.156 11.4a.813.813 0 001.29 0c.637-.791 6.156-7.812 6.156-11.4A6.9 6.9 0 0032 4zm-3.074 7.056A3.075 3.075 0 1132 14.21a3.122 3.122 0 01-3.074-3.154z"
            ></path>
        </svg>
    )
}

export default FinanceIcon
