import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// slices
import {
  isLoggedInLogin,
  loginState,
  setUserProfile,
} from "../pages/login/login.slice";

// constants
import {
  PRIVATE_ROOT_ROUTE,
  PRIVATE_ROUTE,
  PUBLIC_ROUTES,
  ROOT_ROUTE,
} from "../constants";

// pages
import LandingPage from "../pages/landingPage";
import AboutUsPage from "../pages/aboutusPage";
import BlogPage from "../pages/blogPage";
import ProjectModal from "../pages/project";
import JobsScreen from "../pages/jobs";

// components
import Layout from "../components/layouts/layout";
import ProjectDescriptionScreen from "../pages/project/projectDescriptionScreen";
import PublicRoute from "../pages/allRoutes/publicRoutes";
import PublicRoutesList from "../pages/allRoutes/publicRoutesList";
import PrivateRoute from "../pages/allRoutes/privateRoutes";
import ProfileSection from "../pages/profile";
import DashboardModal from "../pages/dashboard";
import BlogPageDetail from "../pages/blogPage/blogPageDetail";

const WebRoutes = () => {
  // CHECK IS LOGGED IN OR NOT
  const navigate = useNavigate();

  const params = useLocation();

  const dispatch = useDispatch();

  const { userProfile } = useSelector(loginState);

  // CHECK IS LOGIN
  async function checkIsLogin() {
    const checkLogin = await dispatch(
      isLoggedInLogin({ token: localStorage.getItem("Token") })
    );

    const checkSuccessLogin = checkLogin?.payload?.data;

    if (checkSuccessLogin) {
      if (checkSuccessLogin?.data?.success) {
        dispatch(
          setUserProfile({
            ...checkSuccessLogin?.data?.data,
            token: localStorage.getItem("Token"),
          })
        );
        navigate(PRIVATE_ROOT_ROUTE);
      } else {
        navigate(ROOT_ROUTE);
        window.localStorage.clear();
      }
      // localStorage.setItem("authToken", )
    }

    for (const key in PUBLIC_ROUTES) {
      if (ROOT_ROUTE === params.pathname) {
        navigate(PUBLIC_ROUTES["/"]);
      } else if (PUBLIC_ROUTES[key] === params?.pathname) {
        navigate(PUBLIC_ROUTES[key]);
      }
    }

    for (const key in PUBLIC_ROUTES) {
      if (ROOT_ROUTE === params.pathname) {
        navigate(PUBLIC_ROUTES["/"]);
      } else if (params?.pathname === PUBLIC_ROUTES.signUp) {
        navigate(PUBLIC_ROUTES.signUp);
        // navigate(PUBLIC_ROUTES.login)
      } else if (PUBLIC_ROUTES[key] === params?.pathname) {
        navigate(PUBLIC_ROUTES[key]);
      }
    }
    for (const key in PRIVATE_ROUTE && localStorage.getItem("Token")) {
      if (
        (PRIVATE_ROUTE[key] === params?.pathname && typeof key === "string") ||
        typeof PRIVATE_ROUTE[key] === "object"
      ) {
        navigate(PRIVATE_ROUTE[key]);
      } else {
      }
    }
  }

  // INITIALLY CHECK IS LOGIN
  useEffect(() => {
    checkIsLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path={"/"} element={<LandingPage />} />
      <Route element={<PublicRoute />}>{PublicRoutesList()}</Route>
      {userProfile && userProfile?.id && userProfile?.token !== null && (
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route
              element={<DashboardModal />}
              path={PRIVATE_ROUTE.dashboard}
            />
            <Route
              element={<ProjectModal />}
              path={PRIVATE_ROUTE.project.root}
            />
            <Route
              element={<ProjectDescriptionScreen />}
              path={PRIVATE_ROUTE.project.projectId(":id")}
            />
            <Route element={<JobsScreen />} path={PRIVATE_ROUTE.jobs} />
            <Route element={<ProfileSection />} path={PRIVATE_ROUTE.profile} />
          </Route>
        </Route>
      )}
      <Route path={PUBLIC_ROUTES.aboutUs} element={<AboutUsPage />} />
      <Route path={PUBLIC_ROUTES.blog} element={<BlogPage />} />
      <Route path={PUBLIC_ROUTES.blogId(":id")} element={<BlogPageDetail />} />
    </Routes>
  );
};

export default WebRoutes;
