import React from "react";

function BinIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke={color ? color : "#212B36"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M14.66 3.328l-6.023 6.04m16.64-6.04l6.024 6.04"
      ></path>
      <path
        stroke={color ? color : "#212B36"}
        strokeWidth="1.5"
        d="M3.328 13.063c0-3.079 1.648-3.328 3.694-3.328h25.893c2.047 0 3.694.25 3.694 3.328 0 3.578-1.647 3.328-3.694 3.328H7.022c-2.046 0-3.694.25-3.694-3.328z"
      ></path>
      <path
        stroke={color ? color : "#212B36"}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M16.241 23.297v5.907m7.655-5.907v5.907M5.824 16.641l2.347 14.377c.532 3.228 1.813 5.591 6.573 5.591h10.034c5.175 0 5.94-2.263 6.54-5.391l2.795-14.577"
      ></path>
    </svg>
  );
}

export default BinIcon;
