// ROOT ROUTE
export const ROOT_ROUTE = "/";

// PRIVATE ROOT ROUTE
export const PRIVATE_ROOT_ROUTE = "/dashboard";

// PUBLIC ROUTE
export const PUBLIC_ROUTES = {
  login: "/login",
  signUp: "/sign-up",
  verifyOtp: "/verify-otp",
  inquiry: "/inquiry",
  aboutUs: "/about-us",
  blog: "/blog",
  blogId:(id) =>`/blog/${id}`
};

// PRIVATE ROUTE
export const PRIVATE_ROUTE = {
  home: "/home",
  project: {
    root: `/project`,
    projectId: (id) => `/project/${id}`,
  },
  jobs: "/jobs",
  dashboard: "/dashboard",
  profile: "/profile",
};
