import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  verifyOtp,
  isLoggedIn,
  logOut,
  signUp,
  login,
  forgotPassword,
} from "./login.service";

const initialState = {
  status: "loaded",
  userProfile: {},
  token: null,
};

export const verifyOtpLogin = createAsyncThunk(
  "loginSlice/verifyOtp",
  async (payload) => {
    const response = await verifyOtp(payload);
    return response;
  }
);

export const isLoggedInLogin = createAsyncThunk(
  "loginSlice/isLoggedIn",
  async (payload = {}) => {
    const response = await isLoggedIn(payload);
    if (response?.data?.success) {
      // localStorage.setItem(
      //   "currentProject",
      //   JSON.stringify({
      //     id: response?.data?.data.activeProject?._id,
      //     name: response?.data?.data?.activeProject?.name,
      //   })
      // );
      return response;
    }
    return response;
  }
);

export const signOut = createAsyncThunk(
  "loginSlice/logOut",
  async (payload = {}) => {
    const response = await logOut(payload);
    return response;
  }
);

export const signUpForm = createAsyncThunk(
  "loginSlice/signUp",
  async (payload = {}) => {
    const response = await signUp(payload);
    return response;
  }
);

export const loginForm = createAsyncThunk(
  "loginSlice/login",
  async (payload = {}) => {
    const response = await login(payload);
    return response;
  }
);

export const passwordForgot = createAsyncThunk(
  "loginSlice/forgotPassword",
  async (payload = {}) => {
    const response = await forgotPassword(payload);
    return response;
  }
);

export const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(isLoggedInLogin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(isLoggedInLogin.fulfilled, (state, action) => {
        state.status = "loaded";
        state.userProfile = action.payload.data.data;
      });
  },
});

export default loginSlice.reducer;

export const loginState = (state) => {
  const { loginSlice } = state;
  const { status, userProfile, token } = loginSlice;
  return {
    status,
    userProfile,
    token,
  };
};

export const { setUserProfile } = loginSlice.actions;
