export const EVENT_SECTION_CONTENT = {
    title: 'Events',
    description:
        'Join us for a series of exciting events designed to inspire, entertain, and connect our community. Mark your calendar and be a part of these memorable experiences!',
    container: [
        {
            id: 1,
            title: 'Vibrant Gujarat Global summit 2024',
            description:
                "Founders' Clinic had successfully carried out its first ever B2B showcase at @The Vibrant Gujarat Global Tradeshow 2024. From engaging conversations to inspiring connections, it was altogether a memorable event.",
            date: '10th January, 2024',
            color: '#FCE7DB',
            iconColor: '#D1A388',
        },
        {
            id: 2,
            title: `Meetup of "Founders' Clinic" Ahmedabad circle !`,
            description:
                "This event marked the beginning of an engaging journey where entrepreneurs and professionals came together to share experiences, insights, and opportunities. Follow Founders' Clinic and join the tribe for more such interactive sessions.",
            date: '11th April, 2024',
            color: '#DEDBFC',
            iconColor: '#9C97CD',
        },
    ],
}
