import React from "react";

function CorrectIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 17 13"
    >
      <path
        stroke="#67DAFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 1.5l-10 10-5-5"
      ></path>
    </svg>
  );
}

export default CorrectIcon;
