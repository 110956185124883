import React from "react";

function EllipsisIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="22"
      fill="none"
      viewBox="0 0 5 22"
    >
      <path
        fill="#747474"
        d="M4.909 10.935a2.24 2.24 0 11-4.48 0 2.24 2.24 0 014.48 0zM4.909 3.097a2.24 2.24 0 11-4.48 0 2.24 2.24 0 014.48 0zM4.909 18.774a2.24 2.24 0 11-4.48 0 2.24 2.24 0 014.48 0z"
      ></path>
    </svg>
  );
}

export default EllipsisIcon;
