import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

// antd
import { ConfigProvider } from 'antd'

// slices
import loginSlice from '../pages/login/login.slice'
import projectSlice from '../pages/project/project.slice'

// routes
import WebRoutes from './webRoutes'

// provider
import { WindowWidthProvider } from '../windowContext'
import ScrollToTopButton from '../components/scrollToBottom'

const RouteContext = () => {
    const redux = configureStore({
        reducer: {
            loginSlice,
            projectSlice,
        },
    })

    return (
        <Provider store={redux}>
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            colorBgContainer: '#67DAFF',
                            colorText: '#000',
                            fontSize: '16px',
                            colorPrimaryHover: '#fff',
                            borderRadius: '8px',
                        },
                    },
                    token: {
                        colorPalette: {
                            baseColor: {
                                primary: '#67DAFF',
                                secondary: '#191A38',
                                tertiary: '#4F46E5',
                                quaternary: '#F7F5FF',
                                quinary: '#CFD7DB',
                                senary: '#F6F7F9',
                                white: '#FFFFFF',
                                black: '#000000',
                                error: '#FF3838',
                            },
                            textColor: {
                                primary: '#252525',
                                secondary: '#747474',
                                tertiary: '#DBD9FF',
                                quaternary: '#B9B9B9',
                                quinary: '#E3E3E3',
                                senary: '#919EAB',
                                gray: '#919EAB29',
                                error: '#FF0000',
                            },
                            iconColor: {
                                primary: '#CFD7DB',
                                secondary: '#DADADA',
                                tertiary: '#1B806A',
                                quaternary: '#B71D18',
                                quinary: '#67daff33',
                                senary: '#727272',
                                error: '#1a1a1a',
                            },
                            registrationColor: {
                                primary: '##031C38',
                                secondary: '#F828A6',
                            },
                        },
                        breakPoints: {
                            xs: 400,
                            sm: 768,
                            md: 1200,
                            lg: 1536,
                            xl: 1920,
                        },
                        fontSizes: {},
                        fontFamily: 'Poppins',
                    },
                }}
            >
                <BrowserRouter>
                    <WindowWidthProvider>
                        <ScrollToTopButton />
                        <WebRoutes />
                    </WindowWidthProvider>
                </BrowserRouter>
            </ConfigProvider>
        </Provider>
    )
}

export default RouteContext
