import React from "react";

function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      fill="none"
      viewBox="0 0 14 18"
    >
      <path
        fill="#637381"
        d="M6.998 11.646c-3.594 0-6.664.566-6.664 2.833 0 2.267 3.05 2.854 6.664 2.854 3.594 0 6.664-.566 6.664-2.833 0-2.268-3.05-2.854-6.664-2.854z"
      ></path>
      <path
        fill="#637381"
        d="M6.998 9.486a4.394 4.394 0 004.41-4.41 4.394 4.394 0 00-4.41-4.41 4.395 4.395 0 00-4.41 4.41 4.395 4.395 0 004.41 4.41z"
        opacity="0.4"
      ></path>
    </svg>
  );
}

export default ProfileIcon;
