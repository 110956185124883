import React from 'react'

function MentoringIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 683 683"
        >
            <g
                fillRule="evenodd"
                clipPath="url(#clip0_219_3202)"
                clipRule="evenodd"
            >
                <path
                    fill="url(#paint0_linear_219_3202)"
                    d="M296.453 682.569c-21.6 0-40.993-12.96-49.729-32.16-6.24 2.4-12.96 3.744-19.872 3.744-29.953 0-54.337-24.384-54.337-54.337 0-2.688.096-5.28.48-7.776-34.944-1.152-62.785-29.952-62.785-65.185 0-14.496 4.8-28.416 13.344-39.553-22.944-10.272-38.208-33.216-38.208-59.425 0-29.76 19.584-54.816 47.04-62.688-1.632-5.761-2.592-11.713-2.592-17.857 0-28.416 18.145-52.897 44.257-61.729-.672-3.456-1.056-7.008-1.056-10.656 0-29.952 24.384-54.337 54.433-54.337 6.24 0 12.384 1.056 18.048 3.072.576-1.536 1.248-3.072 1.92-4.608 2.208-4.608 7.68-6.624 12.384-4.32a9.3 9.3 0 014.416 12.384c-1.728 3.744-2.976 7.297-3.456 11.041-.48 3.168-2.4 5.856-5.376 7.2-2.88 1.344-6.24 1.056-8.928-.672-5.76-3.744-12.384-5.568-19.2-5.568-19.68 0-35.713 15.936-35.713 35.712 0 4.608.96 9.312 2.784 13.824 1.056 2.688.96 5.568-.48 8.16-1.536 2.497-3.936 4.129-6.72 4.609-22.464 3.744-38.784 23.136-38.784 45.888 0 7.2 1.632 14.304 4.992 20.833 1.248 2.784 1.248 5.952-.288 8.64-1.632 2.688-4.32 4.416-7.392 4.8-23.905 2.208-41.953 22.08-41.953 46.272 0 22.753 16.32 42.049 38.689 45.793 3.552.672 6.528 3.36 7.392 6.912a9.129 9.129 0 01-3.264 9.6c-11.329 8.833-17.857 22.177-17.857 36.673 0 25.728 20.929 46.657 46.465 46.657 2.592 0 5.472-.288 8.544-.768 3.36-.576 6.816.672 8.928 3.456 2.112 2.688 2.592 6.336 1.248 9.408-2.016 4.416-2.976 9.408-2.976 14.304 0 19.68 16.033 35.713 35.713 35.713a36.37 36.37 0 0019.968-5.952c2.592-1.728 5.952-2.208 8.832-.96 2.976 1.152 5.088 3.744 5.568 6.816 3.456 16.512 18.145 28.608 35.137 28.608 19.68 0 35.713-15.936 35.713-35.712a9.26 9.26 0 019.312-9.313 9.26 9.26 0 019.312 9.313c.384 29.76-24.001 54.144-53.953 54.144z"
                ></path>
                <path
                    fill="url(#paint1_linear_219_3202)"
                    d="M217.155 309.508c-.864 0-1.632-.192-2.4-.288l-31.392-8.16c-4.897-1.344-7.969-6.336-6.625-11.328 1.344-4.896 6.433-7.968 11.329-6.624l31.296 8.16c4.992 1.248 8.064 6.336 6.72 11.328-1.056 4.224-4.8 6.912-8.928 6.912z"
                ></path>
                <path
                    fill="url(#paint2_linear_219_3202)"
                    d="M193.54 405.99h-.576c-39.169-2.304-55.297-27.84-55.969-29.088-2.688-4.416-1.344-10.08 3.072-12.768 4.416-2.688 10.176-1.344 12.768 3.072.576.96 12.289 18.528 41.185 20.16 5.28.192 9.12 4.608 8.832 9.792-.288 4.992-4.416 8.832-9.312 8.832z"
                ></path>
                <path
                    fill="url(#paint3_linear_219_3202)"
                    d="M140.834 492.104c-2.784 0-5.376-1.248-7.296-3.456-3.168-4.032-2.496-9.888 1.44-13.056.672-.576 17.856-14.017 48.865-13.057 5.184.096 9.12 4.32 9.024 9.601-.096 5.184-4.416 9.12-9.6 9.024-23.713-.768-36.481 8.928-36.673 8.928-1.536 1.344-3.552 2.016-5.76 2.016z"
                ></path>
                <path
                    fill="url(#paint4_linear_219_3202)"
                    d="M185.571 590.985c-3.744 0-7.393-2.304-8.737-6.048-1.824-4.8.672-10.176 5.473-11.904 6.432-2.4 24.384-10.752 31.392-19.872 2.976-4.128 8.928-4.992 13.056-1.92 4.128 3.072 4.896 8.928 1.824 13.056-11.328 15.072-36.672 24.96-39.552 26.016-1.248.576-2.4.672-3.456.672z"
                ></path>
                <path
                    fill="url(#paint5_linear_219_3202)"
                    d="M252.197 646.378a9.26 9.26 0 01-9.312-9.312v-21.312a9.26 9.26 0 019.312-9.313 9.26 9.26 0 019.312 9.313v21.312c0 5.184-4.224 9.312-9.312 9.312z"
                ></path>
                <path
                    fill="url(#paint6_linear_219_3202)"
                    d="M215.139 510.919c-1.248 0-2.4-.288-3.648-.672-4.8-2.016-6.912-7.488-4.896-12.288 6.528-15.456 30.624-41.857 76.225-31.104 5.088 1.248 8.16 6.336 6.912 11.232-1.152 5.088-6.144 8.16-11.136 6.912-41.185-9.696-54.241 18.912-54.817 20.256-1.44 3.456-4.896 5.664-8.64 5.664z"
                ></path>
                <path
                    fill="url(#paint7_linear_219_3202)"
                    d="M294.724 551.528c-7.008 0-11.904-.768-12.384-.96-5.088-.768-8.544-5.664-7.68-10.752.864-5.088 5.76-8.544 10.848-7.584.384.096 33.792 5.184 48.193-16.417 2.976-4.32 8.736-5.472 12.96-2.496 4.32 2.88 5.472 8.64 2.496 12.865-14.496 21.696-39.361 25.344-54.433 25.344z"
                ></path>
                <path
                    fill="url(#paint8_linear_219_3202)"
                    d="M386.503 682.57c-29.953 0-54.337-24.384-54.337-54.433V411.942a9.26 9.26 0 019.312-9.312 9.26 9.26 0 019.312 9.312v216.195c0 19.777 16.033 35.713 35.713 35.713 16.992 0 31.776-12 35.04-28.609.672-3.072 2.88-5.568 5.664-6.816 2.881-1.152 6.145-.768 8.737.96 5.856 3.936 12.864 5.952 20.064 5.952 19.68 0 35.712-15.936 35.712-35.712 0-4.896-1.056-9.888-2.976-14.208-1.344-3.264-.96-6.817 1.248-9.505 2.112-2.688 5.569-4.032 8.929-3.456 3.072.576 5.952.768 8.448.768 25.632 0 46.56-20.928 46.56-46.656 0-14.401-6.528-27.649-17.856-36.577a9.299 9.299 0 01-3.36-9.6c.96-3.648 3.84-6.336 7.488-7.008 22.369-3.84 38.689-23.136 38.689-45.793 0-24.096-18.144-43.969-42.049-46.273-3.072-.288-5.664-2.016-7.296-4.8-1.632-2.784-1.632-5.856-.288-8.64 3.168-6.528 4.896-13.632 4.896-20.832a46.31 46.31 0 00-38.784-45.793c-2.785-.576-5.185-2.208-6.625-4.704a9.057 9.057 0 01-.48-8.16 35.556 35.556 0 002.784-13.824c0-19.681-16.032-35.713-35.712-35.713-7.008 0-13.536 2.016-19.2 5.568-2.688 1.728-6.048 2.016-8.929.672-2.976-1.344-4.992-3.936-5.376-7.2-.48-3.84-1.728-7.584-3.36-10.944-2.208-4.608-.384-10.272 4.128-12.48 4.704-2.304 10.273-.384 12.577 4.128.768 1.44 1.44 3.072 2.016 4.608a56.61 56.61 0 0118.144-2.976c29.952 0 54.337 24.384 54.337 54.337 0 3.552-.384 7.2-1.056 10.656 26.112 8.832 44.256 33.312 44.256 61.729 0 6.144-.768 12.096-2.592 17.856 27.649 7.872 47.137 32.929 47.137 62.593 0 26.305-15.36 49.249-38.304 59.521 8.64 11.136 13.344 25.056 13.344 39.553 0 35.136-28.033 63.937-62.785 65.185.384 2.496.576 5.184.576 7.68 0 30.048-24.385 54.433-54.433 54.433-6.912 0-13.536-1.248-19.872-3.744-8.353 19.584-27.649 32.544-49.441 32.544z"
                ></path>
                <path
                    fill="url(#paint9_linear_219_3202)"
                    d="M465.8 309.508c-4.128 0-7.872-2.784-9.024-6.912-1.344-4.992 1.728-10.176 6.624-11.328l31.392-8.16c4.896-1.344 10.08 1.728 11.328 6.624 1.248 4.992-1.728 10.176-6.72 11.328l-31.296 8.16c-.672.288-1.44.288-2.304.288z"
                ></path>
                <path
                    fill="url(#paint10_linear_219_3202)"
                    d="M489.319 405.99c-4.992 0-9.12-3.84-9.408-8.832-.192-5.184 3.648-9.6 8.832-9.792 29.569-1.824 41.089-20.064 41.185-20.16 2.688-4.416 8.448-5.76 12.768-3.072 4.416 2.688 5.76 8.352 3.072 12.768-.672 1.056-16.8 26.784-55.969 29.088h-.48z"
                ></path>
                <path
                    fill="url(#paint11_linear_219_3202)"
                    d="M542.024 492.104c-1.92 0-3.936-.672-5.664-2.016-.672-.576-13.248-9.888-36.672-9.024-5.184.096-9.504-3.936-9.6-9.12-.096-5.184 3.84-9.408 9.024-9.6 30.912-.96 48.193 12.576 48.769 13.056 4.032 3.264 4.704 9.12 1.536 13.056-1.92 2.4-4.705 3.648-7.393 3.648z"
                ></path>
                <path
                    fill="url(#paint12_linear_219_3202)"
                    d="M497.384 590.985c-1.056 0-2.208-.096-3.264-.672-2.88-1.056-28.129-10.944-39.649-26.016-3.072-4.128-2.208-9.984 1.92-13.056 4.128-3.072 9.984-2.208 13.056 1.92 6.913 9.12 24.865 17.472 31.297 19.872a9.32 9.32 0 015.472 11.904c-1.44 3.744-5.088 6.048-8.832 6.048z"
                ></path>
                <path
                    fill="url(#paint13_linear_219_3202)"
                    d="M430.759 646.378a9.26 9.26 0 01-9.312-9.312v-21.312a9.26 9.26 0 019.312-9.313 9.26 9.26 0 019.313 9.313v21.312a9.26 9.26 0 01-9.313 9.312z"
                ></path>
                <path
                    fill="url(#paint14_linear_219_3202)"
                    d="M467.816 510.92c-3.552 0-7.008-2.208-8.544-5.76-.672-1.344-13.728-29.856-54.721-20.256-5.088 1.152-9.984-2.016-11.232-6.912-1.152-5.088 1.824-10.081 6.912-11.233 45.505-10.656 69.697 15.745 76.225 31.009 2.016 4.8-.192 10.272-4.992 12.288-1.248.576-2.4.864-3.648.864z"
                ></path>
                <path
                    fill="url(#paint15_linear_219_3202)"
                    d="M388.231 551.528c-15.073 0-39.937-3.648-54.529-25.153-2.88-4.32-1.728-10.176 2.592-12.96 4.224-2.976 10.08-1.728 12.864 2.496 14.688 21.888 48.001 16.416 48.289 16.416 5.088-.96 9.888 2.496 10.848 7.585.768 5.088-2.592 9.888-7.68 10.848-.672 0-5.472.768-12.384.768z"
                ></path>
                <path
                    fill="url(#paint16_linear_219_3202)"
                    d="M387.175 320.645h-91.298c-12.672 0-22.944-10.273-22.944-22.849v-2.4c-6.624-49.248-22.08-65.473-37.057-81.121-15.648-16.512-30.624-32.064-30.624-77.953 0-75.17 61.153-136.226 136.226-136.226 75.073 0 136.226 61.057 136.226 136.226 0 45.889-14.976 61.441-30.624 77.953-14.881 15.648-30.145 31.776-36.961 80.449v3.072c0 12.576-10.272 22.849-22.944 22.849zM341.478 18.816c-64.801 0-117.602 52.8-117.602 117.506 0 38.4 11.232 50.113 25.537 65.089 15.456 16.128 34.656 36.384 42.048 92.065 0 .384.192.768.192 1.248v3.072c0 2.208 2.016 4.224 4.224 4.224h91.298c2.304 0 4.32-2.016 4.32-4.224v-1.92c-.192-.768-.192-1.632 0-2.496 7.392-55.777 26.592-75.841 42.048-91.969 14.305-14.976 25.441-26.689 25.441-65.089 0-64.897-52.705-117.506-117.506-117.506z"
                ></path>
                <path
                    fill="url(#paint17_linear_219_3202)"
                    d="M388.903 355.494h-94.754c-14.688 0-26.688-12-26.688-26.688 0-14.689 12-26.689 26.688-26.689 5.184 0 9.312 4.128 9.312 9.408a9.26 9.26 0 01-9.312 9.312 7.95 7.95 0 00-7.968 7.969 7.948 7.948 0 007.968 7.968h94.754c4.416 0 7.968-3.744 7.968-7.968a7.95 7.95 0 00-7.968-7.969 9.26 9.26 0 01-9.312-9.312c0-5.28 4.128-9.408 9.312-9.408 14.688 0 26.688 12 26.688 26.689 0 14.688-12 26.688-26.688 26.688z"
                ></path>
                <path
                    fill="url(#paint18_linear_219_3202)"
                    d="M388.903 390.15h-94.754c-14.688 0-26.688-12-26.688-26.688 0-14.688 12-26.689 26.688-26.689 5.184 0 9.312 4.129 9.312 9.409a9.26 9.26 0 01-9.312 9.312 7.948 7.948 0 00-7.968 7.968 7.948 7.948 0 007.968 7.968h94.754a7.948 7.948 0 007.968-7.968 7.948 7.948 0 00-7.968-7.968 9.26 9.26 0 01-9.312-9.312c0-5.28 4.128-9.409 9.312-9.409 14.688 0 26.688 12.001 26.688 26.689s-12 26.688-26.688 26.688z"
                ></path>
                <path
                    fill="url(#paint19_linear_219_3202)"
                    d="M353.957 421.35h-25.152c-16.609 0-30.145-13.536-30.145-30.144v-10.368c0-5.28 4.128-9.408 9.312-9.408 5.28 0 9.408 4.128 9.408 9.408v10.368c0 6.24 5.185 11.424 11.425 11.424h25.152c6.432 0 11.52-5.088 11.52-11.424v-10.368c0-5.28 4.128-9.408 9.312-9.408 5.184 0 9.312 4.128 9.312 9.408v10.368c.192 16.512-13.44 30.144-30.144 30.144z"
                ></path>
                <path
                    fill="url(#paint20_linear_219_3202)"
                    d="M326.789 320.644c-4.128 0-7.872-2.784-9.024-6.912l-18.72-69.409c-1.248-4.992 1.632-10.177 6.528-11.521 4.992-1.248 10.176 1.632 11.52 6.529l18.624 69.505c1.344 4.896-1.536 10.08-6.528 11.424-.768.288-1.536.384-2.4.384z"
                ></path>
                <path
                    fill="url(#paint21_linear_219_3202)"
                    d="M356.167 320.646c-.864 0-1.632-.096-2.4-.192-4.992-1.345-8.064-6.433-6.72-11.521l18.72-69.409c1.344-4.992 6.336-7.968 11.424-6.528 4.896 1.344 7.968 6.336 6.624 11.424l-18.624 69.505c-1.152 3.936-4.896 6.721-9.024 6.721z"
                ></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_219_3202"
                    x1="217.878"
                    x2="217.878"
                    y1="213.797"
                    y2="682.569"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_219_3202"
                    x1="201.411"
                    x2="201.411"
                    y1="282.791"
                    y2="309.508"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_219_3202"
                    x1="169.24"
                    x2="169.24"
                    y1="362.752"
                    y2="405.99"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_219_3202"
                    x1="162.202"
                    x2="162.202"
                    y1="462.486"
                    y2="492.104"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_219_3202"
                    x1="203.345"
                    x2="203.345"
                    y1="549.4"
                    y2="590.985"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_219_3202"
                    x1="252.197"
                    x2="252.197"
                    y1="606.441"
                    y2="646.378"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear_219_3202"
                    x1="247.922"
                    x2="247.922"
                    y1="464.35"
                    y2="510.919"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear_219_3202"
                    x1="312.671"
                    x2="312.671"
                    y1="511.662"
                    y2="551.528"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint8_linear_219_3202"
                    x1="464.792"
                    x2="464.792"
                    y1="213.488"
                    y2="682.57"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint9_linear_219_3202"
                    x1="481.432"
                    x2="481.432"
                    y1="282.791"
                    y2="309.508"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint10_linear_219_3202"
                    x1="513.527"
                    x2="513.527"
                    y1="362.752"
                    y2="405.99"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint11_linear_219_3202"
                    x1="520.749"
                    x2="520.749"
                    y1="462.295"
                    y2="492.104"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint12_linear_219_3202"
                    x1="479.703"
                    x2="479.703"
                    y1="549.4"
                    y2="590.985"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint13_linear_219_3202"
                    x1="430.759"
                    x2="430.759"
                    y1="606.441"
                    y2="646.378"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint14_linear_219_3202"
                    x1="435.137"
                    x2="435.137"
                    y1="464.293"
                    y2="510.92"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint15_linear_219_3202"
                    x1="370.261"
                    x2="370.261"
                    y1="511.783"
                    y2="551.528"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint16_linear_219_3202"
                    x1="341.478"
                    x2="341.478"
                    y1="0.096"
                    y2="320.645"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint17_linear_219_3202"
                    x1="341.526"
                    x2="341.526"
                    y1="302.117"
                    y2="355.494"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint18_linear_219_3202"
                    x1="341.526"
                    x2="341.526"
                    y1="336.773"
                    y2="390.15"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint19_linear_219_3202"
                    x1="341.382"
                    x2="341.382"
                    y1="371.43"
                    y2="421.35"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint20_linear_219_3202"
                    x1="317.4"
                    x2="317.4"
                    y1="232.512"
                    y2="320.644"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <linearGradient
                    id="paint21_linear_219_3202"
                    x1="365.431"
                    x2="365.431"
                    y1="232.633"
                    y2="320.646"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0487FF"></stop>
                    <stop offset="1" stopColor="#0CD3FF"></stop>
                </linearGradient>
                <clipPath id="clip0_219_3202">
                    <path fill="#fff" d="M0 0H682.667V682.667H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default MentoringIcon
