import axiosNew from "../../helpers/axios";

// VERIFY OTP
export const verifyOtp = async (payload) => {
  let data = payload?.body ?? {};
  return new Promise(async (resolve) => {
    try {
      const verifyOtp = await axiosNew({
        url: "/verifyotp",
        data,
        headers: {
          Authorization: `Bearer ${payload?.token}`,
        },
      });
      resolve({ data: verifyOtp?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// CHECK LOGIN OR NOT
export const isLoggedIn = async (payload) => {
  return new Promise(async (resolve) => {
    try {
      const isLoggedIn = await axiosNew({
        url: "/islogin",
        headers: {
          Authorization: `Bearer ${payload?.token}`,
        },
      });
      resolve({ data: isLoggedIn?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// LOG OUT
export const logOut = async () => {
  return new Promise(async (resolve) => {
    try {
      const logOut = await axiosNew({
        url: "/logout",
      });
      resolve({ data: logOut?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// SIGN UP
export const signUp = async (payload) => {
  let data = payload ?? {};
  return new Promise(async (resolve) => {
    try {
      const signUp = await axiosNew({
        url: "/register",
        data,
      });
      resolve({ data: signUp?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// LOGIN
export const login = async (payload) => {
  return new Promise(async (resolve) => {
    let data = payload ?? {};
    try {
      const loginData = await axiosNew({
        url: "/login",
        data,
      });
      resolve({ data: loginData?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// FORGOT PASSWORD
export const forgotPassword = async (payload) => {
  return new Promise(async (resolve) => {
    let data = payload ?? {};
    try {
      const forgotPassword = await axiosNew({
        url: "auth/forgot-password",
        data,
      });
      resolve({ data: forgotPassword?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};
