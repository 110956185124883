// antd
import { Divider, Modal, Row, Typography, theme } from "antd";

// assets
import { BinIcon } from "../../assets/icons/project";
import ButtonDashboard from "../button/buttonDashboard";

import "../button/button.css";

const DeletePopUp = ({
  previewDelete,
  onCancel,
  onDelete,
  modalTitle,
  loadingButton,
  pageKey,
  keyModal,
}) => {
  const { Title, Text } = Typography;

  const { useToken } = theme;

  const { token } = useToken();

  const checkKeyModal = (key) => {
    switch (key) {
      case "logout":
        return "Are you sure you want to logout?";

      default:
        return `Are you sure you want to delete your ${pageKey}?`;
    }
  };

  return (
    <Modal
      closable={false}
      open={previewDelete}
      destroyOnClose={true}
      onCancel={onCancel}
      centered
      style={{
        width: "426px",
        maxWidth: "426px",
        padding: "0px",
        borderRadius: "10px",
      }}
      title={null}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "40px",
            width: "40px",
            marginBottom: "15px",
          }}
        >
          <BinIcon />
        </div>
        <Title level={4} style={{ margin: 0, textTransform: "capitalize" }}>
          {modalTitle}
        </Title>
        <Text
          style={{
            fontSize: "16px",
            marginTop: "15px",
            textAlign: "center",
          }}
        >
          {checkKeyModal(keyModal)}
        </Text>
        <Divider />
        <Row
          align={"middle"}
          justify={"space-between"}
          className="deletePopUp"
          style={{
            columnGap: "10px",
          }}
        >
          <ButtonDashboard
            props={{
              buttonText: "Cancel",
              onClick: onCancel,
              buttonType: "text",
              className: "transparentButton",
              style: {
                backgroundColor: "transparent",
                color: token.colorPalette.baseColor.black,
              },
            }}
          />
          <ButtonDashboard
            props={{
              buttonText: keyModal === "logout" ? "Logout" : "Delete",
              onClick: onDelete,
              danger: true,
              loadingButton,
              style: {
                color: token.colorPalette.baseColor.white,
                backgroundColor: token.colorPalette.textColor.error,
              },
            }}
          />
        </Row>
      </div>
    </Modal>
  );
};

export default DeletePopUp;
