import { Outlet, Route } from "react-router-dom";
import { useSelector } from "react-redux";

// slices
import { loginState } from "../login/login.slice";

// routes
import { PUBLIC_ROUTES } from "../../constants/index";

const PrivateRoute = () => {
  const { status, userProfile } = useSelector(loginState);

  if (status !== "loading" && userProfile !== undefined && userProfile?.id) {
    return <Outlet />;
  } else {
    return <Route path={PUBLIC_ROUTES.login} />;
  }
};

export default PrivateRoute;
