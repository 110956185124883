import EnrollmentPlanImage from '../../assets/images/enrollmentPlanImage.png'

export const ENROLLMENT_SECTION = {
    title: 'Are you a professional offering business support services?',
    subTitle: 'Do you extend services in the capacity of;',
    planImage: EnrollmentPlanImage,
    planList: [
        'CA, CS, Other accounting & tax expert',
        'Legal Expert',
        'Business Mentor / Coach / Consultant / Trainer',
        'Business Finance professional',
        'Govt. schemes consultant',
        'IT Professional',
        'Marketing / Branding expert',
        'HR Consultant',
        'Corporate Wellness therapist',
        'Corporate astrology / Numerology / Vastu consultant',
    ],
}
