// constants
import { CONTACT_SECTION } from '../../../constants/landingPage/contactSection'

// css
import '../contactSection/contactSection.css'

function ContactSection() {
    const handleEmailClick = () => {
        window.location.href = 'mailto:info@foundersclinic.com'
    }

    return (
        <>
            <div className="contactMain" id="contactSectionId">
                <div className="contactInner">
                    <h1 className="contactHeader">{CONTACT_SECTION.title}</h1>
                    <p className="contactPara1">
                        {CONTACT_SECTION.descriptionOne}
                    </p>
                    <p className="contactPara2">
                        {CONTACT_SECTION.descriptionTwo}
                    </p>
                    <div className="contactSource">
                        {/* <p className="contactWrapper contactNumber">
                            {CONTACT_SECTION.contactNumber}
                        </p> */}
                        <p
                            className="contactWrapper contactEmail"
                            onClick={handleEmailClick}
                        >
                            {CONTACT_SECTION.email}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactSection
