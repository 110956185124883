import { useNavigate } from 'react-router'

// antd
import { Typography } from 'antd'

// assets
import linkedin from '../../../assets/icons/linkedInIcon.svg'
import instagram from '../../../assets/icons/instagramIcon.svg'
import facebook from '../../../assets/icons/facebookIcon.svg'
import youtube from '../../../assets/icons/youtubeIcon.svg'
import LogoIconWhite from '../../../assets/icons/logoIconWhite'

// components
import ButtonComponent from '../../../components/button/buttonComponent'

// constants
import { PUBLIC_ROUTES } from '../../../constants'
import { FOOTER_SECTION } from '../../../constants/landingPage/footerSection'

// css
import './footerSection.css'
import FooterIconOne from '../../../assets/icons/footer/footerIconOne'
import FooterIconTwo from '../../../assets/icons/footer/footerIconTwo'

function FooterSection() {
    const navigate = useNavigate()

    const { Text } = Typography

    return (
        <>
            <div className="footerMain" id="footerSectionId">
                {/* <div className="footerSvgOne">
                    <FooterIconOne />
                </div>
                <div className="footerSvgTwo">
                    <FooterIconTwo />
                </div> */}
                <div className="footerInner">
                    <p className="upSkill">{FOOTER_SECTION.title}</p>
                    <p className="request">{FOOTER_SECTION.subTitle}</p>
                    <ButtonComponent
                        props={{
                            buttonText: 'Contact Us',
                            onClick: () => {
                                navigate(PUBLIC_ROUTES.inquiry)
                            },
                        }}
                    />
                    <p className="para2">{`© ${new Date().getFullYear()} Founders' Clinic`}</p>
                    <hr className="h_line" />
                    <div className="footerOuter">
                        <div
                            style={{
                                width: '160px',
                                height: '45px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate('/')}
                        >
                            <LogoIconWhite />
                        </div>
                        <div className="footerOuterLink">
                            <div>
                                <Text
                                    onClick={() => navigate('/')}
                                    className="footerLink"
                                >
                                    Home
                                </Text>
                            </div>
                            <div>
                                <Text
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        })
                                        navigate(PUBLIC_ROUTES.aboutUs)
                                    }}
                                    className="footerLink"
                                >
                                    About
                                </Text>
                            </div>
                            <div>
                                <Text
                                    style={{
                                        cursor: 'auto',
                                        display: 'none',
                                    }}
                                    className="footerLink"
                                >
                                    Privacy Policy
                                </Text>
                            </div>
                            <div>
                                <Text
                                    style={{
                                        cursor: 'auto',
                                        display: 'none',
                                    }}
                                    className="footerLink"
                                >
                                    Terms & Condition
                                </Text>
                            </div>
                        </div>
                        <div className="iconMain">
                            <div className="iconLine">
                                <div
                                    className="footerIcon"
                                    onClick={() =>
                                        window.open(
                                            'https://linkedin.com/company/founders-clinic',
                                            '_blank'
                                        )
                                    }
                                >
                                    <img
                                        src={linkedin}
                                        height="13px"
                                        alt=""
                                        weight="13px"
                                    />
                                </div>
                                {/* <div className="footerIcon">
                                    <img
                                        src={facebook}
                                        height="13px"
                                        alt=""
                                        weight="13px"
                                    />
                                </div> */}
                                <div
                                    className="footerIcon"
                                    onClick={() =>
                                        window.open(
                                            'https://www.instagram.com/founders_clinic',
                                            '_blank'
                                        )
                                    }
                                >
                                    <img
                                        src={instagram}
                                        height="13px"
                                        alt=""
                                        weight="13px"
                                    />
                                </div>
                                <div
                                    className="footerIcon"
                                    onClick={() =>
                                        window.open(
                                            'http://www.youtube.com/@FoundersClinic',
                                            '_blank'
                                        )
                                    }
                                >
                                    <img
                                        src={youtube}
                                        height="13px"
                                        weight="13px"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </>
    )
}
export default FooterSection
