import React from "react";

function MissionBackgroundIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 303 441"
    >
      <path
        fill="#E7D9F8"
        d="M0 303C0 135.658 135.658 0 303 0v441H0V303z"
      ></path>
    </svg>
  );
}

export default MissionBackgroundIcon;
