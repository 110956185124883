import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";

// antd
import { Col, Form, Input, Row, Typography, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

// slices
import { loginState } from "../login/login.slice";

// components
import ButtonComponent from "../../components/button/buttonComponent";

// constants
import { PHONE_COUNTRIES } from "../../constants/phone-countries";
import { EMAIL_PATTERN } from "../../constants/login";

// GET BASE 64
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

// BEFORE UPLOAD
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const ProfileModal = () => {
  const { Title } = Typography;

  const onFinishFailed = () => {};

  const onProfileSave = () => {};

  const [form] = Form.useForm();

  const { userProfile } = useSelector(loginState);

  const [phoneNumber, setPhoneNumber] = useState({
    default: null,
    phoneNo: null,
    code: null,
    flag: null,
  });
  const [phoneValid, setPhoneValid] = useState(false);
  const [_, setEmailValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  // HANDLE CHANGE
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  // UPLOAD BUTTON
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  // HANDLE CHANGE PHONE NUMBER
  const handleChangePhoneNumber = (number, country) => {
    const phoneNo = number.replace(country.dialCode, "");
    setPhoneNumber({
      default: number,
      phoneNo,
      code: `+${country.dialCode}`,
      flag: country.countryCode.toUpperCase(),
    });
  };

  useEffect(() => {
    form.setFieldsValue({ ...userProfile });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <>
      <Form
        layout="vertical"
        name="signUpForm"
        onFinish={onProfileSave}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
        form={form}
        style={{
          marginTop: "20px",
        }}
      >
        <Col span={24}>
          <Row align={"middle"} justify={"space-between"}>
            <Title level={4}>Upload Photo</Title>
            <Form.Item>
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please Enter a Name",
              },
            ]}
            colon={false}
          >
            <Input name="name" placeholder="Enter Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Mobile Number*"
            name="phone"
            rules={[
              {
                validator: async () => {
                  if (!phoneValid && phoneNumber?.phoneNo?.length > 0) {
                    return Promise.reject(
                      new Error("Please Enter a valid Mobile Number")
                    );
                  }
                  if (
                    phoneNumber?.phoneNo?.length === 0 ||
                    phoneNumber?.phoneNo === null
                  ) {
                    return Promise.reject(
                      new Error("Please Enter Mobile Number")
                    );
                  }
                },
              },
            ]}
            colon={false}
          >
            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
              }}
              country={"in"}
              specialLabel=""
              inputStyle={{
                height: "50px",
                width: "100%",
              }}
              value={phoneNumber?.default ?? null}
              onChange={(number, country) => {
                handleChangePhoneNumber(number, country);
                const correspondingCountry = PHONE_COUNTRIES.find(
                  (countryName) =>
                    countryName.iso2 === country.countryCode?.toUpperCase()
                );
                let numberNew = `+${number}`;
                if (
                  correspondingCountry &&
                  number &&
                  correspondingCountry?.validation.test(numberNew)
                ) {
                  setPhoneValid(true);
                } else {
                  setPhoneValid(false);
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Email Address*"
            name="email"
            rules={[
              {
                validator: async (_, value) => {
                  const pattern = EMAIL_PATTERN;

                  if (value?.length === 0 || value === undefined) {
                    return Promise.reject(
                      new Error("Please Enter Email Address")
                    );
                  }
                  if (!pattern.test(value) && value?.length > 0) {
                    setEmailValid(false);
                    return Promise.reject(
                      new Error("Please Enter a valid Email Address")
                    );
                  } else {
                    setEmailValid(true);
                  }
                },
              },
              // {},
            ]}
            colon={false}
          >
            <Input
              placeholder="Enter Email Address"
              onChange={() => {
                setEmailValid(false);
              }}
            />
          </Form.Item>
        </Col>
        <Row
          align={"middle"}
          justify={"center"}
          style={{
            marginTop: "40px",
          }}
        >
          <ButtonComponent
            props={{
              buttonText: "Save & Update",
              htmlType: "submit",
            }}
          />
        </Row>
      </Form>
    </>
  );
};

export default ProfileModal;
