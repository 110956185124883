// antd
import { Row, Typography } from "antd";

const DashboardModal = () => {
  const { Title } = Typography;

  return (
    <Row align={"middle"} justify={"center"}>
      <Title
        level={1}
        style={{
          margin: "200px 0px",
        }}
      >
        Coming Soon
      </Title>
    </Row>
  );
};

export default DashboardModal;
