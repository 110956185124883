import React from 'react'

function BusinessWellnessIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={'100%'}
            width={'100%'}
        >
            <linearGradient
                id="a"
                x1="94.638"
                x2="435.18"
                y1="461.018"
                y2="120.475"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#f0f"></stop>
                <stop offset="1" stopColor="#29abe2"></stop>
            </linearGradient>
            <path
                fill="url(#a)"
                d="M28.2 235.6c0 22.4 18.2 40.6 40.6 40.6s40.6-18.2 40.6-40.6S91.2 195 68.8 195s-40.6 18.3-40.6 40.6zm39.9 179h57.2c11.6 0 21 9.4 21 21v48.3c0 4.4 3.6 8 8 8H195c4.4 0 8-3.6 8-8v-48.3c0-42.8-34.9-77.7-77.7-77.7h-7.8v-33.4c0-26.5-21.5-48-48-48h-1.4c-26.5 0-48 21.5-48 48v42.1c0 26.5 21.5 48 48 48zm379.2-138.4c22.4 0 40.6-18.2 40.6-40.6S469.7 195 447.3 195s-40.6 18.2-40.6 40.6 18.2 40.6 40.6 40.6zm.7.3h-1.4c-26.5 0-48 21.5-48 48v33.4h-7.8c-42.8 0-77.7 34.9-77.7 77.7v48.3c0 4.4 3.6 8 8 8h40.7c4.4 0 8-3.6 8-8v-48.3c0-11.6 9.4-21 21-21h55.8c.5 0 .9 0 1.4-.1v.1c26.5 0 48-21.5 48-48v-42.1c0-26.5-21.5-48-48-48zm-78.2 56.8v-40.7c0-4.4-3.6-8-8-8H150.2c-4.4 0-8 3.6-8 8v40.7c0 4.4 3.6 8 8 8h73.4V488c0 4.4 3.6 8 8 8h48.8c4.4 0 8-3.6 8-8V341.3h73.4c4.4 0 8-3.6 8-8zm-8-149.3c-2.5-1.4-5.5-1.3-7.9.1-12.4 7.2-26.5 11-40.9 11-45 0-81.5-36.6-81.5-81.5S268 32 313 32s81.5 36.6 81.5 81.5c0 21.5-8.3 41.8-23.3 57.1-2.4 2.4-3 6.1-1.4 9.2l5.9 11.8-13.9-7.6zm-146.4-70.5c0 24.1 8.8 46.1 23.3 63.1-12.4 12.7-29.2 19.8-47.1 19.8-11.7 0-23.1-3.1-33.1-8.9-2.4-1.4-5.4-1.5-7.9-.1l-7.5 4.2 2.9-5.8c1.5-3.1.9-6.7-1.4-9.2-12.2-12.4-18.9-28.8-18.9-46.2 0-36.4 29.6-66 66-66 11.6 0 22.7 3 32.6 8.6-5.7 12.5-8.9 26.2-8.9 40.5z"
            ></path>
        </svg>
    )
}

export default BusinessWellnessIcon
