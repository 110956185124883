import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

// slices
import { loginState } from "../login/login.slice";

// constants
import { PRIVATE_ROUTE } from "../../constants/index";

// PUBLIC ROUTES
const PublicRoute = () => {
  const { userProfile, status } = useSelector(loginState);
  if (
    status !== "loading" &&
    userProfile !== undefined &&
    userProfile?.id !== undefined &&
    localStorage.getItem("Token") !== null
  ) {
    return <Navigate to={`${PRIVATE_ROUTE.dashboard}`} />;
  } else {
    return <Outlet />;
  }
};

export default PublicRoute;
