import React from "react";

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      fill="none"
      viewBox="0 0 16 18"
    >
      <path
        fill="#637381"
        d="M14.37 6.907c0 .057-.445 5.674-.699 8.038-.159 1.45-1.094 2.33-2.497 2.356-1.078.024-2.133.032-3.171.032-1.102 0-2.18-.008-3.226-.032-1.356-.033-2.292-.93-2.443-2.356-.261-2.372-.697-7.981-.705-8.038a.662.662 0 01.159-.465.592.592 0 01.436-.195h11.558c.166 0 .317.073.436.195a.623.623 0 01.151.465z"
        opacity="0.4"
      ></path>
      <path
        fill="#637381"
        d="M15.5 3.98c0-.342-.27-.61-.594-.61h-2.43c-.494 0-.924-.352-1.034-.848l-.136-.607A1.654 1.654 0 009.72.667H6.28c-.746 0-1.397.514-1.595 1.288l-.127.568a1.07 1.07 0 01-1.034.847h-2.43a.602.602 0 00-.595.61v.317c0 .335.27.611.595.611h13.81a.607.607 0 00.595-.61V3.98z"
      ></path>
    </svg>
  );
}

export default DeleteIcon;
