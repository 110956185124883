import React from 'react'

function AccountingIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="100%"
            viewBox="0 0 48 48"
        >
            <defs>
                <linearGradient
                    id="a"
                    x1="13"
                    x2="21.2"
                    y1="4.03"
                    y2="4.03"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        className="stopColorfe9661 svgShape"
                        offset="0"
                        stopColor="#364f6b"
                    ></stop>
                    <stop
                        className="stopColorffb369 svgShape"
                        offset="1"
                        stopColor="#ff6997"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="b"
                    x1="12.04"
                    x2="5.76"
                    y1="7.17"
                    y2="0.89"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="c"
                    x1="29"
                    x2="47"
                    y1="20"
                    y2="20"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="d"
                    x1="1"
                    x2="25"
                    y1="30"
                    y2="30"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        className="stopColor54a5ff svgShape"
                        offset="0"
                        stopColor="#43dde6"
                    ></stop>
                    <stop
                        className="stopColor8ad3fe svgShape"
                        offset="1"
                        stopColor="#8af8fe"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="e"
                    x1="5"
                    x2="21"
                    y1="20"
                    y2="20"
                    gradientTransform="rotate(-90 13 20)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        className="stopColord3e6f5 svgShape"
                        offset="0"
                        stopColor="#e4e4e4"
                    ></stop>
                    <stop
                        className="stopColorf0f7fc svgShape"
                        offset="1"
                        stopColor="#f6f6f6"
                    ></stop>
                </linearGradient>
                <linearGradient
                    id="f"
                    x1="29"
                    x2="47"
                    y1="44"
                    y2="44"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="g"
                    x1="29"
                    x2="47"
                    y1="38"
                    y2="38"
                    xlinkHref="#a"
                ></linearGradient>
                <linearGradient
                    id="h"
                    x1="29"
                    x2="47"
                    y1="32"
                    y2="32"
                    xlinkHref="#a"
                ></linearGradient>
            </defs>
            <path
                fill="#fc5185"
                d="M13 15a1 1 0 01-1-1V5a1 1 0 012 0v9a1 1 0 01-1 1z"
                className="colorfe9661 svgShape"
            ></path>
            <path
                fill="url(#a)"
                d="M21.2 1.85c-.39 4.41-4.32 6.51-8.2 4.37.38-4.42 4.31-6.52 8.2-4.37z"
            ></path>
            <path
                fill="url(#b)"
                d="M4.8 1.85c.39 4.41 4.32 6.51 8.2 4.37C12.62 1.8 8.68-.3 4.8 1.85z"
            ></path>
            <circle cx="38" cy="20" r="9" fill="url(#c)"></circle>
            <path
                fill="#f0f0f0"
                d="M38 19a1 1 0 111-1 1 1 0 002 0 3 3 0 00-2-2.82V15a1 1 0 00-2 0v.18A3 3 0 0038 21a1 1 0 11-1 1 1 1 0 00-2 0 3 3 0 002 2.82V25a1 1 0 002 0v-.18A3 3 0 0038 19z"
                className="colorf0f7fc svgShape"
            ></path>
            <rect
                width="24"
                height="34"
                x="1"
                y="13"
                fill="url(#d)"
                rx="3"
            ></rect>
            <path
                fill="url(#e)"
                d="M10 12h6v16h-6z"
                transform="rotate(90 13 20)"
            ></path>
            <rect
                width="18"
                height="6"
                x="29"
                y="41"
                fill="url(#f)"
                rx="2"
            ></rect>
            <rect
                width="18"
                height="6"
                x="29"
                y="35"
                fill="url(#g)"
                rx="2"
            ></rect>
            <rect
                width="18"
                height="6"
                x="29"
                y="29"
                fill="url(#h)"
                rx="2"
            ></rect>
            <path
                fill="#f0f0f0"
                d="M5 27h6v6H5zm0 10h6v6H5zm10-10h6v6h-6zm0 10h6v6h-6z"
                className="colorf0f7fc svgShape"
            ></path>
        </svg>
    )
}

export default AccountingIcon
