import React from "react";

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#637381"
        d="M.666 4.372C.666 2.33 2.358.667 4.436.667h4.135c2.074 0 3.762 1.658 3.762 3.697v9.264c0 2.043-1.692 3.705-3.772 3.705H4.43c-2.075 0-3.763-1.658-3.763-3.697V4.372z"
        opacity="0.4"
      ></path>
      <path
        fill="#637381"
        d="M17.149 8.546l-2.372-2.424a.613.613 0 00-.883.001.654.654 0 00.001.908l1.3 1.327H6.957A.634.634 0 006.332 9c0 .355.28.642.625.642h8.238l-1.3 1.327a.654.654 0 00-.001.908.616.616 0 00.883.001l2.372-2.423a.652.652 0 000-.91z"
      ></path>
    </svg>
  );
}

export default LogoutIcon;
