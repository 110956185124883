import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#637381"
        d="M14.66 13.794H9.917a.847.847 0 00-.84.853c0 .471.377.853.84.853h4.745c.463 0 .84-.382.84-.853a.847.847 0 00-.84-.853z"
        opacity="0.4"
      ></path>
      <path
        fill="#637381"
        d="M6.59 3.753l4.497 3.634a.258.258 0 01.043.357L5.799 14.69a1.75 1.75 0 01-1.358.681l-2.91.036a.332.332 0 01-.326-.26l-.662-2.876c-.115-.528 0-1.075.335-1.496l5.357-6.979a.25.25 0 01.356-.043z"
      ></path>
      <path
        fill="#637381"
        d="M13.1 5.221l-.867 1.082a.248.248 0 01-.353.04c-1.053-.852-3.752-3.04-4.5-3.647a.257.257 0 01-.037-.36l.836-1.038c.759-.977 2.082-1.066 3.149-.215l1.225.976c.503.394.838.914.953 1.46.132.601-.01 1.192-.406 1.702z"
        opacity="0.4"
      ></path>
    </svg>
  );
}

export default EditIcon;
