import BlogSection from './blogSection/blogSection'
import BusinessSection from './businessSection/businessSection'
import ChooseClinicSection from './chooseClinicSection/chooseClinicSection'
import ContactSection from './contactSection/contactSection'
import EnrollmentSection2 from './enrollmentSection2/enrollmentSection2'
import FooterSection from './footerSection/footerSection'
import HeaderSection from './headerSection/headerSection'
import ImageSlider from './imageSlider/imageSlider'
import InformationSection from './informationSection/informationSection'
import JoinclinicSection from './joinclinicSection/joinclinicSection'
import MemberShipPlanSection from './membershipPlanSection/membershipPlanSection'
import ServicesSection from './servicesSection/servicesSection'
import SubscribeSection from '../aboutusPage/subscribeSection/subscribeSection'
import NewEcoSection from './newEcoSection/newEcoSection'
import EventSection from './eventSection/eventSection'
import ScrollToTopButton from '../../components/scrollToBottom'
import FounderCircleSection from './founderCircleSection/founderCircleSection'
import JoinClinicSectionSecondary from './joinClinicSectionSecondary/joinClinicSectionSecondary'
// import JoinUSFloatButton from '../../components/joinUsButton'

function LandingPage() {
    return (
        <div>
            <HeaderSection />
            <ImageSlider />
            <BusinessSection />
            {/* <FounderCircleSection /> */}
            <ChooseClinicSection />
            <JoinclinicSection />
            <EnrollmentSection2 />
            <MemberShipPlanSection />
            <JoinClinicSectionSecondary />
            <ServicesSection />
            <InformationSection />
            <EventSection />
            <BlogSection />
            {/* <NewEcoSection /> */}
            {/* <SubscribeSection /> */}
            <ContactSection />
            <FooterSection />
            {/* <JoinUSFloatButton /> */}
            <ScrollToTopButton />
        </div>
    )
}

export default LandingPage
