import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  addProject,
  deleteProject,
  documentUpload,
  editProject,
  getSingleProject,
  listProject,
} from "./project.service";

const initialState = {
  status: "loaded",
  projects: [],
  selectedProject: undefined,
  statusGet: "loaded",
};

export const projectAdd = createAsyncThunk(
  "projectSlice/addProject",
  async (payload) => {
    const response = await addProject(payload);
    return response;
  }
);

export const projectList = createAsyncThunk(
  "projectSlice/listProject",
  async (payload = {}) => {
    const response = await listProject(payload);
    return response;
  }
);

export const projectDelete = createAsyncThunk(
  "projectSlice/deleteProject",
  async (payload = {}) => {
    const response = await deleteProject(payload);
    return response;
  }
);

export const projectEdit = createAsyncThunk(
  "projectSlice/editProject",
  async (payload = {}) => {
    const response = await editProject(payload);
    return response;
  }
);

export const projectGet = createAsyncThunk(
  "projectSlice/getSingleProject",
  async (payload = {}) => {
    const response = await getSingleProject(payload);
    return response;
  }
);

export const uploadDocument = createAsyncThunk(
  "projectSlice/documentUpload",
  async (payload = {}) => {
    const response = await documentUpload(payload);
    return response;
  }
);

export const projectSlice = createSlice({
  name: "projectSlice",
  initialState,
  reducers: {
    clearProject: (state) => {
      state.selectedProject = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(projectList.fulfilled, (state, action) => {
        state.status = "loaded";
        state.projects = action.payload?.data?.data?.projects;
      })
      .addCase(projectGet.pending, (state) => {
        state.statusGet = "loading";
      })
      .addCase(projectGet.fulfilled, (state, action) => {
        state.statusGet = "loaded";
        state.selectedProject = action.payload.data.data.projects;
      });
  },
});

export default projectSlice.reducer;

export const projectState = (state) => {
  const { projectSlice } = state;
  const { status, projects, selectedProject, statusGet } = projectSlice;
  return {
    status,
    projects,
    selectedProject,
    statusGet,
  };
};

export const { clearProject } = projectSlice.actions;
