import React from 'react'

function LogoIconWhite() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 331 84"
        >
            <path
                fill="#02D8E9"
                d="M23.312 41.6c0 9.206 6.37 15.065 14.988 15.065 6.53 0 11.765-3.366 13.855-8.92l-5.678-2.068c-1.298 3.407-4.4 5.392-8.173 5.392-5.051 0-8.925-3.548-8.925-9.469 0-5.92 3.874-9.448 8.925-9.448 3.773 0 6.875 1.965 8.173 5.372l5.678-2.067c-2.09-5.555-7.322-8.9-13.855-8.9-8.619 0-14.988 5.838-14.988 15.043zM4.192 83.898h54.203v-8.457H4.192v8.457zM0 31.831v47.838h8.384V31.831H0zm4.192 47.838H0v4.23h4.192v-4.23z"
            ></path>
            <path
                fill="#02D8E9"
                d="M42.522 63.374h-8.384v16.295h8.384V63.374zM71.945 0H17.74v8.457h54.204V0zm4.192 52.071V4.229h-8.385V52.07h8.385zM71.945 4.229h4.192V0h-4.192v4.229z"
            ></path>
            <path fill="#02D8E9" d="M42 4.229h-8.385v16.296H42V4.229z"></path>
            <path
                fill="#fff"
                stroke="#fff"
                strokeWidth="1.2"
                d="M248.863 75.994v-28.8h4.02v28.803h-4.02v-.003zM231.109 47.193h3.998v28.804h-4.343L215.84 53.143v22.854h-3.977V47.193h4.303l14.943 22.752V47.193zM194.863 75.994v-28.8h4.02v28.803h-4.02v-.003zM171.88 72.327h9.755v3.667h-13.772v-28.8h4.02l-.003 25.133zM141.276 76.77c-8.097 0-14.276-5.603-14.276-14.81 0-9.167 6.179-14.767 14.276-14.767 5.775 0 10.561 2.892 12.763 7.842l-3.715 1.487c-1.655-3.525-5.029-5.5-9.048-5.5-5.736 0-10.278 3.992-10.278 10.938 0 6.986 4.542 10.978 10.278 10.978 4.019 0 7.39-1.976 9.048-5.5l3.715 1.508c-2.202 4.932-6.988 7.823-12.763 7.823z"
            ></path>
            <path
                fill="#fff"
                stroke="#fff"
                strokeWidth="1.056"
                d="M280.142 76.262c-3.925 0-7.356-1.356-9.803-3.798-2.446-2.44-3.948-6.003-3.948-10.484 0-4.461 1.501-8.012 3.946-10.447 2.448-2.437 5.878-3.792 9.805-3.792 5.392 0 9.846 2.595 12.047 7.032l-2.744 1.098c-1.819-3.446-5.27-5.357-9.303-5.357-2.992 0-5.702 1.043-7.664 3.02-1.965 1.98-3.142 4.854-3.142 8.446 0 3.61 1.177 6.496 3.141 8.48 1.963 1.983 4.673 3.026 7.665 3.026 4.032 0 7.48-1.91 9.301-5.355l2.746 1.114c-2.2 4.421-6.655 7.017-12.047 7.017z"
            ></path>
            <path
                fill="#fff"
                stroke="#fff"
                strokeWidth="1.2"
                d="M280.172 76.906c-8.098 0-14.276-5.603-14.276-14.81-.558-9.127 6.178-14.903 14.276-14.903 5.775 0 10.837 2.567 12.762 7.979l-3.715 1.486c-1.655-3.524-5.028-5.5-9.047-5.5-5.736 0-10.278 3.992-10.278 10.938 0 6.986 4.542 10.979 10.278 10.979 4.019 0 7.39-1.977 9.047-5.5l3.715 1.507c-2.202 4.93-6.987 7.824-12.762 7.824zM310.524 37.193c-6.079 0-10.533-2.93-11.524-7.923l4.185-1c.659 3.43 3.607 5.408 7.481 5.408 3.111 0 6.1-1.287 6.041-4.823-.042-3.494-3.791-4.45-7.77-5.552-4.513-1.249-9.03-2.68-9.03-8.088 0-5.467 4.471-8.21 9.769-8.21 5.134 0 9.747 2.184 10.843 7.236l-3.98 1.02c-.722-3.264-3.421-4.72-6.679-4.72-2.926 0-5.835 1.287-5.835 4.636 0 3.036 3.258 3.93 6.948 4.93 4.639 1.27 9.957 2.722 9.957 8.63 0 5.94-4.945 8.456-10.406 8.456zM276.962 10.976v9.44h5.155c3.791 0 5.503-1.915 5.503-4.701 0-2.744-1.712-4.739-5.503-4.739h-5.155zM288.586 36.8l-7.544-13.225h-4.08V36.8h-4.102V7.398h9.811c6.1 0 8.883 3.765 8.883 8.38 0 4.095-2.393 6.945-6.389 7.631l8.224 13.39h-4.803zM263.637 11.12H251.97v9.147h10.286v3.701H251.97v9.088h11.667v3.743h-15.848V7.398h15.852v3.722h-.004zM219.54 33.056h5.34c6.368 0 9.399-4.265 9.399-10.98 0-6.675-3.031-10.96-9.399-10.96h-5.34v21.94zm-4.101 3.743V7.398h9.605c8.967 0 13.521 6.072 13.521 14.677 0 8.669-4.554 14.72-13.521 14.72h-9.605v.004zM203.636 7.398h4.08v29.401h-4.433l-15.234-23.328v23.328h-4.06V7.398h4.391l15.252 23.223.004-23.223zm-38.684 29.795c-6.041 0-10.717-3.595-10.717-10.231V7.398h4.101v18.984c0 4.844 2.884 7.067 6.616 7.067 3.732 0 6.679-2.244 6.679-7.067V7.398h4.102v19.564c0 6.636-4.702 10.23-10.781 10.23zm-33.79-3.887c5.835 0 10.449-4.074 10.449-11.226 0-7.09-4.618-11.167-10.449-11.167-5.852 0-10.491 4.073-10.491 11.166-.004 7.153 4.635 11.227 10.491 11.227zm0 3.887c-8.266 0-14.571-5.738-14.571-15.118 0-9.358 6.305-15.075 14.571-15.075 8.224 0 14.55 5.717 14.55 15.075 0 9.38-6.326 15.118-14.55 15.118zM108.016 11.12h-11.83v9.13h10.285v3.722H96.186V36.8H92V7.398h16.016v3.722z"
            ></path>
            <path
                fill="#02D8E9"
                stroke="#02D8E9"
                strokeWidth="1.2"
                d="M131.162 33.306c5.835 0 10.449-4.074 10.449-11.226 0-7.09-4.618-11.167-10.449-11.167-5.852 0-10.491 4.073-10.491 11.166-.004 7.153 4.635 11.227 10.491 11.227zm0 3.887c-8.266 0-14.571-5.738-14.571-15.118 0-9.358 6.305-15.075 14.571-15.075 8.224 0 14.55 5.717 14.55 15.075 0 9.38-6.326 15.118-14.55 15.118z"
            ></path>
            <path
                fill="#fff"
                stroke="#fff"
                strokeWidth="1.2"
                d="M108.015 11.1h-11.83v9.125h10.286v3.722H96.185v12.831H92v-29.4h16.015v3.721z"
            ></path>
            <path
                fill="#fff"
                d="M330.83 7v2.318c0 .667-.125 1.36-.375 2.08a8.91 8.91 0 01-1.046 2.056 7.693 7.693 0 01-1.5 1.648L326 13.864c.447-.675.822-1.38 1.125-2.114.295-.727.439-1.53.432-2.41V7h3.273z"
            ></path>
        </svg>
    )
}

export default LogoIconWhite
