import React from 'react'

function ConsultancyIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={'100%'}
            width={'100%'}
        >
            <path
                fill="#2e368f"
                d="M423.6 465.5H313.8c-22.2 0-40.1-17.9-40.1-40.1 0-49.2 39.9-89.1 89.1-89.1h11.8c49.2 0 89.1 39.9 89.1 89.1.1 22.1-17.9 40.1-40.1 40.1z"
            ></path>
            <circle cx="368.7" cy="301.3" r="47.2" fill="#f3bf8f"></circle>
            <g>
                <path
                    fill="#6fccdd"
                    d="M198.1 465.5H88.3c-22.2 0-40.1-17.9-40.1-40.1 0-49.2 39.9-89.1 89.1-89.1h11.8c49.2 0 89.1 39.9 89.1 89.1 0 22.1-17.9 40.1-40.1 40.1z"
                ></path>
                <circle cx="143.2" cy="301.3" r="47.2" fill="#f3bf8f"></circle>
            </g>
            <g>
                <path
                    fill="#f15a26"
                    d="M310.8 482.6H201.1c-22.2 0-40.1-17.9-40.1-40.1 0-49.2 39.9-89.1 89.1-89.1h11.8c49.2 0 89.1 39.9 89.1 89.1.1 22.1-18 40.1-40.2 40.1z"
                ></path>
                <circle cx="256" cy="318.5" r="47.2" fill="#f3bf8f"></circle>
            </g>
            <path
                fill="#fbb03b"
                d="M256.4 29.3L295.9 94.9 370.4 112.1 320.2 169.9 326.9 246.1 256.4 216.2 186 246.1 192.7 169.9 142.5 112.1 217 94.9z"
            ></path>
        </svg>
    )
}

export default ConsultancyIcon
