import { Route } from "react-router-dom";

// constants
import { PUBLIC_ROUTES } from "../../constants/index";

// routes
import LoginLayout from "../loginLayout";
import GetInquiryPage from "../getInquiryPage";
import LoginForm from "../login/loginForm";
import SignupForm from "../login/signupForm";
import VerifyOtp from "../login/verifyOtp";

const PublicRoutesList = () => {
  return (
    <Route element={<LoginLayout />}>
      <Route path={PUBLIC_ROUTES.inquiry} element={<GetInquiryPage />} />
      <Route path={PUBLIC_ROUTES.login} element={<LoginForm />} />
      <Route path={PUBLIC_ROUTES.signUp} element={<SignupForm />} />
      <Route path={PUBLIC_ROUTES.verifyOtp} element={<VerifyOtp />} />
    </Route>
  );
};

export default PublicRoutesList;
