import axios from 'axios'

// constants
import { API_URL } from '../constants/global'

const axiosNew = axios.create({
    // baseURL: process.env.REACT_APP_URL,
    baseURL: API_URL,
    method: 'post',
})

axiosNew.interceptors.request.use((config) => {
    const user = localStorage.getItem('Token')

    if (user?.length > 0) {
        config.headers.Authorization = `Bearer ${user}`
    }
    return config
})

export default axiosNew
