import React from 'react'

function EventIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="50"
            fill="none"
            viewBox="0 0 44 50"
        >
            <path
                fill={props?.color}
                d="M3.8 47.88v1.734h10.4v-5.2c0-.25.015-.497.037-.74-.026-.029-.05-.059-.078-.087A6.067 6.067 0 003.8 47.881zm36.4 1.734v-1.733a6.06 6.06 0 00-10.437-4.197c.022.24.037.484.037.73v5.2h10.4zm-24.267-5.2v5.2h12.134v-5.2a6.066 6.066 0 10-12.133 0zM43.668 4.547a1.733 1.733 0 00-1.733-1.733h-3.467V1.081a.867.867 0 10-1.733 0v1.733h-6.067V1.081a.867.867 0 10-1.734 0v1.733h-6.066V1.081a.867.867 0 10-1.733 0v1.733h-6.067V1.081a.867.867 0 10-1.733 0v1.733H7.267V1.081a.867.867 0 00-1.734 0v1.733H2.067A1.733 1.733 0 00.333 4.547v1.734h43.334V4.547zM18.06 19.44c.14.167.211.38.201.597l-.16 3.495c.056.08.096.095.147.078l3.467-1.24a.86.86 0 01.584 0l3.467 1.24a.126.126 0 00.114-.016l-.132-3.553a.867.867 0 01.202-.597l2.059-2.46a.26.26 0 00.053-.26.297.297 0 00-.221-.202l-3.207-.844a.866.866 0 01-.491-.347l-2.034-2.935a.117.117 0 00-.1-.059.115.115 0 00-.1.059l-2.033 2.936a.872.872 0 01-.492.347l-3.207.844a.298.298 0 00-.221.2.26.26 0 00.053.26l2.051 2.457zM9.867 40.08a3.467 3.467 0 100-6.933 3.467 3.467 0 000 6.934z"
            ></path>
            <path
                fill={props?.color}
                d="M2.067 33.148H6a5.175 5.175 0 017.731 0H16.8a5.2 5.2 0 1110.4 0h3.068a5.175 5.175 0 017.73 0h3.936a1.733 1.733 0 001.733-1.734v-23.4H.333v23.4a1.733 1.733 0 001.734 1.733zm12.229-16.954a2.031 2.031 0 011.431-1.352l2.893-.761 1.851-2.673a1.861 1.861 0 013.052 0l1.857 2.673 2.897.762a2.033 2.033 0 011.489 2.35 1.993 1.993 0 01-.434.9l-1.841 2.201.143 3.155a1.804 1.804 0 01-.735 1.538 1.853 1.853 0 01-1.729.254L22 24.106l-3.173 1.135a1.851 1.851 0 01-1.729-.254 1.807 1.807 0 01-.734-1.537l.15-3.157-1.843-2.201a1.994 1.994 0 01-.375-1.898z"
            ></path>
            <path
                fill={props?.color}
                d="M22 36.614a3.467 3.467 0 100-6.933 3.467 3.467 0 000 6.933zM34.133 40.08a3.467 3.467 0 100-6.933 3.467 3.467 0 000 6.934z"
            ></path>
        </svg>
    )
}

export default EventIcon
