import React from "react";

function FixedPriceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#67DAFF"
        d="M14.82.82a.82.82 0 00-1.64 0v1.368h1.64V.82zm-5.469 0a.82.82 0 00-1.64 0v1.368h1.64V.82zm10.938 0a.82.82 0 00-1.64 0v1.368h1.64V.82zm4.43 1.368h-4.43v1.366a.82.82 0 01-1.64 0V2.188H14.82v1.366a.82.82 0 01-1.64 0V2.188H9.35v1.366a.82.82 0 01-1.64 0V2.188H3.28a2.464 2.464 0 00-2.46 2.46v2.735h26.358V4.65a2.464 2.464 0 00-2.46-2.461zm-10.72 10.937c-4.1 0-7.436 3.337-7.436 7.438C6.563 24.663 9.899 28 14 28c4.1 0 7.437-3.336 7.437-7.437 0-4.101-3.336-7.438-7.437-7.438zm.821 11.179v.415a.82.82 0 11-1.64 0v-.5c-.822-.259-1.578-.762-1.797-1.075a.82.82 0 011.295-1.004c.195.168.943.601 1.464.601.542 0 .922-.316.977-.622.072-.404-.548-.666-.74-.736a32.759 32.759 0 01-1.794-.728.807.807 0 01-.114-.062c-1.402-.914-1.15-3.093.62-3.689v-.498a.82.82 0 011.64 0v.402c.523.108 1.03.348 1.5.714a.82.82 0 11-1.01 1.294c-.355-.278-.917-.567-1.58-.367-.467.14-.526.56-.313.74.22.097.895.387 1.619.654 2.727 1.006 2.138 3.927-.127 4.46z"
      ></path>
      <path
        fill="#006D90"
        d="M.82 23.898a2.464 2.464 0 002.461 2.461H7.02a9.036 9.036 0 01-2.097-5.796c0-5.006 4.072-9.078 9.078-9.078 5.006 0 9.078 4.072 9.078 9.078a9.037 9.037 0 01-2.097 5.796h3.737a2.464 2.464 0 002.461-2.46V9.023H.82v14.874z"
      ></path>
    </svg>
  );
}

export default FixedPriceIcon;
