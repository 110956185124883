import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OTPInput from 'react-otp-input'

// antd
import { Col, Form, Row, Typography, message, theme } from 'antd'

// slices
import { isLoggedInLogin, setUserProfile, verifyOtpLogin } from './login.slice'

// components
import ButtonComponent from '../../components/button/buttonComponent'

// assets
import LogoIcon from '../../assets/icons/landingPage/logo'

// css
import './login.css'

function VerifyOtp() {
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { useToken } = theme

    const { token } = useToken()

    const { Title, Text } = Typography

    const initialState = {
        loadingButton: false,
        isOTP: '',
    }

    // STATE MANAGEMENT
    const [state, setState] = useState(initialState)

    // ON FINISH FAILED
    function onFinishFailed() {}

    // ON VERIFY OTP
    async function onVerifyOtp() {
        setState((prevState) => ({ ...prevState, loadingButton: true }))

        const formData = new FormData()
        if (JSON.parse(localStorage?.getItem('phoneData'))?.step === 'login') {
            formData.append(
                'user',
                JSON.stringify(
                    JSON.parse(localStorage?.getItem('phoneData'))?.user
                )
            )
        }
        formData.append(
            'phone',
            JSON.parse(localStorage?.getItem('phoneData'))?.phone
        )
        formData.append('otp', state?.isOTP)

        const result = await dispatch(
            verifyOtpLogin({
                body: formData,
                token: JSON.parse(localStorage?.getItem('phoneData'))?.token,
            })
        )
        const data = result?.payload?.data

        if (data) {
            const { success, message: checkMessage } = data?.data
            if (success) {
                await localStorage.setItem('Token', data?.data?.token)
                setState((prevState) => ({
                    ...prevState,
                    loadingButton: false,
                }))
                message.success(checkMessage)
                // window.localStorage.clear();
                const checkLogin = await dispatch(
                    isLoggedInLogin({ token: data?.data?.token })
                )
                const checkSuccessLogin = checkLogin?.payload?.data

                if (checkSuccessLogin) {
                    const {
                        success: checkSuccess,
                        message: checkMessage,
                        data: userProfile,
                    } = checkSuccessLogin?.data

                    if (checkSuccess) {
                        dispatch(
                            setUserProfile({
                                ...userProfile,
                                token: data?.data?.token,
                            })
                        )
                    } else {
                        message.error(checkMessage)
                    }
                }
            } else {
                message.error(checkMessage)
                setState((prevState) => ({
                    ...prevState,
                    loadingButton: false,
                    isOTP: '',
                }))
                form.setFieldValue('otpInput', '')
            }
        }
    }

    // SEND OTP
    const handleSendOtp = async () => {
        // const result = await dispatch(
        //   sendOtpLogin({
        //     countryCode: JSON.parse(localStorage?.getItem("phoneData"))
        //       ?.countryCode,
        //     phone: JSON.parse(localStorage?.getItem("phoneData"))?.phoneNumber,
        //   })
        // );
        // const data = result?.payload?.data;
        // if (data) {
        //   const { success, message: checkMessage } = data;
        //   if (success) {
        //     setIsOTP("");
        //     form.setFieldValue("otpInput", "");
        //     setLoadingButton(false);
        //     message.success(checkMessage);
        //   } else {
        //     setIsOTP("");
        //     form.setFieldValue("otpInput", "");
        //     setLoadingButton(false);
        //     if (checkMessage) {
        //       message.error(checkMessage);
        //     } else {
        //       message.error("Something went wrong, try again later.");
        //     }
        //   }
        // }
    }

    // HANDLE CHANGE OTP
    const handleChangeOTP = (otp) => {
        setState((prevState) => ({ ...prevState, isOTP: otp }))
    }

    return (
        <>
            <div
                style={{
                    padding: '60px 60px 0px 60px',
                }}
            >
                <div
                    style={{
                        width: '160px',
                        height: '45px',
                        cursor: 'pointer',
                    }}
                    onClick={() => navigate('/')}
                >
                    <LogoIcon />
                </div>
                <Title
                    style={{
                        fontWeight: 700,
                        margin: '50px 0px 0px 0px',
                    }}
                    level={3}
                >
                    OTP Verification
                </Title>
                <div
                    style={{
                        marginTop: '10px',
                    }}
                >
                    <Text
                        style={{
                            color: token.colorPalette.textColor.senary,
                            fontSize: token.fontSizeHeading5,
                        }}
                    >
                        Welcome to the digital ecosystem tailored for your
                        enterprise.
                    </Text>
                </div>
                <div
                    style={{
                        marginTop: '10px',
                    }}
                >
                    <Text
                        style={{
                            color: token.colorPalette.textColor.senary,
                            fontSize: token.fontSizeHeading5,
                        }}
                    >
                        Enter the verification code we just sent on your mobile
                        number.
                    </Text>
                </div>
                <Form
                    layout="vertical"
                    name="verifyOtp"
                    onFinish={onVerifyOtp}
                    onFinishFailed={onFinishFailed}
                    requiredMark={false}
                    form={form}
                    style={{
                        marginTop: '20px',
                    }}
                >
                    <Col
                        span={24}
                        style={{
                            padding: '0px',
                            marginBottom: '10px',
                        }}
                    >
                        <Form.Item
                            name="otpInput"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter OTP',
                                },
                            ]}
                            colon={false}
                        >
                            <OTPInput
                                value={state?.isOTP}
                                onChange={handleChangeOTP}
                                renderInput={(props) => <input {...props} />}
                                numInputs={6}
                                shouldAutoFocus
                                inputStyle="input-otp-style"
                                containerStyle={'otp-style'}
                                hasErrored={true}
                                isInputNum={true}
                            />
                        </Form.Item>
                    </Col>
                    <Row
                        gutter={20}
                        style={{
                            marginTop: '40px',
                        }}
                    >
                        <Col span={24}>
                            <ButtonComponent
                                props={{
                                    buttonText: 'Verify',
                                    htmlType: 'submit',
                                    style: {
                                        width: '100%',
                                    },
                                    disabled: state?.loadingButton,
                                    loadingButton: state?.loadingButton,
                                }}
                            />
                        </Col>
                    </Row>
                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Text
                            className="sub-title"
                            style={{
                                fontWeight: 400,
                            }}
                        >
                            Didn’t received code?{' '}
                            <span
                                style={{
                                    color: token.colorPalette.baseColor.primary,
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                }}
                                className="sub-title"
                                onClick={() => handleSendOtp()}
                            >
                                Resend
                            </span>
                        </Text>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default VerifyOtp
