// antd
import { Card, Col, Row, Typography, theme } from "antd";

// constants
import { CHOOSE_CLINIC_SECTION } from "../../../constants/landingPage/chooseClinic";

// assets
import ChooseCardIcon from "../../../assets/icons/landingPage/chooseCard";

// styles
import "./chooseClinicSection.css";

const ChooseClinicSection = () => {
  const { Text, Title } = Typography;

  const { useToken } = theme;
  const { token } = useToken();

  return (
    <div className="chooseContainerMain">
      <div className="chooseContainer">
        <div className="chooseContainerInner">
          <Text
            style={{
              color: token.colorPalette.baseColor.primary,
            }}
            className="chooseContainerText"
          >
            {CHOOSE_CLINIC_SECTION.title}
          </Text>
          <Title
            level={2}
            style={{
              margin: 0,
              fontSize: "40px",
              fontWeight: 700,
            }}
            className="chooseContainerMainTitle"
          >
            {CHOOSE_CLINIC_SECTION.subTitle}
          </Title>
          <div>
            <Row gutter={13} className="chooseContainerRow">
              {CHOOSE_CLINIC_SECTION?.container?.length > 0 &&
                CHOOSE_CLINIC_SECTION?.container.map((clinicData) => (
                  <Col xs={12} sm={8} md={8} lg={8} xl={6} xxl={6}>
                    <Card
                      className="chooseContainerCard"
                      bodyStyle={{
                        padding: 0,
                      }}
                    >
                      <Title
                        style={{
                          margin: 0,
                        }}
                        level={5}
                      >
                        {clinicData?.title}
                      </Title>
                      <Text className="chooseCardDescription">
                        {clinicData?.description}
                      </Text>
                      <div className="chooseCardIcon">
                        <ChooseCardIcon />
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseClinicSection;
