import React from "react";

function JobIcon({ selected }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={selected ? "#67daff" : "#637381"}
        d="M20.96 6.05c-.05.989-.99 1.36-1.718.691a55.933 55.933 0 01-1.2-1.138c-1.066.97-2.72 2.483-4.691 4.313a1.262 1.262 0 01-1.811-.115 115.179 115.179 0 00-2.708-3.15 44.334 44.334 0 00-4.948 4.233 1.25 1.25 0 01-1.768-1.767c1.89-1.89 3.963-3.663 6.187-5.154l.003-.002a1.25 1.25 0 011.578.156c.968.968 1.842 2.032 2.728 3.076a637.77 637.77 0 013.662-3.362A54.139 54.139 0 0115.26 2.76c-.669-.729-.298-1.669.69-1.719 1.262-.063 2.556-.1 3.801.132.56.105.972.517 1.077 1.077.233 1.246.196 2.54.132 3.802z"
      ></path>
      <g fill={selected ? "#67daff" : "#637381"} opacity="0.32">
        <path d="M2.61 22.984c-.837-.02-1.47-.524-1.54-1.358-.04-.5-.07-1.186-.07-2.126s.03-1.626.07-2.126c.07-.834.703-1.337 1.54-1.358.365-.01.823-.016 1.39-.016s1.025.006 1.39.016c.837.02 1.47.524 1.54 1.358.04.5.07 1.186.07 2.126s-.03 1.626-.07 2.126c-.07.834-.703 1.337-1.54 1.358-.365.01-.823.016-1.39.016s-1.025-.006-1.39-.016zM18.846 22.98c-.975-.04-1.659-.724-1.722-1.698C17.06 20.273 17 18.634 17 16s.059-4.273.124-5.282c.064-.974.747-1.659 1.722-1.697C19.167 9.008 19.549 9 20 9c.451 0 .833.008 1.154.02.975.04 1.659.724 1.722 1.698C22.94 11.727 23 13.366 23 16s-.059 4.273-.124 5.282c-.064.974-.747 1.659-1.722 1.697-.321.013-.703.021-1.154.021-.451 0-.833-.008-1.154-.02zM10.777 22.983c-.934-.028-1.615-.641-1.682-1.573C9.042 20.677 9 19.594 9 18s.042-2.678.095-3.41c.067-.932.748-1.545 1.682-1.573.335-.01.74-.017 1.223-.017.484 0 .888.007 1.223.017.934.028 1.615.641 1.682 1.573.053.732.095 1.816.095 3.41s-.042 2.677-.095 3.41c-.067.932-.748 1.545-1.682 1.573-.335.01-.74.017-1.223.017-.484 0-.888-.007-1.223-.017z"></path>
      </g>
    </svg>
  );
}

export default JobIcon;
