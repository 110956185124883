import React from "react";

function BusinessRing() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <circle
        cx="52"
        cy="52"
        r="51.5"
        stroke="url(#paint0_linear_379_18)"
        opacity="0.4"
      ></circle>
      <defs>
        <linearGradient
          id="paint0_linear_379_18"
          x1="52"
          x2="52"
          y1="0"
          y2="104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#67DAFF"></stop>
          <stop offset="1" stopColor="#67DAFF" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BusinessRing;
