import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// antd
import {
    Checkbox,
    Col,
    Form,
    Input,
    Radio,
    Row,
    Typography,
    message,
    theme,
} from 'antd'

// slices
import { signUpForm } from './login.slice'

// assets
// import { AppleIcon, FacebookIcon, GoogleIcon } from "../../assets/icons/login";
import LogoIcon from '../../assets/icons/landingPage/logo'

// components
import ButtonComponent from '../../components/button/buttonComponent'

// constants
import { PHONE_COUNTRIES } from '../../constants/phone-countries'
import { PUBLIC_ROUTES } from '../../constants'
import { EMAIL_PATTERN } from '../../constants/login'

const SignupForm = () => {
    const [form] = Form.useForm()

    const { useToken } = theme

    const { token } = useToken()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { Title, Text } = Typography

    const [loadingButton, setLoadingButton] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState({
        default: null,
        phoneNo: null,
        code: null,
        flag: null,
    })
    const [phoneValid, setPhoneValid] = useState(false)
    const [_, setEmailValid] = useState(false)

    // ON FINISH FAILED
    function onFinishFailed() {}

    useEffect(() => {
        window.localStorage.clear()
    }, [])

    // ON SIGN UP
    async function onSignUp(value) {
        setLoadingButton(true)

        const formData = new FormData()

        formData.append('name', value?.name)
        formData.append('email', value?.email)
        formData.append('type', value?.type)
        formData.append('phone', `${phoneNumber?.code}${phoneNumber?.phoneNo}`)

        const result = await dispatch(signUpForm(formData))
        const data = result?.payload?.data
        if (data?.data) {
            const { success, message: checkMessage } = data?.data
            if (success) {
                localStorage.setItem(
                    'phoneData',
                    JSON.stringify({
                        user: {
                            name: value?.name,
                            email: value?.email,
                            type: value?.type,
                            phone: `${phoneNumber?.code}${phoneNumber?.phoneNo}`,
                        },
                        step: 'login',
                        token: data?.data?.token,
                    })
                )
                setLoadingButton(false)
                message.success(checkMessage)
                navigate(PUBLIC_ROUTES.verifyOtp)
            } else {
                setLoadingButton(false)
                if (checkMessage) {
                    message.error(checkMessage)
                } else {
                    message.error('Something went wrong, try again later.')
                }
            }
        }
    }

    // const socialButtons = [
    //   {
    //     name: "facebook",
    //     link: `${process.env.REACT_APP_URL}/auth/facebook`,
    //     icon: <FacebookIcon />,
    //   },
    //   {
    //     name: "google",
    //     link: `${process.env.REACT_APP_URL}/auth/google`,
    //     icon: <GoogleIcon />,
    //   },
    //   {
    //     name: "apple",
    //     link: `${process.env.REACT_APP_URL}/auth/apple`,
    //     icon: <AppleIcon />,
    //   },
    // ];

    // HANDLE CHANGE PHONE NUMBER
    const handleChangePhoneNumber = (number, country) => {
        const phoneNo = number.replace(country.dialCode, '')
        setPhoneNumber({
            default: number,
            phoneNo,
            code: `+${country.dialCode}`,
            flag: country.countryCode.toUpperCase(),
        })
    }

    return (
        <div
            style={{
                padding: '58px 65px 0px 65px',
                marginBottom: '58px',
            }}
        >
            <div
                style={{
                    width: '160px',
                    height: '45px',
                    cursor: 'pointer',
                }}
                onClick={() => navigate('/')}
            >
                <LogoIcon />
            </div>
            <Title
                style={{
                    margin: '50px 0px 0px 0px',
                }}
                level={3}
            >
                Sign-up
            </Title>
            <Text
                style={{
                    color: token.colorPalette.textColor.senary,
                    fontSize: token.fontSizeHeading3,
                }}
            >
                Let’s get started
            </Text>
            <Form
                layout="vertical"
                name="signUpForm"
                onFinish={onSignUp}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                form={form}
                style={{
                    marginTop: '20px',
                }}
            >
                <Col span={24}>
                    <Form.Item
                        name="type"
                        label="Sign up as"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select your role',
                            },
                        ]}
                    >
                        <Radio.Group
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '10px',
                            }}
                        >
                            <Radio value="Entrepreneur">
                                Professional (Service provider)
                            </Radio>
                            <Radio value="JobSeeker">
                                Businesses (Service Seeker)
                            </Radio>
                            <Radio value="Both">Both</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter a Name',
                            },
                        ]}
                        colon={false}
                    >
                        <Input name="name" placeholder="Enter Name" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Mobile Number*"
                        name="phone"
                        rules={[
                            {
                                validator: async () => {
                                    if (
                                        !phoneValid &&
                                        phoneNumber?.phoneNo?.length > 0
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter a valid Mobile Number'
                                            )
                                        )
                                    }
                                    if (
                                        phoneNumber?.phoneNo?.length === 0 ||
                                        phoneNumber?.phoneNo === null
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter Mobile Number'
                                            )
                                        )
                                    }
                                },
                            },
                        ]}
                        colon={false}
                    >
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                required: true,
                            }}
                            country={'in'}
                            specialLabel=""
                            inputStyle={{
                                height: '50px',
                                width: '100%',
                            }}
                            value={phoneNumber?.default ?? null}
                            onChange={(number, country) => {
                                handleChangePhoneNumber(number, country)
                                const correspondingCountry =
                                    PHONE_COUNTRIES.find(
                                        (countryName) =>
                                            countryName.iso2 ===
                                            country.countryCode?.toUpperCase()
                                    )
                                let numberNew = `+${number}`
                                if (
                                    correspondingCountry &&
                                    number &&
                                    correspondingCountry?.validation.test(
                                        numberNew
                                    )
                                ) {
                                    setPhoneValid(true)
                                } else {
                                    setPhoneValid(false)
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Email Address*"
                        name="email"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    const pattern = EMAIL_PATTERN

                                    if (
                                        value?.length === 0 ||
                                        value === undefined
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter Email Address'
                                            )
                                        )
                                    }
                                    if (
                                        !pattern.test(value) &&
                                        value?.length > 0
                                    ) {
                                        setEmailValid(false)
                                        return Promise.reject(
                                            new Error(
                                                'Please Enter a valid Email Address'
                                            )
                                        )
                                    } else {
                                        setEmailValid(true)
                                    }
                                },
                            },
                            // {},
                        ]}
                        colon={false}
                    >
                        <Input
                            placeholder="Enter Email Address"
                            onChange={() => {
                                setEmailValid(false)
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error(
                                                  'Should accept agreement'
                                              )
                                          ),
                            },
                        ]}
                    >
                        <Checkbox>Agree to Terms & Condition</Checkbox>
                    </Form.Item>
                </Col>
                <Row
                    style={{
                        marginTop: '50px',
                    }}
                >
                    <Col span={24}>
                        <ButtonComponent
                            props={{
                                buttonText: 'Sign Up',
                                htmlType: 'submit',
                                disabled: loadingButton,
                                loadingButton: loadingButton,
                                style: {
                                    width: '100%',
                                    height: '48px',
                                },
                            }}
                        />
                    </Col>
                </Row>
            </Form>
            {/* <div
        style={{
          marginTop: "35px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Divider dashed>
          <Text
            style={{
              color: token.colorPalette.textColor.senary,
            }}
          >
            Or Login with
          </Text>
        </Divider>
      </div> */}
            {/* <Row justify={"center"}>
        <Space size={"large"}>
          {socialButtons?.length > 0 &&
            socialButtons.map((data) => (
              <div
                key={data?.name}
                style={{
                  // backgroundColor: '#f7f7f7',
                  padding: "15px 40px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  border: `1px solid ${token.colorPalette.iconColor.secondary}`,
                }}
                onClick={() => window.open(data?.link, "_self")}
              >
                <div>{data?.icon}</div>
              </div>
            ))}
        </Space>
      </Row> */}
            <div
                style={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Text className="titleLight sub-title">
                    Already registered?{' '}
                    <span
                        style={{
                            color: token.colorPalette.baseColor.primary,
                            cursor: 'pointer',
                        }}
                        className="sub-title"
                        onClick={() => {
                            navigate(PUBLIC_ROUTES.login)
                        }}
                    >
                        Sign in
                    </span>
                </Text>
            </div>
        </div>
    )
}

export default SignupForm
