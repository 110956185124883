// ScrollToTopButton.js

import { useState, useEffect } from 'react'

// css
import './index.css'
import { useLocation } from 'react-router-dom'

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false)

    // Show button when page is scrolled down
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }
    const { pathname } = useLocation()

    // Set event listener when component mounts
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility)

        // Clean up event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', toggleVisibility)
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional smooth scrolling
        })
    }

    return (
        isVisible && (
            <div className="scroll-to-top" onClick={scrollToTop}>
                <span>↑</span>
            </div>
        )
    )
}

export default ScrollToTopButton
