
import React from "react";

function AIIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_305_174)">
        <path
          fill="#004777"
          d="M30.701 7.52l-2.226 2.214a.554.554 0 01-.254.105h-4.94v1.172h4.94c.373 0 .817-.183 1.081-.446l2.324-2.312a1.928 1.928 0 01-.925-.734zm-1.614 27.267l-1.515-1.508a1.657 1.657 0 00-1.08-.446h-3.21v1.172h3.209a.553.553 0 01.254.105l1.454 1.446a1.93 1.93 0 01.888-.77zm-.619-5.672c0-.204.033-.401.093-.586h-5.28v1.172h5.28a1.895 1.895 0 01-.093-.586zm1.002-5.097c0-.204.033-.401.093-.586H23.28v1.172h6.281a1.902 1.902 0 01-.092-.586zM25 18.581c0-.204.033-.4.093-.585H23.28v1.171h1.812a1.895 1.895 0 01-.093-.586zm10.52-.046l-4.67-4.647a1.657 1.657 0 00-1.08-.446H23.28v1.172h6.488a.553.553 0 01.254.105l4.667 4.644c.184-.355.474-.645.83-.828zM24.735 5.375a1.9 1.9 0 01.092-.586h-1.546V5.96h1.546a1.899 1.899 0 01-.092-.586z"
        ></path>
        <path
          fill="#FFC502"
          d="M26.654 7.365a1.996 1.996 0 01-1.997-1.99c0-1.098.896-1.99 1.997-1.99s1.997.892 1.997 1.99c0 1.097-.896 1.99-1.997 1.99z"
        ></path>
        <path
          fill="#EF5050"
          d="M14.763 3.338c-.24-.04-.483-.06-.727-.06-2.425 0-4.391 1.956-4.391 4.37 0 .212.016.42.045.625a4.41 4.41 0 00-1.337-.207c-2.425 0-4.392 1.957-4.392 4.37 0 .692.165 1.375.483 1.99a6.16 6.16 0 00-3.468 5.535 6.16 6.16 0 003.492 5.547 4.332 4.332 0 00-.507 2.036c0 2.414 1.967 4.37 4.392 4.37.464 0 .912-.072 1.332-.205a4.41 4.41 0 00-.04.584c0 2.414 1.966 4.37 4.391 4.37.25 0 .495-.021.733-.061a4.134 4.134 0 003.909 2.773c2.283 0 4.134-1.842 4.134-4.114V4.66c0-2.272-1.85-4.114-4.134-4.114a4.134 4.134 0 00-3.915 2.791z"
        ></path>
        <path
          fill="#00003F"
          d="M2.147 21.367a5.737 5.737 0 013.25-5.184.39.39 0 00.175-.53 3.917 3.917 0 01-.439-1.81c0-2.195 1.795-3.98 4.001-3.98.415 0 .825.063 1.22.189a.39.39 0 00.504-.428 4.01 4.01 0 01-.041-.57c0-2.195 1.794-3.98 4-3.98.219 0 .442.019.663.056a.39.39 0 00.434-.26 3.74 3.74 0 013.545-2.526c1.445 0 2.7.819 3.325 2.014a4.126 4.126 0 00-4.12-3.772 4.133 4.133 0 00-3.915 2.791 4.43 4.43 0 00-.726-.06c-2.426 0-4.392 1.956-4.392 4.37 0 .212.016.42.045.625a4.41 4.41 0 00-1.337-.207c-2.425 0-4.391 1.957-4.391 4.37 0 .693.164 1.375.482 1.99A6.16 6.16 0 00.962 20c0 1.946.907 3.68 2.322 4.81a5.728 5.728 0 01-1.137-3.443z"
          opacity="0.1"
        ></path>
        <path
          fill="#FB8276"
          d="M9.34 22.047a.586.586 0 100-1.172H7.657a1.94 1.94 0 01-1.944-1.932.586.586 0 10-1.172 0 3.114 3.114 0 003.116 3.104H9.34zM6.84 28.198a.586.586 0 101.172 0c0-1 .82-1.815 1.826-1.815h5.006a.586.586 0 100-1.171H9.839a2.996 2.996 0 00-2.998 2.986zm10.348-1.815h2.43a.586.586 0 100-1.171h-2.43a.585.585 0 100 1.171zm-5.332-8.932a.586.586 0 100 1.172c2.332 0 4.228-1.89 4.228-4.21a.586.586 0 10-1.172 0c0 1.675-1.37 3.038-3.056 3.038zm2.562-12.482a2.545 2.545 0 00-2.704 2.366.586.586 0 001.17.076c.048-.75.703-1.32 1.459-1.273a.585.585 0 10.075-1.17zM12.45 27.82a.586.586 0 100 1.171 1.75 1.75 0 011.755 1.743c0 .962-.788 1.744-1.755 1.744a.586.586 0 100 1.172 2.924 2.924 0 002.926-2.916 2.924 2.924 0 00-2.926-2.914zm5.996-9.827a.586.586 0 100 1.172 2.58 2.58 0 002.582-2.572 2.58 2.58 0 00-2.582-2.571.586.586 0 100 1.172c.777 0 1.41.628 1.41 1.4 0 .771-.633 1.4-1.41 1.4zm-10.322-.539a3.59 3.59 0 003.594-3.579 3.59 3.59 0 00-3.594-3.579.586.586 0 100 1.172 2.417 2.417 0 012.422 2.407 2.417 2.417 0 01-2.422 2.407.586.586 0 100 1.172z"
        ></path>
        <path
          fill="#FB8276"
          d="M18.664 0a4.72 4.72 0 00-4.292 2.743 4.919 4.919 0 00-.35-.013c-2.705 0-4.913 2.16-4.976 4.84a5.013 5.013 0 00-.707-.051c-2.744 0-4.977 2.223-4.977 4.956 0 .6.105 1.18.313 1.732A6.697 6.697 0 00.376 20c0 2.377 1.285 4.596 3.318 5.803a4.953 4.953 0 00-.332 1.78c0 2.733 2.233 4.956 4.977 4.956.238 0 .475-.017.709-.05.083 2.66 2.283 4.8 4.975 4.8a4.9 4.9 0 00.358-.014A4.724 4.724 0 0018.664 40c2.603 0 4.72-2.108 4.72-4.7V4.7c0-2.592-2.117-4.7-4.72-4.7zm3.549 4.765h-1.127a.586.586 0 100 1.172h1.127V9.84h-4.74a1.94 1.94 0 01-1.944-1.932.586.586 0 10-1.172 0 3.114 3.114 0 003.117 3.104h4.739v9.864h-9.505a.586.586 0 100 1.172h9.505v8.045h-1.81a2.996 2.996 0 00-3 2.986.586.586 0 101.173 0c0-1 .82-1.814 1.826-1.814h1.81V35.3c0 .092-.004.183-.01.273h-1.116a.586.586 0 100 1.172h.814a3.554 3.554 0 01-3.236 2.083 3.553 3.553 0 01-3.086-1.787 4.962 4.962 0 001.908-1.149.585.585 0 10-.813-.843 3.79 3.79 0 01-1.985 1.01v.002c-.01 0-.456.056-.665.056-2.099 0-3.806-1.698-3.806-3.784 0-.054.003-.11.006-.164a4.972 4.972 0 001.313-.787.586.586 0 10-.75-.9 3.826 3.826 0 01-2.446.885c-2.1 0-3.806-1.697-3.806-3.784 0-.621.147-1.214.437-1.762a3.799 3.799 0 011.551-1.564.585.585 0 00-.112-1.077.586.586 0 00-.446.047 4.973 4.973 0 00-1.72 1.54A5.53 5.53 0 011.548 20a5.526 5.526 0 012.436-4.598 5.597 5.597 0 013.171-.979.586.586 0 100-1.172 6.77 6.77 0 00-2.417.444 3.724 3.724 0 01-.204-1.22c0-2.087 1.707-3.784 3.805-3.784.395 0 .785.06 1.16.18a.586.586 0 00.758-.643 3.81 3.81 0 01-.04-.542c0-2.086 1.707-3.784 3.806-3.784.206 0 .417.018.626.052 1.802.298 3.18 1.858 3.18 3.732a.586.586 0 101.171 0c0-2.195-1.441-4.06-3.431-4.71a3.55 3.55 0 013.095-1.804c1.957 0 3.549 1.583 3.549 3.528v.065z"
        ></path>
        <path
          fill="#FFC502"
          d="M35.669 14.252a1.996 1.996 0 01-1.997-1.99c0-1.098.896-1.991 1.997-1.991s1.997.893 1.997 1.99c0 1.098-.896 1.99-1.997 1.99zm1.958 14.996a1.996 1.996 0 01-1.998-1.99c0-1.098.896-1.991 1.998-1.991 1.101 0 1.997.893 1.997 1.99 0 1.098-.896 1.99-1.997 1.99zm-10.708-8.676a1.996 1.996 0 01-1.997-1.99c0-1.098.896-1.991 1.997-1.991 1.102 0 1.998.893 1.998 1.99 0 1.098-.896 1.99-1.998 1.99z"
        ></path>
        <path
          fill="#33C7CA"
          d="M30.387 31.105a1.996 1.996 0 01-1.997-1.99c0-1.098.896-1.99 1.997-1.99 1.102 0 1.998.892 1.998 1.99 0 1.098-.896 1.99-1.997 1.99zM32.3 8.453a1.996 1.996 0 01-1.998-1.99c0-1.098.896-1.991 1.998-1.991 1.101 0 1.997.893 1.997 1.99 0 1.098-.896 1.99-1.997 1.99zm4.097 13.773a1.996 1.996 0 01-1.997-1.991c0-1.098.896-1.99 1.997-1.99 1.102 0 1.998.892 1.998 1.99 0 1.098-.896 1.99-1.998 1.99zm-1.32 13.138a1.996 1.996 0 01-1.997-1.99c0-1.098.896-1.99 1.997-1.99s1.997.892 1.997 1.99c0 1.097-.896 1.99-1.997 1.99z"
        ></path>
        <path
          fill="#FFC502"
          d="M31.39 26.009a1.996 1.996 0 01-1.998-1.99c0-1.098.896-1.991 1.997-1.991s1.997.893 1.997 1.99c0 1.098-.896 1.99-1.997 1.99zM29.84 38.535a1.996 1.996 0 01-1.997-1.99c0-1.099.896-1.991 1.997-1.991s1.997.892 1.997 1.99c0 1.098-.896 1.99-1.997 1.99z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_305_174">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default AIIcon;
