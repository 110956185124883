import missionImage from "../../assets/images/mission.png";

export const ABOUT_US_PAGE_CONTENT =
  `"Founders' Clinic” is designed to simplify the process of finding and engaging with the right professionals for your specific needs. Whether you're a startup seeking strategic guidance, an established business in search of specialized skills, or anything in between, we've got you covered.`;

export const MISSION_ABOUT_US_PAGE = {
  title: "Mission",
  description:
    "Our mission is to offer a single window for all business support services and to maximize client’s satisfaction through innovative, transparent, need based resources and solutions. We are committed  to help organizations in Resource coordination & resource mobilization and to solve issues, create value, maximize growth and improve business performance.",
  missionImage: missionImage,
};

export const SUBSCRIBE_SECTION_CONTENT = {
  title:
    "Don't miss any opportunity and stay tuned to the latest news in the MSME ecosystem!",
};

export const VISION_SECTION = {
  title: "Vision",
  description:
    "To integrate the maximum number of business support services to facilitate entrepreneurs all over the globe and to help them successfully implement their innovative ideas to grow their business holistically.",
};
