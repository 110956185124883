// helpers
import axiosNew from "../../helpers/axios";

// ADD PROJECT
export const addProject = async (payload) => {
  let data = payload ?? {};
  return new Promise(async (resolve) => {
    try {
      const projectData = await axiosNew({
        url: "/add-project",
        data,
      });
      resolve({ data: projectData?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// EDIT PROJECT
export const editProject = async (payload) => {
  let data = payload ?? {};
  return new Promise(async (resolve) => {
    try {
      const projectData = await axiosNew({
        url: "/edit-project",
        data,
      });
      resolve({ data: projectData?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// LIST PROJECT
export const listProject = async (payload) => {
  let data = payload ?? {};
  return new Promise(async (resolve) => {
    try {
      const projectData = await axiosNew({
        url: "/show-project",
        data,
      });
      resolve({ data: projectData?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// DELETE PROJECT
export const deleteProject = async (payload) => {
  let data = payload ?? {};
  return new Promise(async (resolve) => {
    try {
      const projectData = await axiosNew({
        url: "/delete-project",
        data,
      });
      resolve({ data: projectData?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// GET SINGLE PROJECT
export const getSingleProject = async (payload) => {
  let data = payload ?? {};
  return new Promise(async (resolve) => {
    try {
      const projectData = await axiosNew({
        url: "/single-project",
        data,
      });
      resolve({ data: projectData?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};

// UPLOAD PROJECT DOCUMENT
export const documentUpload = async (payload) => {
  let data = payload ?? {};
  return new Promise(async (resolve) => {
    try {
      const projectData = await axiosNew({
        url: "/upload-project",
        data,
      });
      resolve({ data: projectData?.data ?? {} });
    } catch (error) {
      resolve({ data: error?.response?.data ?? {} });
    }
  });
};
