// assets
import { useNavigate } from 'react-router-dom'
import ButtonComponent from '../../../components/button/buttonComponent'
import { PUBLIC_ROUTES } from '../../../constants'
import { ENROLLMENT_SECTION } from '../../../constants/landingPage/enrollmentSection'

// css
import '../enrollmentSection2/enrollmentSection2.css'

function EnrollmentSection2() {
    const navigate = useNavigate()

    return (
        <>
            <div className="enrollmentMain">
                <div className="enrollmentInner">
                    <div className="svgInner">
                        <img
                            className="enrollmentImg"
                            src={ENROLLMENT_SECTION.planImage}
                            alt=""
                        />
                    </div>
                    <div className="enrollmentDesc">
                        <h1 className="enrollmentHeading">
                            {ENROLLMENT_SECTION.title}
                        </h1>
                        <p className="textMainLine">
                            {ENROLLMENT_SECTION.subTitle}
                        </p>
                        <ul
                            style={{
                                paddingLeft: '20px',
                            }}
                        >
                            {ENROLLMENT_SECTION?.planList?.length > 0 &&
                                ENROLLMENT_SECTION.planList.map((data) => (
                                    <li className="enrollmentList" key={data}>
                                        {data}
                                    </li>
                                ))}
                        </ul>
                        <div
                            style={{
                                marginTop: '20px',
                            }}
                        >
                            <ButtonComponent
                                props={{
                                    onClick: () => {
                                        navigate(PUBLIC_ROUTES.inquiry)
                                    },
                                    buttonText: 'Get Empanelled',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EnrollmentSection2
