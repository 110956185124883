// sections
import BannerSection from '../aboutusPage/bannerSection'
import SubscribeSection from '../aboutusPage/subscribeSection/subscribeSection'
import ContactSection from '../landingPage/contactSection/contactSection'
import FooterSection from '../landingPage/footerSection/footerSection'
import HeaderSection from '../landingPage/headerSection/headerSection'
import BlogCard from './blogCard'

const BlogPage = () => {
    return (
        <>
            <HeaderSection />
            <BannerSection blogPage={true} />
            <BlogCard />
            {/* <SubscribeSection /> */}
            <ContactSection />
            <FooterSection />
        </>
    )
}

export default BlogPage
