import React from 'react'

function MarketingIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width={'100%'}
            height={'100%'}
        >
            <rect
                width="16"
                height="12"
                x="31"
                y="1"
                fill="#dad7e5"
                rx="2"
            ></rect>
            <path
                fill="#fc6"
                d="M35.32 42.32L24 31V15c14.27 0 21.31 17.33 11.32 27.32z"
            ></path>
            <path
                fill="#ffde76"
                d="M40 31c0 4.91-2.34 9.39-4.86 11.14L24 31V15a16 16 0 0116 16z"
            ></path>
            <path
                fill="#db5669"
                d="M35.32 42.32C25.31 52.3 8 45.3 8 31a16 16 0 0116-16v16z"
            ></path>
            <path
                fill="#f26674"
                d="M35.14 42.14a16 16 0 01-21.41-23.41A15.93 15.93 0 0124 15v16z"
            ></path>
            <rect
                width="16"
                height="12"
                x="1"
                y="1"
                fill="#dad7e5"
                rx="2"
            ></rect>
            <path
                fill="#edebf2"
                d="M17 3v8H5a2 2 0 01-2-2V1h12a2 2 0 012 2zm30 0v8H35a2 2 0 01-2-2V1h12a2 2 0 012 2z"
            ></path>
            <path
                fill="#c6c3d8"
                d="M13 6H5a1 1 0 010-2h8a1 1 0 010 2zm-4 4H5a1 1 0 010-2h4a1 1 0 010 2zm34-4h-8a1 1 0 010-2h8a1 1 0 010 2zm-4 4h-4a1 1 0 010-2h4a1 1 0 010 2z"
            ></path>
            <path
                fill="#edebf2"
                d="M19 33h-6a1 1 0 010-2h6a1 1 0 010 2zm-3 4h-3a1 1 0 010-2h3a1 1 0 010 2zm19-10h-6a1 1 0 010-2h6a1 1 0 010 2zm-3 4h-3a1 1 0 010-2h3a1 1 0 010 2z"
            ></path>
            <path
                fill="#425b72"
                d="M15 29c-.64 0-.21.28-7.75-8.34C6.93 20.29 7 20.27 7 17a1 1 0 012 0v2.62l6.75 7.72A1 1 0 0115 29zm18-6a1 1 0 01-.39-1.92L39 18.34V17a1 1 0 012 0c0 2.12.14 2.6-.61 2.92C32.78 23.18 33.29 23 33 23z"
            ></path>
        </svg>
    )
}

export default MarketingIcon
