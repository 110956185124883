export const CHOOSE_CLINIC_SECTION = {
    title: 'Key Features',
    subTitle: "Why Choose Founders' Clinic?",
    container: [
        {
            title: 'Precision Matchmaking',
            description: 'AI algorithms ensuring strategic partnerships.',
        },
        {
            title: 'Tailored Business Support',
            description: 'Customized solutions for diverse industry needs.',
        },
        {
            title: 'Integrated Tools',
            description:
                'Access to finance, marketing, compliance, recruitment, training/coaching and more.',
        },
        {
            title: 'Expert Consultation',
            description:
                'Guidance from industry experts for informed decision-making.',
        },
        {
            title: 'Community Hub',
            description: 'Collaborate, learn, and network with other MSMEs.',
        },
        {
            title: 'Diverse Network',
            description:
                'Connect with a wide range of professionals across industries.',
        },
        {
            title: 'Innovation Hub',
            description:
                'Stay ahead with the latest trends, insights, and technology.',
        },
        {
            title: 'Real Results',
            description:
                'Transform your vision into reality with tangible, measurable outcomes.',
        },
    ],
}
