// antd
import { Row, Typography } from "antd";

// assets
import vision from "../../../assets/images/vision.png";

// constants
import { VISION_SECTION } from "../../../constants/aboutUsPage";

// css
import "./visionSection.css";

const VisionPage = () => {
  const { Title, Text } = Typography;

  return (
    <>
      <Row align={"middle"} className="visionContainer">
        <img className="visionImage" src={vision} alt="" />
        <div className="visionTextContainer">
        <h1 className="missionHeading">
            {VISION_SECTION.title}
          </h1>
          <p className="missionList">
            {VISION_SECTION.description}
          </p>
        </div>
      </Row>
    </>
  );
};

export default VisionPage;
